import interRegular from './font';
import logoFont from './logo-font';

const typography = {
  baseFont: interRegular.fontFamily,
  fontFamily: interRegular.fontFamily,
  logoFont: logoFont.fontFamily,
  logoFontFamily: logoFont.fontFamily,
  alternateFont: 'sans-serif',
  superTinySize: 'clamp(11px, 12px, 1.05vw)',
  extraTinySize: 'clamp(13px, 14px, 1.1vw)',
  tinySize: 'clamp(14px, 15px, 1.12vw)',
  largeTinySize: 'clamp(14.5px, 16.5px, 1.16vw)',
  extraSmallSize: 'clamp(15px, 19px, 1.2vw)',
  essSize: 'clamp(16px, 19px, 1.3vw)',
  smallSize: 'clamp(19px, 22px, 1.4vw)',
  smSize: 'clamp(20px, 24px, 1.6vw)',
  inputLabelSMSize: 'clamp(18px, 22px, 1.8vw)',
  mediumSize: 'clamp(20px, 27px, 2vw)',
  mmlSize: 'clamp(23px, 30px, 2.5vw)',
  mlSize: 'clamp(25px, 32px, 2.8vw)',
  largeSize: 'clamp(30px, 37px, 3.4vw)',
  extraLargeSize: 'clamp(35px, 42px, 4vw)',
};

export default typography;
