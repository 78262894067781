import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useContactTags, useMyCampaigns } from '../../../api/hooks/campaigns';
import { useMyLocations } from '../../../api/hooks/enterprise';
import { ADD_MEMBERS_TO_CAMPAIGN } from '../../../api/mutations/campaign';
import { CREATE_CHAT_FOR_CONTACT } from '../../../api/mutations/chat';
import { RESYNC_LEADS } from '../../../api/mutations/client';
import { GET_MY_CLIENTS_UPDATED_AT } from '../../../api/queries/client';
import { MY_CLIENTS_SUBSCRIPTION } from '../../../api/subscriptions/client';
import { getTypesenseClient } from '../../../api/typesense/typesense-client';
import Paths from '../../../Paths';
import {
  AbsoluteLoadingIndicatorContainer,
  CenteredDiv,
  CenteredDivWithExtraSmallGap,
  ClientSearchEmailIcon,
  ClientSearchPhoneIcon,
  ColumnDivWithSmallGap,
  ExtraSmallDeleteButton,
  ExtraSmallLightPrimaryCopyIcon,
  ExtraSmallPrimaryButton,
  ExtraSmallSecondaryButton,
  ExtraTinyGapColumnCenteredDiv,
  LightESSText,
  MenuItem,
  MenuItemCheckedRadioButton,
  MenuItemCheckmark,
  MenuItemUncheckedRadioButton,
  NextPageButton,
  PreviousPageButton,
  SmallAddIcon,
  SmallChatIcon,
  SmallCheckbox,
  SmallErrorMenuItemText,
  SmallFilterIcon,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  SmallRemoveIcon,
  SmallText,
  Table,
  TableBody,
  TableCell,
  TableCellHeader,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TooltipSubtitleText,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import { updateMyCampaignMembersInCache } from '../../../utils/cache';
import {
  appNameLabelMap,
  ascendingKey,
  audienceSearchParamsCookieKey,
  booleanLabelMap,
  campaignMemberManagementContextKey,
  campaignMemberStatusLabelMap,
  canBeTextedKey,
  chatDemoContextKey,
  clientFilterFieldLabelMap,
  clientSearchParamsCookieKey,
  contactTypeLabelMap,
  daysSinceLastVisitField,
  daysUntilNextUpcomingVisitField,
  descendingKey,
  emptyArrayValue,
  emptyIntValue,
  emptyStringValue,
  femaleKey,
  firstNameField,
  getDisplayedClientFilterFieldKeys,
  inactiveKey,
  intraStringSeparatorChar,
  lastMessagedAtField,
  lastNameField,
  maleKey,
  memberStatusField,
  membershipStatusLabelMap,
  multiLocationPrivilegedRoles,
  nameField,
  nextUpcomingVisitField,
  numUpcomingVisitsField,
  numVisitsField,
  singleLocationBusinessType,
  stagingFilterConversionDelay,
  superAdminRole,
  textSeparatorChar,
  typesenseBusinessContactSchemaName,
  typesenseContactSchemaName,
  unclassifiedKey,
  unknownCampaignKey,
} from '../../../utils/constants';
import {
  dateToTextFormat,
  getCookieExpiryObject,
  getDaysBetweenDates,
} from '../../../utils/date';
import {
  audienceClientSearchParams,
  baseClientSearchParams,
  getSortParam,
  getTypesenseBusinessFilterString,
  getTypesenseFilterString,
  updateSearchFiltersCookie,
} from '../../../utils/filters';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import {
  formatNumber,
  formatPhoneNumber,
  safeParseInt,
} from '../../../utils/numbers';
import { copyTextToClipboard } from '../../../utils/string';
import {
  getUserBusiness,
  getUserLocationTimezone,
  getUserLocationTypesenseApiKey,
} from '../../../utils/user';
import OptionsFilterDropdown from '../../Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';
import CreateCampaignModal from '../../Modals/CreateCampaignModal';
import CreateContactModal from '../../Modals/CreateContactModal';
import FilterClientsModal from '../../Modals/FilterClientsModal';
import PermanentlyDeleteCampaignMembersModal from '../../Modals/PermanentlyDeleteCampaignMembersModal';
import SelectCampaignForChatDemoModal from '../../Modals/SelectCampaignForChatDemoModal';
import SmartListModal from '../../Modals/SmartListModal';
import PopperMenu from '../../PopperMenu';
import Snackbar from '../../Snackbar';
import ClientSearchBox from '../ClientSearchBox/ClientSearchBox';
import {
  ActionsText,
  ClientActionText,
  ClientsContainer,
  DemoNameInputContainer,
  EmptySearchContainer,
  FilterButtonHangingChip,
  FilterButtonHangingChipText,
  FiltersContainer,
  LeftTableButtonsContainer,
  NumResultsContainer,
  ResetText,
  ResetTextContainer,
  ResultSizeContainer,
  ResyncLeadsIcon,
  RightTableButtonsContainer,
  SelectedText,
  SortFieldContainer,
  SortIcon,
  TableActionsContainer,
  TableButtonsContainer,
} from './styled';

const basePageSize = 50;

const unknownKey = 'Unknown';
const noneKey = 'None';

const selectedCampaignAddType = 'selected';
const allCampaignAddType = 'all';

const sortableFields = [
  nameField,
  numVisitsField,
  daysSinceLastVisitField,
  numUpcomingVisitsField,
  nextUpcomingVisitField,
  daysUntilNextUpcomingVisitField,
  memberStatusField,
  lastMessagedAtField,
];

const cookieExpiryObject = getCookieExpiryObject();

const currentDateString = Date.now();

// Messed things up last time it ran
const displayingResyncLeads = false;

const sortCampaigns = (campaigns) => {
  return sortBy(campaigns, (c) => c.name);
};

const getFilterString = (locationId, filters, businessId) => {
  const { filterString: typesenseFilterString } = !businessId
    ? getTypesenseFilterString([locationId], filters)
    : getTypesenseBusinessFilterString([businessId], filters);
  return typesenseFilterString;
};

const getCookieString = (id, cookie, isLocation) => {
  const filterString = getFilterString(
    isLocation ? id : null,
    cookie.filters,
    !isLocation ? id : null,
  );

  return `${cookie['pageNumber'] || ''}${intraStringSeparatorChar}${
    cookie['searchFilter'] || ''
  }${intraStringSeparatorChar}${
    cookie['sortParam'] || ''
  }${intraStringSeparatorChar}${filterString}`;
};

const locationViewKey = 'location';
const portfolioViewKey = 'portfolio';
const tableViewOptions = [
  {
    key: locationViewKey,
    label: 'Location view',
  },
  {
    key: portfolioViewKey,
    label: 'Portfolio view',
  },
];

const ClientSearch = ({
  locationId,
  context,
  demoName,
  onUpdateDemoName,
  selectedClientForChatDemo = null,
  onSelectClientForChatDemo = () => {},
  onResetClient = () => {},
}) => {
  const navigate = useNavigate();
  const { cache } = useApolloClient();
  const { inDemoMode, user, cookies, setCookie } = useContext(BaseContext);

  const isSuperAdmin = user?.role === superAdminRole;
  const timezone = getUserLocationTimezone(user);
  const business = getUserBusiness(user);
  const businessId = business?.id;
  const isMultiLocation =
    multiLocationPrivilegedRoles.includes(user?.role) &&
    business?.type !== singleLocationBusinessType;

  const typesenseApiKey = getUserLocationTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  const { subscribeToMore, ...result } = useQuery(GET_MY_CLIENTS_UPDATED_AT, {
    fetchPolicy: 'network-only',
  });

  const [
    createChatForContactMutation,
    { loading: createChatForContactLoading },
  ] = useMutation(CREATE_CHAT_FOR_CONTACT);
  const [resyncLeadsMutation, { loading: resyncLeadsLoading }] =
    useMutation(RESYNC_LEADS);

  const {
    campaigns,
    loading: campaignsLoading,
    refetch: refetchCampaigns,
  } = useMyCampaigns({
    shallow: true,
  });
  const campaignNameMap = {};
  campaigns?.map((c) => {
    campaignNameMap[c.id] = c.name;
  });
  const sortedCampaigns = sortCampaigns(campaigns);

  const { tags, loading: tagsLoading } = useContactTags();
  const { locations } = useMyLocations({ skipCondition: !isMultiLocation });
  const locationMap = {};
  locations.map((l) => {
    locationMap[l.id] = l.name;
  });
  const baseLocationName = locationMap[locationId];

  const [
    addMembersToCampaignMutation,
    { loading: addMembersToCampaignMutationLoading },
  ] = useMutation(ADD_MEMBERS_TO_CAMPAIGN);

  const [stagingLastUpdatedAt, setStagingLastUpdatedAt] = useState(
    result?.data?.getMyClientsUpdatedAt?.timestamp,
  );
  const [lastUpdatedAt, setLastUpdatedAt] = useState(
    result?.data?.getMyClientsUpdatedAt?.timestamp,
  );
  const [clients, setClients] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState({});
  const [
    hasUpdatedSelectedClientFromChatDemo,
    setHasUpdatedSelectedClientFromChatDemo,
  ] = useState(false);
  const [
    selectCampaignForChatDemoModalOpen,
    setSelectCampaignForChatDemoModalOpen,
  ] = useState(false);
  const [userCampaignsForChatDemo, setUserCampaignsForChatDemo] = useState([]);
  const [
    selectCampaignForChatDemoMenuAnchorEl,
    setSelectCampaignForChatDemoMenuAnchorEl,
  ] = useState();
  const [numResults, setNumResults] = useState();
  const [hasFetched, setHasFetched] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [addToCampaignMenuAnchorEl, setAddToCampaignMenuAnchorEl] =
    useState(false);
  const [addToCampaignGroup, setAddToCampaignGroup] = useState();
  const [createContactModalOpen, setCreateContactModalOpen] = useState(false);
  const [createSmartListModalOpen, setCreateSmartListModalOpen] =
    useState(false);
  const [campaignForSmartList, setCampaignForSmartList] = useState();
  const [
    selectCampaignForChatCreationMenuAnchorEl,
    setSelectCampaignForChatCreationMenuAnchorEl,
  ] = useState();
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);
  const [removeFromCampaignsMenuAnchorEl, setRemoveFromCampaignsMenuAnchorEl] =
    useState(false);
  const [removeFromCampaignsModalOpen, setRemoveFromCampaignsModalOpen] =
    useState(false);

  useEffect(() => {
    if (!lastUpdatedAt) {
      const updatedTimestamp = result?.data?.getMyClientsUpdatedAt?.timestamp;
      setLastUpdatedAt(updatedTimestamp);
    }
  }, [result]);

  useEffect(() => {
    subscribeToMore({
      document: GET_MY_CLIENTS_UPDATED_AT,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedTimestamp =
          subscriptionData?.data?.getMyClientsUpdatedAt?.timestamp;
        console.log(
          'x-GET_MY_CLIENTS_UPDATED_AT subscriptionData',
          subscriptionData,
        );
        setStagingLastUpdatedAt(updatedTimestamp);
        return {
          getMyClientsUpdatedAt: {
            timestamp: updatedTimestamp,
          },
        };
      },
    });
  }, [lastUpdatedAt]);

  useEffect(() => {
    subscribeToMore({
      document: MY_CLIENTS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedTimestamp =
          subscriptionData?.data?.myClientUpdates?.timestamp;
        console.log(
          'x-MY_CLIENTS_SUBSCRIPTION subscriptionData',
          subscriptionData,
        );
        setLastUpdatedAt(updatedTimestamp);
        return {
          getMyClientsUpdatedAt: {
            timestamp: updatedTimestamp,
          },
        };
      },
    });
  }, [stagingLastUpdatedAt]);

  const defaultClientSearchParams =
    context === campaignMemberManagementContextKey
      ? audienceClientSearchParams
      : baseClientSearchParams;
  const baseClientFilter = defaultClientSearchParams?.filters || {};

  const relevantKey =
    context === campaignMemberManagementContextKey
      ? audienceSearchParamsCookieKey
      : clientSearchParamsCookieKey;
  const storedClientSearchParams = cookies[relevantKey];
  const clientSearchParamsFromCookie = storedClientSearchParams
    ? storedClientSearchParams
    : defaultClientSearchParams;

  const [view, setView] = useState(locationViewKey);
  const [pageNumber, setPageNumber] = useState(
    clientSearchParamsFromCookie.pageNumber || 0,
  );
  const [filters, setFilters] = useState(clientSearchParamsFromCookie.filters);
  const [stagingSearchFilter, setStagingSearchFilter] = useState(
    clientSearchParamsFromCookie.searchFilter,
  );
  const [searchFilter, setSearchFilter] = useState(
    clientSearchParamsFromCookie.searchFilter,
  );
  const [sortParam, setSortParam] = useState(
    clientSearchParamsFromCookie.sortParam,
  );
  const [pageSize, setPageSize] = useState(basePageSize);
  const [sortParamFieldMenuToOpen, setSortParamFieldMenuToOpen] = useState();
  const [sortParamMenuAnchorEl, setSortParamMenuAnchorEl] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const onLocationView = view === locationViewKey;
  const displayOtherLocations =
    locations?.length > 1 && isMultiLocation && isSuperAdmin;
  const displayMultiLocationData = displayOtherLocations && onLocationView;
  const selectCampaignForChatDemoMenuOpenBoolean = Boolean(
    selectCampaignForChatDemoMenuAnchorEl,
  );
  const addToCampaignMenuOpenBoolean = Boolean(addToCampaignMenuAnchorEl);
  const removeFromCampaignsMenuOpenBoolean = Boolean(
    removeFromCampaignsMenuAnchorEl,
  );
  const selectCampaignForChatCreationMenuOpenBoolean = Boolean(
    selectCampaignForChatCreationMenuAnchorEl,
  );
  const sortParamMenuOpenBoolean = Boolean(sortParamMenuAnchorEl);

  const getClientSearchParameters = (pageSizeToUse = pageSize) => {
    const filterString = getFilterString(
      onLocationView ? locationId : null,
      filters,
      !onLocationView ? businessId : null,
    );

    let searchParameters = {
      q: '*',
      per_page: pageSizeToUse,
      page: pageNumber,
      filter_by: filterString,
      sort_by: sortParam,
    };

    if (searchFilter) {
      searchParameters = {
        ...searchParameters,
        q: searchFilter,
        query_by: 'firstName,lastName',
      };
    }

    return searchParameters;
  };

  const cookieString = getCookieString(
    onLocationView ? locationId : businessId,
    clientSearchParamsFromCookie,
    onLocationView,
  );

  useEffect(() => {
    const cookiePageNumber = clientSearchParamsFromCookie.pageNumber;
    const cookieFilters = clientSearchParamsFromCookie.filters;
    const cookieSearchFilter = clientSearchParamsFromCookie?.searchFilter;
    const cookieSortParam = clientSearchParamsFromCookie.sortParam;

    let filterChanged = false;

    // Easier to always set rather than checking if need to set (its an object)
    setFilters(cookieFilters);

    if (cookieSortParam !== sortParam) {
      setSortParam(cookieSortParam);
      filterChanged = true;
    }

    if (cookieSearchFilter !== searchFilter) {
      setStagingSearchFilter(cookieSearchFilter);
      setSearchFilter(cookieSearchFilter);
      filterChanged = true;
    }

    setPageNumber(filterChanged ? 0 : parseInt(cookiePageNumber));
  }, [cookieString]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        onUpdateQuery('searchFilter', stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    async function fetchClients() {
      const searchParameters = getClientSearchParameters();
      const table = onLocationView
        ? typesenseContactSchemaName
        : typesenseBusinessContactSchemaName;
      const data = await typesenseClient
        .collections(table)
        .documents()
        .search(searchParameters);
      setNumResults(data.found);

      const documents = data.hits.map((hit) => hit.document);

      setClients(documents);
      setHasFetched(true);
    }

    fetchClients();
  }, [view, lastUpdatedAt, pageNumber, filters, sortParam]);

  useEffect(() => {
    if (
      selectedClientForChatDemo &&
      context === chatDemoContextKey &&
      !hasUpdatedSelectedClientFromChatDemo
    ) {
      setSelectedContacts(selectedClientForChatDemo);
      setHasUpdatedSelectedClientFromChatDemo(true);
    }
  }, [selectedClientForChatDemo, hasUpdatedSelectedClientFromChatDemo]);

  const onUpdateFilters = (newFilters) => {
    updateSearchFiltersCookie(
      { ...clientSearchParamsFromCookie },
      newFilters,
      relevantKey,
      setCookie,
    );
  };

  const onUpdateQuery = (field, newValue) => {
    let params = { ...clientSearchParamsFromCookie };

    params[field] = newValue;

    setCookie(relevantKey, params, cookieExpiryObject);
  };

  const onUpdateSortParam = (newSortValue) => {
    let params = { ...clientSearchParamsFromCookie };

    params['sortParam'] = newSortValue;

    if (pageNumber !== 1) {
      params['pageNumber'] = 1;
    }

    setCookie(relevantKey, params, cookieExpiryObject);
  };

  const onUpdatePageNumber = (newPageNumber) => {
    let params = { ...clientSearchParamsFromCookie };

    params['pageNumber'] = newPageNumber;

    setCookie(relevantKey, params, cookieExpiryObject);
  };

  const updateSearchFilter = (stagingSearchFilterValue) => {
    setStagingSearchFilter(stagingSearchFilterValue);
  };

  const onResetSearchFilter = () => {
    setStagingSearchFilter('');
  };

  const onApplyFilters = (updatedFilters) => {
    onUpdateQuery('filters', updatedFilters);
    setSnackbarMessage('Filter applied');
  };

  const onUpdateSelectedContactsOnPage = () => {
    const updatedSelectedClients = {
      ...selectedContacts,
    };

    if (!wholePageSelected) {
      clients.map((c) => (updatedSelectedClients[c.id] = c));
      if (addToCampaignGroup === selectedCampaignAddType) {
        setAddToCampaignGroup(allCampaignAddType);
      }
    } else {
      clients.map((c) => delete updatedSelectedClients[c.id]);
      if (addToCampaignGroup !== selectedCampaignAddType) {
        setAddToCampaignGroup(selectedCampaignAddType);
      }
    }

    setSelectedContacts(updatedSelectedClients);
  };

  const onCompleteCampaignMemberAdd = async (numAdded, numAttemptedToAdd) => {
    if (numAdded === numAttemptedToAdd) {
      setSnackbarMessage(`Successfully added members to campaign`);
    } else if (numAdded > 0 && numAdded < numAttemptedToAdd) {
      setSnackbarMessage(
        `${numAdded}/${numAttemptedToAdd} members were successfully added to the campaign`,
      );
    } else {
      setSnackbarMessage(`No members were able to be added to the campaign`);
    }

    setSelectedContacts({});
    await refetchCampaigns();
  };

  const onAddSelectedContacts = (campaignId) => {
    const contactIds = Object.keys(selectedContacts);
    const numAttemptedToAdd = contactIds.length;

    addMembersToCampaignMutation({
      variables: {
        campaignId,
        contactIds,
      },
      onCompleted: async (data) => {
        setAddToCampaignMenuAnchorEl();

        const response = data.addMembersToCampaign;
        const updatedCampaign = response.campaign;
        const updatedMemberIds = updatedCampaign.memberIds;
        const numAdded = response.numAdded;

        await updateMyCampaignMembersInCache(
          campaignId,
          updatedMemberIds,
          cache,
        );

        onCompleteCampaignMemberAdd(numAdded, numAttemptedToAdd);
      },
    });
  };

  const onAddAllMemberResults = (campaignId, createSmartList) => {
    const filterString = getFilterString(
      onLocationView ? locationId : null,
      filters,
      !onLocationView ? businessId : null,
    );

    addMembersToCampaignMutation({
      variables: {
        campaignId,
        filter: filterString,
        createSmartList,
      },
      onCompleted: async (data) => {
        setAddToCampaignMenuAnchorEl();

        const response = data.addMembersToCampaign;
        const updatedCampaign = response.campaign;
        const updatedMemberIds = updatedCampaign.memberIds;
        const numAdded = response.numAdded;

        await updateMyCampaignMembersInCache(
          campaignId,
          updatedMemberIds,
          cache,
        );

        await onCompleteCampaignMemberAdd(numAdded, numResults);
      },
    });
  };

  const onCreateChatForContact = (campaignId) => {
    const contact = selectedContacts[Object.keys(selectedContacts)[0]];

    const isManuallyCreated = campaignId === 'manual';
    createChatForContactMutation({
      variables: {
        contact,
        campaignId,
      },
      onCompleted: async (data) => {
        const res = data.createChatForContact;
        const success = res.success;
        if (success) {
          setSnackbarMessage(`Chat created`);
          if (isManuallyCreated) {
            const chatId = res.metadata?.chatId;
            if (chatId) {
              const chatPath = `${Paths.chats}?chat-id=${chatId}`;
              navigate(chatPath);
            }
          }
        }
      },
    });
  };

  const onDeleteMembersFromCampaigns = (numClientsDeleted) => {
    setSnackbarMessage(
      `Successfully removed client${
        numClientsDeleted !== 1 ? 's' : ''
      } from campaigns`,
    );

    setRemoveFromCampaignsModalOpen();

    refetchCampaigns();

    setSelectedContacts({});
  };

  const onInitiateChatDemo = (campaign) => {
    const clientId = Object.keys(selectedContacts)[0];
    const compressedCampaign = {
      id: campaign.id,
      name: campaign.name,
    };
    onSelectClientForChatDemo(clientId, compressedCampaign);
  };

  const onSelectCampaignForChatDemo = (selectedCampaign) => {
    const clientId = Object.keys(selectedContacts)[0];
    const compressedCampaign = {
      id: selectedCampaign.id,
      name: selectedCampaign.name,
    };
    onSelectClientForChatDemo(clientId, compressedCampaign);
  };

  const onResyncLeads = () => {
    resyncLeadsMutation({
      onCompleted: async (data) => {
        const success = data?.resyncLeads?.success;

        setSnackbarMessage(success ? `Resyncing leads` : `Failed to resync`);
      },
    });
  };

  const onCreateContact = (success) => {
    setSnackbarMessage(
      success
        ? `Created contact`
        : `Error creating contact, please reach out to support`,
    );
  };

  const onCopyText = (text, fieldName) => {
    copyTextToClipboard(text);
    setSnackbarMessage(`${fieldName} copied`);
  };

  const campaignMemberIdMap = {};

  campaigns?.map((c) => {
    const campaignMemberIds = Object.keys(c.memberIds);
    campaignMemberIds.map((memberId) => {
      if (memberId in campaignMemberIdMap) {
        const existingMemberCampaigns = campaignMemberIdMap[memberId];
        const updatedMemberCampaigns = [...existingMemberCampaigns, c];
        campaignMemberIdMap[memberId] = updatedMemberCampaigns;
      } else {
        campaignMemberIdMap[memberId] = [c];
      }
    });
  });

  const displayedClientFilterFieldKeys =
    getDisplayedClientFilterFieldKeys(context);

  const defaultAppliedKeys = ['locationId'];
  if (context === campaignMemberManagementContextKey) {
    defaultAppliedKeys.push(canBeTextedKey);
  }

  const numFiltersApplied = Object.keys(filters).filter(
    (key) => !defaultAppliedKeys.includes(key),
  )?.length;
  const numSelected = Object.keys(selectedContacts)?.length;

  const queryApplied = numFiltersApplied || searchFilter;

  const totalNumPages = Math.ceil(numResults / pageSize);
  let wholePageSelected = clients?.length > 0;
  for (let i = 0; i < clients?.length; i++) {
    const client = clients[i];
    const id = client.id;
    if (!(id in selectedContacts)) {
      wholePageSelected = false;
      break;
    }
  }

  const isLoading =
    campaignsLoading ||
    addMembersToCampaignMutationLoading ||
    createChatForContactLoading;

  if (isLoading) {
    return (
      <AbsoluteLoadingIndicatorContainer>
        {addMembersToCampaignMutationLoading && (
          <SmallText>Adding contacts to campaign...</SmallText>
        )}
        <LoadingIndicator />
      </AbsoluteLoadingIndicatorContainer>
    );
  }

  return (
    hasFetched && (
      <>
        <ColumnDivWithSmallGap>
          <TableActionsContainer ctx={context}>
            <TableButtonsContainer>
              <LeftTableButtonsContainer>
                {context === campaignMemberManagementContextKey ? (
                  <>
                    <ExtraSmallPrimaryButton
                      onClick={(e) => {
                        setAddToCampaignMenuAnchorEl(e.currentTarget);
                        setAddToCampaignGroup(
                          numSelected > 0
                            ? selectedCampaignAddType
                            : allCampaignAddType,
                        );
                      }}
                    >
                      <Tooltip
                        title={
                          <TooltipTitleText>
                            Add contact to campaign
                          </TooltipTitleText>
                        }
                      >
                        <SmallAddIcon />
                      </Tooltip>
                    </ExtraSmallPrimaryButton>
                    <Tooltip
                      title={
                        <TooltipTitleText>
                          {!campaigns?.length
                            ? `Create a campaign to create a chat for`
                            : numSelected !== 1
                            ? 'Select a contact to create a chat for'
                            : `Create chat for ${
                                selectedContacts[
                                  Object.keys(selectedContacts)[0]
                                ].firstName
                              }`}
                        </TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <span>
                        <ExtraSmallPrimaryButton
                          disabled={numSelected !== 1 || !campaigns?.length}
                          onClick={(e) => {
                            setSelectCampaignForChatCreationMenuAnchorEl(
                              e.currentTarget,
                            );
                          }}
                        >
                          <SmallChatIcon />
                        </ExtraSmallPrimaryButton>
                      </span>
                    </Tooltip>
                    <PopperMenu
                      open={selectCampaignForChatCreationMenuOpenBoolean}
                      anchorElement={selectCampaignForChatCreationMenuAnchorEl}
                      onClose={() => {
                        setSelectCampaignForChatCreationMenuAnchorEl();
                      }}
                      variant='offset'
                    >
                      <SmallMenuItemTitleContainer disableRipple>
                        <SmallMenuItemTitleText>
                          Select campaign to create chat for
                        </SmallMenuItemTitleText>
                      </SmallMenuItemTitleContainer>
                      <MenuItem
                        disableRipple
                        onClick={() => {
                          setSelectCampaignForChatCreationMenuAnchorEl();
                          onCreateChatForContact('manual');
                        }}
                      >
                        <SmallMenuItemText largeGap>
                          <SmallAddIcon
                            lightPrimary
                            leftMargin={-5}
                          />
                          Empty new chat
                        </SmallMenuItemText>
                      </MenuItem>
                      {campaigns?.map((c) => (
                        <MenuItem
                          disableRipple
                          onClick={() => {
                            const campaignId = c.id;
                            setSelectCampaignForChatCreationMenuAnchorEl();
                            onCreateChatForContact(campaignId);
                          }}
                        >
                          <SmallMenuItemText>
                            {c.name?.length > 26
                              ? `${c.name.slice(0, 23)}...`
                              : c.name}
                          </SmallMenuItemText>
                        </MenuItem>
                      ))}
                    </PopperMenu>
                    {numSelected === 0 ? (
                      <Tooltip
                        title={
                          <TooltipTitleText>
                            Select contacts to remove from campaigns
                          </TooltipTitleText>
                        }
                        placement='bottom'
                      >
                        <span>
                          <ExtraSmallDeleteButton
                            disabled
                            onClick={() => {}}
                          >
                            <SmallRemoveIcon inactive />
                          </ExtraSmallDeleteButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <>
                        <ExtraSmallDeleteButton
                          onClick={(e) =>
                            setRemoveFromCampaignsMenuAnchorEl(e.currentTarget)
                          }
                          disabled={numSelected === 0}
                        >
                          <SmallRemoveIcon />
                        </ExtraSmallDeleteButton>
                        <PopperMenu
                          open={removeFromCampaignsMenuOpenBoolean}
                          anchorElement={removeFromCampaignsMenuAnchorEl}
                          onClose={() => setRemoveFromCampaignsMenuAnchorEl()}
                          variant='offset'
                        >
                          <MenuItem
                            disableRipple
                            onClick={() => {
                              setRemoveFromCampaignsMenuAnchorEl();
                              setRemoveFromCampaignsModalOpen(true);
                            }}
                          >
                            <SmallErrorMenuItemText>
                              Remove selected contact
                              {numSelected === 1 ? '' : 's'} from all campaigns
                            </SmallErrorMenuItemText>
                          </MenuItem>
                        </PopperMenu>
                      </>
                    )}
                  </>
                ) : context === chatDemoContextKey ? (
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        {!campaigns?.length
                          ? `Create a campaign to demo agent chat`
                          : numSelected === 0
                          ? 'Select a contact to demo agent chat'
                          : `Demo agent chat with ${
                              selectedContacts[Object.keys(selectedContacts)[0]]
                                .firstName
                            }`}
                      </TooltipTitleText>
                    }
                    placement='bottom'
                  >
                    <span>
                      <ExtraSmallPrimaryButton
                        disabled={numSelected === 0 || !campaigns?.length}
                        onClick={(e) => {
                          const numCampaigns = campaigns?.length;
                          if (numCampaigns) {
                            if (numCampaigns === 1) {
                              const campaignForDemo = campaigns[0];
                              onInitiateChatDemo(campaignForDemo);
                            } else {
                              setSelectCampaignForChatDemoMenuAnchorEl(
                                e.currentTarget,
                              );
                            }
                          }
                        }}
                      >
                        <SmallChatIcon />
                      </ExtraSmallPrimaryButton>
                    </span>
                  </Tooltip>
                ) : (
                  <ExtraSmallPrimaryButton
                    onClick={() => {
                      setCreateContactModalOpen(true);
                    }}
                  >
                    <Tooltip
                      title={
                        <TooltipTitleText>Create contact</TooltipTitleText>
                      }
                    >
                      <SmallAddIcon />
                    </Tooltip>
                  </ExtraSmallPrimaryButton>
                )}
                <PopperMenu
                  open={selectCampaignForChatDemoMenuOpenBoolean}
                  anchorElement={selectCampaignForChatDemoMenuAnchorEl}
                  onClose={() => {
                    setSelectCampaignForChatDemoMenuAnchorEl();
                  }}
                  variant='offset'
                >
                  <SmallMenuItemTitleContainer disableRipple>
                    <SmallMenuItemTitleText>
                      Select campaign for chat demo
                    </SmallMenuItemTitleText>
                  </SmallMenuItemTitleContainer>
                  <DemoNameInputContainer>
                    <Input
                      label='Demo name'
                      value={demoName}
                      onChange={(e) => onUpdateDemoName(e.target.value)}
                      useSmallText
                      useExtraSmallWidth
                      fixedWidth={200}
                      fixedHeight={45}
                      removeGap
                      useExtraSmallText
                    />
                  </DemoNameInputContainer>
                  {campaigns?.map((c) => (
                    <MenuItem
                      disableRipple
                      onClick={() => onInitiateChatDemo(c)}
                    >
                      <SmallMenuItemText>
                        {c.name?.length > 26
                          ? `${c.name.slice(0, 23)}...`
                          : c.name}
                      </SmallMenuItemText>
                    </MenuItem>
                  ))}
                </PopperMenu>
                <Tooltip
                  title={<TooltipTitleText>Filter contacts</TooltipTitleText>}
                  placement='bottom'
                >
                  <ExtraSmallSecondaryButton
                    onClick={() => setFilterModalOpen(true)}
                  >
                    <SmallFilterIcon />
                    {numFiltersApplied ? (
                      <FilterButtonHangingChip>
                        <FilterButtonHangingChipText>
                          {numFiltersApplied}
                        </FilterButtonHangingChipText>
                      </FilterButtonHangingChip>
                    ) : (
                      <></>
                    )}
                  </ExtraSmallSecondaryButton>
                </Tooltip>
                <ClientSearchBox
                  value={stagingSearchFilter}
                  onChange={(e) => updateSearchFilter(e.target.value)}
                  onReset={onResetSearchFilter}
                />
                {isSuperAdmin && displayingResyncLeads && (
                  <Tooltip
                    title={<TooltipTitleText>Resync leads</TooltipTitleText>}
                    placement='bottom'
                  >
                    <ResyncLeadsIcon onClick={() => onResyncLeads()} />
                  </Tooltip>
                )}
                {queryApplied ? (
                  <ResetTextContainer
                    onClick={() => {
                      onResetSearchFilter();
                      onUpdateFilters(baseClientFilter);
                      setSnackbarMessage('Filters reset');
                    }}
                  >
                    <ResetText>Reset filters</ResetText>
                  </ResetTextContainer>
                ) : (
                  <></>
                )}
                <PopperMenu
                  open={addToCampaignMenuOpenBoolean && addToCampaignGroup}
                  anchorElement={addToCampaignMenuAnchorEl}
                  onClose={() => setAddToCampaignMenuAnchorEl()}
                  variant='offset'
                >
                  <SmallMenuItemTitleContainer disableRipple>
                    <SmallMenuItemTitleText>
                      Add contacts
                    </SmallMenuItemTitleText>
                  </SmallMenuItemTitleContainer>
                  <MenuItem
                    disableRipple
                    disabled={numSelected === 0}
                    onClick={() =>
                      setAddToCampaignGroup(
                        addToCampaignGroup === selectedCampaignAddType
                          ? allCampaignAddType
                          : selectedCampaignAddType,
                      )
                    }
                  >
                    <SmallMenuItemText>
                      Selected contact{numSelected !== 1 && 's'} (
                      {numSelected.toLocaleString()})
                    </SmallMenuItemText>
                    {addToCampaignGroup === selectedCampaignAddType ? (
                      <MenuItemCheckedRadioButton />
                    ) : (
                      <MenuItemUncheckedRadioButton />
                    )}
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    addBottomMargin
                    onClick={() =>
                      setAddToCampaignGroup(
                        addToCampaignGroup === selectedCampaignAddType
                          ? allCampaignAddType
                          : selectedCampaignAddType,
                      )
                    }
                  >
                    <SmallMenuItemText>
                      All results ({numResults.toLocaleString()})
                    </SmallMenuItemText>
                    {addToCampaignGroup === allCampaignAddType ? (
                      <MenuItemCheckedRadioButton />
                    ) : (
                      <MenuItemUncheckedRadioButton />
                    )}
                  </MenuItem>
                  <SmallMenuItemTitleContainer disableRipple>
                    <SmallMenuItemTitleText>
                      To desired campaign
                    </SmallMenuItemTitleText>
                  </SmallMenuItemTitleContainer>
                  {sortedCampaigns?.map((c) => (
                    <MenuItem
                      disableRipple
                      onClick={() => {
                        if (addToCampaignGroup === allCampaignAddType) {
                          setAddToCampaignMenuAnchorEl();
                          setCreateSmartListModalOpen(true);
                          setCampaignForSmartList(c);
                        } else {
                          onAddSelectedContacts(c.id);
                        }
                      }}
                    >
                      <SmallMenuItemText>
                        {c.name?.length > 26
                          ? `${c.name.slice(0, 23)}...`
                          : c.name}
                      </SmallMenuItemText>
                    </MenuItem>
                  ))}
                  <MenuItem
                    disableRipple
                    bottomItem={!!campaigns?.length}
                    onClick={() => {
                      setCreateCampaignModalOpen(true);
                      setAddToCampaignMenuAnchorEl();
                    }}
                  >
                    <SmallMenuItemText>Create new campaign</SmallMenuItemText>
                  </MenuItem>
                </PopperMenu>
              </LeftTableButtonsContainer>
              {displayOtherLocations && (
                <RightTableButtonsContainer>
                  <OptionsFilterDropdown
                    filtered={[view]}
                    options={tableViewOptions}
                    label={`${
                      tableViewOptions.find((o) => o.key === view)?.label
                    }`}
                    leftIconDisplay={false}
                    isMultiSelect={false}
                    onChange={(key) => {
                      if (key !== view) {
                        setView(key);
                      }
                    }}
                  />
                </RightTableButtonsContainer>
              )}
            </TableButtonsContainer>
            <ResultSizeContainer>
              <NumResultsContainer>
                <ActionsText>
                  {formatNumber(numResults)}{' '}
                  {context === campaignMemberManagementContextKey &&
                    `eligible `}
                  {queryApplied ? 'result' : 'contact'}
                  {numResults !== 1 ? 's' : ''}{' '}
                  {numSelected &&
                  context === campaignMemberManagementContextKey ? (
                    <>
                      {textSeparatorChar}
                      <SelectedText> {numSelected} selected </SelectedText>
                      <ClientActionText
                        onClick={() => {
                          setSelectedContacts({});
                        }}
                      >
                        Reset selection
                      </ClientActionText>
                    </>
                  ) : numSelected === 1 && context === chatDemoContextKey ? (
                    <>
                      <SelectedText>
                        {textSeparatorChar}{' '}
                        {selectedContacts[Object.keys(selectedContacts)[0]]
                          ?.firstName || ''}{' '}
                        {inDemoMode
                          ? ''
                          : selectedContacts[Object.keys(selectedContacts)[0]]
                              ?.lastName || ''}{' '}
                        selected{' '}
                      </SelectedText>
                      <ClientActionText
                        onClick={() => {
                          setSelectedContacts({});
                          onResetClient();
                        }}
                      >
                        Reset selection
                      </ClientActionText>
                    </>
                  ) : (
                    ''
                  )}
                </ActionsText>
              </NumResultsContainer>
              <FiltersContainer>
                <ActionsText>
                  {clients?.length === 0
                    ? `No results`
                    : `Page ${
                        pageNumber + 1
                      }/${totalNumPages} ${textSeparatorChar} ${pageSize} results/page`}
                </ActionsText>
                <CenteredDiv>
                  <PreviousPageButton
                    disabled={pageNumber === 0 || totalNumPages === 0}
                    onClick={() => {
                      if (pageNumber !== 0) {
                        onUpdatePageNumber(pageNumber - 1);
                      }
                    }}
                  />
                  <NextPageButton
                    disabled={
                      pageNumber + 1 === totalNumPages || totalNumPages === 0
                    }
                    onClick={() => {
                      if (pageNumber + 1 !== totalNumPages) {
                        onUpdatePageNumber(pageNumber + 1);
                      }
                    }}
                  />
                </CenteredDiv>
              </FiltersContainer>
            </ResultSizeContainer>
          </TableActionsContainer>
          <ClientsContainer ctx={context}>
            <TableContainer>
              <Table isEmpty={clients?.length === 0}>
                <TableHead>
                  <TableRow>
                    <TableCellHeader context={context}>
                      {context === campaignMemberManagementContextKey ? (
                        <SmallCheckbox
                          checked={wholePageSelected}
                          onClick={onUpdateSelectedContactsOnPage}
                        />
                      ) : (
                        <></>
                      )}
                    </TableCellHeader>
                    {displayedClientFilterFieldKeys.map((key, idx) => {
                      const keySelectedAsSortParam =
                        key === nameField
                          ? sortParam.toLowerCase().includes(nameField)
                          : key === daysUntilNextUpcomingVisitField
                          ? sortParam.includes(
                              daysUntilNextUpcomingVisitField,
                            ) || sortParam.includes(numUpcomingVisitsField)
                          : sortParam.includes(key);
                      return (
                        <TableCellHeader
                          end={
                            idx === displayedClientFilterFieldKeys?.length - 1
                          }
                          context={context}
                        >
                          {clientFilterFieldLabelMap[key]['label']}
                          {keySelectedAsSortParam ? (
                            <SortFieldContainer
                              onClick={(e) => {
                                setSortParamMenuAnchorEl(e.currentTarget);
                                setSortParamFieldMenuToOpen(key);
                              }}
                              selected
                            >
                              <SortIcon selected />
                            </SortFieldContainer>
                          ) : sortableFields.includes(key) ? (
                            <SortFieldContainer
                              onClick={(e) => {
                                setSortParamMenuAnchorEl(e.currentTarget);
                                setSortParamFieldMenuToOpen(key);
                              }}
                            >
                              <SortIcon />
                            </SortFieldContainer>
                          ) : (
                            <></>
                          )}
                        </TableCellHeader>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <PopperMenu
                  open={sortParamMenuOpenBoolean}
                  anchorElement={sortParamMenuAnchorEl}
                  onClose={() => setSortParamMenuAnchorEl()}
                  variant='offset'
                >
                  <SmallMenuItemTitleContainer disableRipple>
                    <SmallMenuItemTitleText>
                      Select sort field
                    </SmallMenuItemTitleText>
                  </SmallMenuItemTitleContainer>
                  {sortParamFieldMenuToOpen === nameField ? (
                    <>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(firstNameField, ascendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          First name ascending
                        </SmallMenuItemText>
                        {sortParam ===
                          getSortParam(firstNameField, ascendingKey) && (
                          <MenuItemCheckmark />
                        )}
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(firstNameField, descendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          First name descending
                          {sortParam ===
                            getSortParam(firstNameField, descendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(lastNameField, ascendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Last name ascending
                          {sortParam ===
                            getSortParam(lastNameField, ascendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(lastNameField, descendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Last name descending
                          {sortParam ===
                            getSortParam(lastNameField, descendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                    </>
                  ) : sortParamFieldMenuToOpen === memberStatusField ? (
                    <>
                      <MenuItem disableRipple>
                        <SmallMenuItemText
                          onClick={() =>
                            onUpdateSortParam(
                              getSortParam(memberStatusField, ascendingKey),
                            )
                          }
                        >
                          Member status ascending
                          {sortParam ===
                            getSortParam(memberStatusField, ascendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(memberStatusField, descendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Member status descending
                          {sortParam ===
                            getSortParam(memberStatusField, descendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                    </>
                  ) : sortParamFieldMenuToOpen === numVisitsField ? (
                    <>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(numVisitsField, ascendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Num. visits ascending
                          {sortParam ===
                            getSortParam(numVisitsField, ascendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(numVisitsField, descendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Num. visits descending
                          {sortParam ===
                            getSortParam(numVisitsField, descendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                    </>
                  ) : sortParamFieldMenuToOpen === daysSinceLastVisitField ? (
                    <>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(daysSinceLastVisitField, ascendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Days since last visit ascending
                          {sortParam ===
                            getSortParam(
                              daysSinceLastVisitField,
                              ascendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(
                              daysSinceLastVisitField,
                              descendingKey,
                            ),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Days since last visit descending
                          {sortParam ===
                            getSortParam(
                              daysSinceLastVisitField,
                              descendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                    </>
                  ) : sortParamFieldMenuToOpen ===
                    daysUntilNextUpcomingVisitField ? (
                    <>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(numUpcomingVisitsField, ascendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Num. upcoming visits ascending
                          {sortParam ===
                            getSortParam(
                              numUpcomingVisitsField,
                              ascendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(numUpcomingVisitsField, descendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Num. upcoming visits descending
                          {sortParam ===
                            getSortParam(
                              numUpcomingVisitsField,
                              descendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(
                              daysUntilNextUpcomingVisitField,
                              ascendingKey,
                            ),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Days until next visit ascending
                          {sortParam ===
                            getSortParam(
                              daysUntilNextUpcomingVisitField,
                              ascendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(
                              daysUntilNextUpcomingVisitField,
                              descendingKey,
                            ),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Days until next visit descending
                          {sortParam ===
                            getSortParam(
                              daysUntilNextUpcomingVisitField,
                              descendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                    </>
                  ) : sortParamFieldMenuToOpen === lastMessagedAtField ? (
                    <>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(lastMessagedAtField, ascendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Last contacted ascending
                          {sortParam ===
                            getSortParam(lastMessagedAtField, ascendingKey) && (
                            <MenuItemCheckmark />
                          )}
                        </SmallMenuItemText>
                      </MenuItem>
                      <MenuItem
                        disableRipple
                        onClick={() =>
                          onUpdateSortParam(
                            getSortParam(lastMessagedAtField, descendingKey),
                          )
                        }
                      >
                        <SmallMenuItemText>
                          Last contacted descending
                          {sortParam ===
                            getSortParam(
                              lastMessagedAtField,
                              descendingKey,
                            ) && <MenuItemCheckmark />}
                        </SmallMenuItemText>
                      </MenuItem>
                    </>
                  ) : (
                    <></>
                  )}
                </PopperMenu>
                {clients?.length === 0 ? (
                  <EmptySearchContainer>
                    <LightESSText>None returned</LightESSText>
                  </EmptySearchContainer>
                ) : (
                  <TableBody>
                    {clients.map((c, idx) => {
                      const id = c.id;
                      const isLastRow =
                        idx === pageSize - 1 || idx === clients?.length - 1;

                      let serviceCategoriesUsed;
                      const servicesUsedVal = c.serviceCategoriesUsed || [];
                      if (
                        servicesUsedVal?.length === 1 &&
                        servicesUsedVal?.[0] === emptyArrayValue
                      ) {
                        serviceCategoriesUsed = noneKey;
                      } else {
                        const labelsArr = servicesUsedVal.sort();
                        serviceCategoriesUsed = labelsArr.join(', ');
                      }

                      let tags;
                      let fullTagsString;
                      const baseTags = c.tags;
                      const numTags = baseTags?.length || 0;
                      let containsLongerTag = false;
                      if (
                        !numTags ||
                        (numTags === 1 && baseTags[0] === emptyArrayValue)
                      ) {
                        tags = noneKey;
                        fullTagsString = '';
                      } else {
                        const numTags = c.tags.length;
                        const maxDisplayed = 3;
                        const labelsArr = c.tags.sort();
                        const fullLengthDisplayedTags = [];
                        tags = labelsArr
                          .slice(0, maxDisplayed)
                          .map((t) => {
                            fullLengthDisplayedTags.push(t);
                            if (t.length > 18) {
                              containsLongerTag = true;
                              return `${t.slice(0, 15)}...`;
                            } else {
                              return t;
                            }
                          })
                          .join(', ');
                        if (numTags > maxDisplayed) {
                          tags += `...(+${numTags - maxDisplayed} more)`;
                          fullTagsString = labelsArr.join(', ');
                        } else if (containsLongerTag) {
                          fullTagsString = fullLengthDisplayedTags.join(', ');
                        }
                      }

                      const isSelected = id in selectedContacts;

                      let campaignText;
                      const sourceCampaignArr = c.campaigns;
                      if (
                        !sourceCampaignArr.length ||
                        sourceCampaignArr.includes(emptyArrayValue)
                      ) {
                        campaignText = 'None';
                      } else {
                        const campaignStringsArr = [];
                        sourceCampaignArr.map((c) => {
                          const split = c.split(intraStringSeparatorChar);
                          const campaignId = split[0];
                          const campaignName = campaignNameMap[campaignId];
                          const status = split[1];
                          const formattedCampaignString = `${
                            campaignName ?? unknownCampaignKey
                          } (${campaignMemberStatusLabelMap[status]})`;
                          campaignStringsArr.push(formattedCampaignString);
                        });
                        campaignText = campaignStringsArr.join(', ');
                      }

                      let lastMessagedText;

                      if (c.lastMessagedAt === emptyIntValue) {
                        lastMessagedText = 'Never';
                      } else {
                        const platformLabel =
                          appNameLabelMap[c?.lastMessagedPlatform];
                        const lastMessageDaysAgo = getDaysBetweenDates(
                          c.lastMessagedAt,
                          currentDateString,
                          timezone,
                        );
                        lastMessagedText = `${
                          lastMessageDaysAgo <= 0
                            ? `Today`
                            : lastMessageDaysAgo === 1
                            ? 'Yesterday'
                            : `${formatNumber(
                                safeParseInt(lastMessageDaysAgo),
                              )} days ago`
                        } (${platformLabel || 'LiveIQ'})`;
                      }

                      const disabled = !c.canBeTexted;

                      const phoneType =
                        !c.phoneType || c.phoneType === emptyStringValue
                          ? unknownKey
                          : c.phoneType;
                      const phoneIsValid =
                        c.phoneIsValid === true &&
                        ['mobile', unclassifiedKey].includes(phoneType);

                      const lastVisitServices = (
                        c.lastVisitSessionTypeNames || []
                      )?.filter((n) => !!n && n !== emptyArrayValue);

                      const daysSinceLastVisit = c.daysSinceLastVisit;
                      const daysSinceLastVisitAtBusiness =
                        c.daysSinceLastVisitAtBusiness;
                      const lastVisitAtBusinessLocationId =
                        c.lastVisitAtBusinessLocationId;
                      let lastVisitText;
                      if (
                        daysSinceLastVisit !== c.daysSinceLastVisitAtBusiness &&
                        daysSinceLastVisitAtBusiness !== emptyIntValue &&
                        daysSinceLastVisitAtBusiness >= 0 &&
                        displayMultiLocationData
                      ) {
                        lastVisitText = (
                          <>
                            {baseLocationName}:{' '}
                            {dateToTextFormat(c.lastVisit) || noneKey}
                            <br></br>
                            {daysSinceLastVisit != -1 ? (
                              <>
                                (
                                {daysSinceLastVisit === 0
                                  ? 'Today'
                                  : daysSinceLastVisit === 1
                                  ? 'Yesterday'
                                  : `${formatNumber(
                                      daysSinceLastVisit,
                                    )} days ago`}
                                )
                              </>
                            ) : (
                              ''
                            )}
                            <br></br>
                            <br></br>
                            {locationMap[lastVisitAtBusinessLocationId]}:{' '}
                            {dateToTextFormat(c.lastVisitAtBusiness) || noneKey}
                            <br></br>
                            {daysSinceLastVisitAtBusiness != -1 ? (
                              <>
                                (
                                {daysSinceLastVisitAtBusiness === 0
                                  ? 'Today'
                                  : daysSinceLastVisitAtBusiness === 1
                                  ? 'Yesterday'
                                  : `${formatNumber(
                                      daysSinceLastVisitAtBusiness,
                                    )} days ago`}
                                )
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        );
                      } else {
                        lastVisitText = (
                          <>
                            {dateToTextFormat(c.lastVisit) || noneKey}
                            <br></br>
                            {daysSinceLastVisit != -1 ? (
                              <>
                                (
                                {daysSinceLastVisit === 0
                                  ? 'Today'
                                  : daysSinceLastVisit === 1
                                  ? 'Yesterday'
                                  : `${formatNumber(
                                      daysSinceLastVisit,
                                    )} days ago`}
                                )
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        );
                      }

                      const locationMemberStatus =
                        c.memberStatus || inactiveKey;
                      const businessMemberStatus =
                        c.memberStatusAtBusiness || inactiveKey;
                      const businessMemberStatusLocationId =
                        c.memberStatusAtBusinessLocationId;
                      let memberStatusText;
                      if (
                        businessMemberStatusLocationId !== locationId &&
                        businessMemberStatusLocationId !== emptyStringValue &&
                        locationMemberStatus !== businessMemberStatus &&
                        businessMemberStatus &&
                        businessMemberStatus !== inactiveKey &&
                        displayMultiLocationData
                      ) {
                        memberStatusText = (
                          <>
                            {baseLocationName}:{' '}
                            {membershipStatusLabelMap[locationMemberStatus]}
                            <br></br>
                            {locationMap[businessMemberStatusLocationId]}:{' '}
                            {membershipStatusLabelMap[businessMemberStatus]}
                          </>
                        );
                      } else {
                        memberStatusText =
                          membershipStatusLabelMap[locationMemberStatus];
                      }

                      return (
                        <TableRow
                          key={id}
                          disabled={disabled}
                        >
                          <TableCell isLastRow={isLastRow}>
                            <ExtraTinyGapColumnCenteredDiv>
                              <SmallCheckbox
                                checked={isSelected}
                                onClick={() => {
                                  if (
                                    context ===
                                    campaignMemberManagementContextKey
                                  ) {
                                    const updatedSelectedClients = {
                                      ...selectedContacts,
                                    };

                                    if (isSelected) {
                                      delete updatedSelectedClients[id];
                                    } else {
                                      updatedSelectedClients[id] = c;
                                    }

                                    setSelectedContacts(updatedSelectedClients);
                                  } else {
                                    const currentSelectedClientId =
                                      Object.keys(selectedContacts)[0];
                                    if (currentSelectedClientId === id) {
                                      setSelectedContacts({});
                                    } else {
                                      const newSelectedClients = {
                                        [id]: c,
                                      };
                                      setSelectedContacts(newSelectedClients);
                                    }
                                  }
                                }}
                              />
                              <Tooltip
                                title={
                                  <TooltipTitleText>
                                    Copy ID ({id.slice(0, 4)})
                                  </TooltipTitleText>
                                }
                                placement='bottom'
                              >
                                <ExtraSmallLightPrimaryCopyIcon
                                  onClick={() => {
                                    copyTextToClipboard(id);
                                    setSnackbarMessage('Copied');
                                  }}
                                />
                              </Tooltip>
                            </ExtraTinyGapColumnCenteredDiv>
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {getFormattedFullNameFromUser(c, inDemoMode)}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {contactTypeLabelMap[c.type]}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {campaignText}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {lastMessagedText}
                          </TableCell>
                          {context !== campaignMemberManagementContextKey && (
                            <TableCell isLastRow={isLastRow}>
                              {booleanLabelMap[c.dnd]}
                            </TableCell>
                          )}
                          <TableCell isLastRow={isLastRow}>
                            {c.gender === femaleKey
                              ? `Female`
                              : c.gender === maleKey
                              ? 'Male'
                              : unknownKey}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            <CenteredDiv>
                              <Tooltip
                                title={
                                  <TooltipSubtitleText>
                                    {!c.email ? (
                                      'Unknown email'
                                    ) : inDemoMode ? (
                                      'redacted'
                                    ) : (
                                      <>
                                        Copy email:
                                        <br></br>
                                        {c.email}
                                      </>
                                    )}
                                  </TooltipSubtitleText>
                                }
                              >
                                <ClientSearchEmailIcon
                                  onClick={() => {
                                    if (c.email && !inDemoMode) {
                                      onCopyText(c.email, 'Email');
                                    }
                                  }}
                                  unknown={!c.email}
                                />
                              </Tooltip>
                              <CenteredDivWithExtraSmallGap>
                                <Tooltip
                                  title={
                                    <TooltipSubtitleText>
                                      {!c.phone ? (
                                        'Unknown phone'
                                      ) : inDemoMode ? (
                                        'redacted'
                                      ) : (
                                        <>
                                          Copy phone:
                                          <br></br>
                                          {formatPhoneNumber(c.phone)}
                                        </>
                                      )}
                                    </TooltipSubtitleText>
                                  }
                                >
                                  <ClientSearchPhoneIcon
                                    onClick={() => {
                                      if (c.phone && !inDemoMode) {
                                        onCopyText(
                                          formatPhoneNumber(c.phone),
                                          'Phone number',
                                        );
                                      }
                                    }}
                                    unknown={!c.phone}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <TooltipSubtitleText>
                                      {!phoneIsValid
                                        ? `Invalid phone (Type: ${
                                            phoneType === unknownKey
                                              ? 'unknown'
                                              : phoneType
                                          })`
                                        : `Valid phone (${
                                            phoneType === unclassifiedKey
                                              ? 'unchecked'
                                              : phoneType
                                          })`}
                                    </TooltipSubtitleText>
                                  }
                                >
                                  {phoneIsValid ? '✔' : 'ⅹ'}
                                </Tooltip>
                              </CenteredDivWithExtraSmallGap>
                            </CenteredDiv>
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {memberStatusText}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {c.numVisits || 0}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {lastVisitText}
                            {/* {lastVisitServices?.length ? (
                              <>
                                <br></br>
                                <br></br>
                                {lastVisitServices.join(',')}
                              </>
                            ) : (
                              ''
                            )} */}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {c.daysUntilNextUpcomingVisit != -1
                              ? c.numUpcomingVisits
                              : noneKey}
                            {c.daysUntilNextUpcomingVisit != -1 ? (
                              <>
                                , next one in:
                                <br></br>
                                {c.daysUntilNextUpcomingVisit} day
                                {c.daysUntilNextUpcomingVisit === 1 ? '' : 's'}
                              </>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell isLastRow={isLastRow}>
                            {serviceCategoriesUsed}
                          </TableCell>
                          <TableCell
                            isLastRow={isLastRow}
                            end
                          >
                            {fullTagsString ? (
                              <Tooltip
                                title={
                                  <TooltipTitleText>
                                    {fullTagsString}
                                  </TooltipTitleText>
                                }
                              >
                                {tags}
                              </Tooltip>
                            ) : (
                              <>{tags}</>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </ClientsContainer>
        </ColumnDivWithSmallGap>
        <FilterClientsModal
          isOpen={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          filters={filters}
          locationId={locationId}
          onApply={onApplyFilters}
          campaignNameMap={campaignNameMap}
          tags={tags}
          context={context}
        />
        <CreateCampaignModal
          isOpen={createCampaignModalOpen}
          onClose={() => setCreateCampaignModalOpen(false)}
          onCreate={(campaign) => {
            const id = campaign.id;

            setCreateCampaignModalOpen(false);

            if (addToCampaignGroup === allCampaignAddType) {
              setCreateSmartListModalOpen(true);
              setCampaignForSmartList(campaign);
            } else {
              onAddSelectedContacts(id);
            }
          }}
        />
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
        <SmartListModal
          isOpen={createSmartListModalOpen}
          onClose={() => setCreateSmartListModalOpen(false)}
          campaign={campaignForSmartList}
          onSelect={onAddAllMemberResults}
        />
        <SelectCampaignForChatDemoModal
          isOpen={selectCampaignForChatDemoModalOpen}
          campaigns={userCampaignsForChatDemo}
          onSelect={onSelectCampaignForChatDemo}
          onClose={() => {
            setSelectCampaignForChatDemoModalOpen(false);
            setUserCampaignsForChatDemo([]);
          }}
        />
        <PermanentlyDeleteCampaignMembersModal
          isOpen={removeFromCampaignsModalOpen}
          onClose={() => setRemoveFromCampaignsModalOpen(false)}
          clientsToDelete={selectedContacts}
          onDelete={onDeleteMembersFromCampaigns}
        />
        <CreateContactModal
          isOpen={createContactModalOpen}
          onClose={() => setCreateContactModalOpen(false)}
          onCreate={onCreateContact}
        />
      </>
    )
  );
};

export default ClientSearch;
