import { gql } from '@apollo/client';

export const CREATE_CLIENT_FILTER_TEMPLATE = gql`
  mutation createClientFilterTemplate($name: String!, $filter: String!) {
    createClientFilterTemplate(name: $name, filter: $filter) {
      id
      name
      filter
      createdAt
    }
  }
`;

export const EDIT_CLIENT_FILTER_TEMPLATE = gql`
  mutation editClientFilterTemplate($id: String!, $name: String!) {
    editClientFilterTemplate(id: $id, name: $name) {
      success
    }
  }
`;

export const DELETE_CLIENT_FILTER_TEMPLATE = gql`
  mutation deleteClientFilterTemplate($id: String!) {
    deleteClientFilterTemplate(id: $id) {
      success
    }
  }
`;

export const RESYNC_LEADS = gql`
  mutation resyncLeads {
    resyncLeads {
      success
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($contact: JSON!) {
    createContact(contact: $contact) {
      success
    }
  }
`;
