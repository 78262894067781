import { AiFillThunderbolt } from 'react-icons/ai';
import styled, { css, keyframes } from 'styled-components';
import {
  AgentColorStyle,
  BookedIcon,
  CenterFlexStyle,
  ColumnFlexStyle,
  ContentContainer,
  DarkGoldText,
  DarkLargeText,
  MediumExternalLinkPrimaryButton,
  PageContainer,
  RocketIcon,
  SmallGapStyle,
  SmallIconSize,
  SmallText,
  TinyText,
} from '../../../styles/shared-styled-components';

const moveDots = keyframes`
  from {
    transform: rotate(0deg) scale(12) translateX(-30px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(+30px);
  }
`;

// core colors from site color scheme
const colors = [
  '#7161a0', // primary
  '#9c90bd', // lightPrimary
  '#5a4e80', // darkPrimary
  '#2f2f2f', // charcoal
  '#4f4f4f', // lightCharcoal
  'rgb(58, 133, 136)', // secondary
  '#F9e77e', // gold
];

const createDotsMixin = (count) => {
  let textShadow = '';
  for (let i = 0; i < count; i++) {
    const x = (-0.5 + Math.random() * 3).toFixed(2);
    const y = (-0.5 + Math.random() * 3).toFixed(2);
    const color = colors[Math.floor(Math.random() * colors.length)]; // Randomly select a color from the array
    textShadow += `${x}em ${y}em 7px ${color}, `;
  }
  return textShadow.slice(0, -2); // Remove trailing comma and space
};

const DotAnimationContainer = styled.div`
  pointer-events: none;
  font: 5vmin/1.3 Serif;
  overflow: hidden;
  background: #123;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  html {
    font-size: 52px;
    color: transparent;
  }

  &::before,
  &::after {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 3em;
    height: 3em;
    content: '.';
    color: transparent;
    mix-blend-mode: screen;
    animation: ${moveDots} infinite ease-in-out alternate;
  }

  &::before {
    text-shadow: ${createDotsMixin(40)};
    animation-duration: 90s;
    animation-delay: -27s;
  }

  &::after {
    text-shadow: ${createDotsMixin(40)};
    animation-duration: 90s;
    animation-delay: -32s;
  }
`;

export const DotAnimation = () => {
  return <DotAnimationContainer />;
};

export const HeroContainer = styled.div`
  margin-top: ${(props) =>
    props.size === 'xs' ? '50px' : props.size === 's' ? '45px' : '10px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 70vh;

  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.color.lightGray} 0%,
    ${({ theme }) => theme.color.superLightPrimary} 50%,
    ${({ theme }) => theme.color.lightGray} 100%
  );
  background-size: 100%;
  animation: gradient 15s ease infinite;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .circle {
    position: absolute;
    border-radius: 50%;
    pointer-events: none; // So circles don't interfere with hover
    transition: background-color 0.3s ease;
  }

  .chat-container {
    width: 300px;
    height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  @keyframes slideUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .message {
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    opacity: 0;
    animation: slideUp 1s forwards;
  }
`;

export const TextContainer = styled.div`
  width: 45%;
  margin-top: ${(props) => !props.hideTopMargin && `calc(40px + 5vw)`};

  grid-column: span 7;
  padding-right: 5%;
  text-align: left;

  /* For screens wider than 1024px, padding-left ensures content is aligned to the left */
  @media (max-width: 1024px) {
    padding: 0;
    width: 80%;
    margin: 0 auto; /* Center horizontally */
    text-align: center;
    display: flex;
    justify-content: center; /* Horizontally center all content */
    align-items: center; /* Vertically center if needed */
    flex-direction: column; /* Stack elements vertically */
  }

  @media (max-width: 576px) {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }
`;

export const AgentsContainer = styled.div`
  height: fit-content;
  min-height: 250px;
  margin-top: ${(props) =>
    props.topMargin != null
      ? `${props.topMargin}px`
      : props.size
      ? '100px'
      : '5vw'};
  display: flex;
  justify-content: center;
  position: relative;
  overflow: visible;
  ${(props) =>
    props.size
      ? css`
          width: min(100vw, 480px);
          margin-bottom: 47px;
        `
      : css`
          width: 380px;
        `}

  img {
    // Zoom in on the picture
    min-width: 100%;
    height: fit-content;
    z-index: 10;
    margin: auto 0;
    border-radius: 30px;
    transition: transform 0.3s; /* Add transition for smooth zoom effect */
    object-fit: cover; /* Ensures the image covers the area without distortion */

    &:hover {
      transform: scale(1.1); /* Increase scale for a more zoomed-in effect */
    }
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export const AgentContainer = styled.div`
  transition: opacity 0.5s ease-in-out;
  justify-content: center;
  align-items: center;
  position: absolute;

  ${(props) =>
    props.folds
      ? css`
          width: 220px;
          height: 220px;
        `
      : css`
          width: 250px;
          height: 250px;
        `}

  ${(props) =>
    props.position === 0
      ? css`
          left: 20px;
        `
      : props.position === 2
      ? css`
          right: 20px;
        `
      : css``};

  ${(props) =>
    props.selected
      ? css`
          opacity: 1;
          z-index: 5;
        `
      : css`
          opacity: 0.5;
          filter: grayscale(100%) blur(5px);
          z-index: ${(props) => (props.shouldHide ? `0` : `1`)};
        `}

  img {
    width: 100%;
    height: 100%; /* Force the image to fill the square container */
    border-radius: 50%; /* Perfect circle */
    object-fit: cover; /* Ensures the image fits without distortion */
    border: 3px solid ${({ theme }) => theme.color.primary};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  }
`;

export const AgentInfo = styled.div`
  text-align: center;
  ${CenterFlexStyle}
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  font-size: calc(16px + 0.8vw);
  padding-top: 10px;
`;

export const AgentInfoHeader = styled(AgentInfo)`
  padding-bottom: 20px;
  font-size: calc(20px + 1.2vw);
`;

export const AgentInfoSubtext = styled.div`
  text-align: center;
  ${CenterFlexStyle}
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
  font-size: 18px;
  min-width: 110%;
  margin-top: 3px;
`;

export const BookedChatBubble = styled.div`
  background-color: white;
  border-radius: 30px;
  padding: 10px 15px;
  max-width: 350px;
  width: fit-content;
  margin: 10px;
  font-size: 12px;
  position: absolute;
  top: -19%; /* Adjust this value */
  right: -85%; /* Adjust this value */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  img {
    min-width: 20px !important;
    max-width: 20px;
    height: auto;
  }

  @media (max-width: 1340px) {
    top: -26%; /* Adjust this value */
    right: -56%; /* Adjust this value */
  }

  @media (max-width: 1024px) {
    top: -25%; /* Adjust this value */
    left: -40%; /* Adjust this value */
    font-size: 10px;
    padding: 5px 10px;
    border-radius: 20px;
  }
`;

export const DepositReceivedChatBubble = styled(BookedChatBubble)`
  // animation-delay: 5s;
  top: 25%; /* Adjust this value */
  left: 80%; /* Adjust this value */

  @media (max-width: 1340px) {
    left: 60%;
    top: 32%;
  }

  @media (max-width: 1024px) {
    left: 29%;
    top: 49%;
    font-size: 10px;
    padding: 5px 10px;
  }
`;

export const AnalyticsChatBubble = styled(BookedChatBubble)`
  // animation-delay: 10s;
  top: 60%; /* Adjust this value */
  left: 25%; /* Adjust this value */

  @media (max-width: 1340px) {
    left: -28%;
    top: 60%;
  }

  @media (max-width: 1024px) {
    left: -28%;
    top: 65%;
    font-size: 10px;
    padding: 5px 10px;
  }
`;

export const IncomingMessageBubble = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 25px;
  align-content: center;
  text-align: right;
  padding: 0px 0px;
  border-radius: 30px;
`;

export const GrayMessageBg = styled.div`
  background-color: rgb(240, 240, 240);
  color: black;
  height: 20px;
  align-content: center;
  border-radius: 20px;
  padding: 3px 15px;
`;

export const BlueMessageBg = styled(GrayMessageBg)`
  background-color: #0084ff;
  color: white;
`;

export const OutgoingMessageBubble = styled(IncomingMessageBubble)`
  justify-content: flex-start;
  text-align: left;
  color: white;
`;

export const AvaMessageBubbleBookedIcon = styled.div`
  background: ${({ theme }) => theme.color.deepGreen};
  border-radius: 15px;
  color: white;
  padding: 3px 10px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
`;

export const AvaMessageBubbleDepositedIcon = styled(AvaMessageBubbleBookedIcon)`
  background: ${({ theme }) => theme.color.secondary};
`;

export const AvaMessageBubbleAnalyzingIcon = styled(AvaMessageBubbleBookedIcon)`
  background: ${({ theme }) => theme.color.warning};
  color: black;
`;

export const BubbleRightSide = styled.div`
  width: fit-content;
  white-space: nowrap;
  align-content: center;
`;

export const BubbleLeftSide = styled(BubbleRightSide)`
  padding-right: 5px;
`;

export const SvgShapeBg = styled.div`
  opacity: 0.7;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220%200%20100%20100%22%3E%3Cdefs%3E%3ClinearGradient fill-opacity%3D%220.1%22 id%3D%22sw-gradient%22 x1%3D%220%22 x2%3D%221%22 y1%3D%221%22 y2%3D%220%22%3E%3Cstop id%3D%22stop1%22 stop-color%3D%22rgba(57%2C%2049%2C%2080%2C%201)%22 offset%3D%220%25%22/%3E%3Cstop id%3D%22stop2%22 stop-color%3D%22rgba(113%2C%2097%2C%20160%2C%201)%22 offset%3D%22100%25%22/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill%3D%22url(%23sw-gradient)%22 d%3D%22M10.6%2C-16.7C14.8%2C-16%2C19.8%2C-15.2%2C25.7%2C-12.4C31.6%2C-9.5%2C38.3%2C-4.8%2C40.9%2C1.5C43.5%2C7.8%2C42%2C15.6%2C35.1%2C16.6C28.2%2C17.6%2C15.8%2C11.8%2C9.1%2C13.5C2.3%2C15.1%2C1.2%2C24.1%2C-1.8%2C27.3C-4.8%2C30.5%2C-9.7%2C27.8%2C-16.3%2C26.1C-23%2C24.5%2C-31.6%2C23.8%2C-35.8%2C19.6C-40.1%2C15.4%2C-40.1%2C7.7%2C-36.2%2C2.2C-32.3%2C-3.2%2C-24.6%2C-6.5%2C-18.2%2C-7.1C-11.9%2C-7.7%2C-7.1%2C-5.8%2C-4.3%2C-7.3C-1.5%2C-8.8%2C-0.7%2C-13.8%2C1.3%2C-15.9C3.2%2C-18.1%2C6.5%2C-17.5%2C10.6%2C-16.7Z%22 width%3D%22100%25%22 height%3D%22100%25%22 transform%3D%22translate(50%2050)%22 style%3D%22transition%3A%200.3s%3B%22 stroke-width%3D%220%22 stroke%3D%22url(%23sw-gradient)%22/%3E%3C/svg%3E');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 0;
`;

export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TopSvgWave = styled.div`
  position: absolute;
  top: 65;
  left: 0;
  width: 100%;
  height: auto;
`;

export const SvgBackgroundDiv = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%237161a0' fill-opacity='0.4' d='M0,32L48,53.3C96,75,192,117,288,149.3C384,181,480,203,576,202.7C672,203,768,181,864,192C960,203,1056,245,1152,245.3C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'/%3E%3Cpath fill='%237161a0' fill-opacity='0.4' d='M0,32L48,53.3C96,75,192,117,288,149.3C384,181,480,203,576,202.7C672,203,768,181,864,192C960,203,1056,245,1152,245.3C1248,245,1344,203,1392,181.3L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'/%3E%3C/svg%3E");
  background-size: cover; /* Adjust size: 'contain' or 'auto' also possible */
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  height: 320px; /* Adjust height to match the SVG's aspect ratio */
`;

export const BottomSvgWave = styled.div`
  position: absolute;
  top: 90;
  left: 0;
  width: 100%;
  height: auto;
`;

export const GradientSpan = styled.span`
  background: linear-gradient(
    155deg,
    ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.lightGray
        : ({ theme }) => theme.color.charcoal},
    ${({ theme }) => theme.color.primary} 50%,
    ${(props) =>
      props.dark
        ? ({ theme }) => theme.color.lightGray
        : ({ theme }) => theme.color.charcoal}
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.15;
`;

export const SquigglyLine = styled.span`
  width: 100%;
  height: 300px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="300" height="100"><defs><linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color:white;stop-opacity:1" /><stop offset="50%" style="stop-color:%237161A0;stop-opacity:1" /><stop offset="100%" style="stop-color:white;stop-opacity:1" /></linearGradient></defs><path d="M3 9.411C31.03 5.372 99.63-.956 149.8 6.043c50.169 6.999 13.018 10.2-11.828 10.925" stroke="url(%23gradient)" stroke-width="4.748" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>');
  background-repeat: no-repeat;
  background-position: bottom -125px center;
  background-size: 160%;
  overflow: visible;
`;
export const HeaderText = styled(DarkLargeText)`
  position: relative;
  max-width: 50rem;
  text-align: start;
  font-size: 50px;
  letter-spacing: -0.025em;
  pointer-events: none;

  @media (max-width: 1024px) {
    font-size: 40px;
    text-align: center;
  }

  @media (max-width: 576px) {
    font-size: 30px;
    text-align: center;
  }
`;

export const FrontPageContainer = styled.div`
  margin-bottom: 20px;
`;

export const HeaderSubTextContainer = styled.div`
  margin: 18px 0px;
`;

export const HeaderSubText = styled(SmallText)`
  letter-spacing: -0.5px;
  max-width: 50rem;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  line-height: 1.2;
  pointer-events: none;
  text-align: ${(props) => (props.center ? 'center' : 'start')};

  font-size: 20px;

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 576px) {
    text-align: center;
  }
`;

// Keyframes for the rotating words
const rotateWord = keyframes`
  0% { opacity: 0; }
  2% { opacity: 0; transform: translateY(-30px); }
  5% { opacity: 1; transform: translateY(0px); }
  17% { opacity: 1; transform: translateY(0px); }
  20% { opacity: 0; transform: translateY(30px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
`;

// Main container for words
export const Words = styled.div`
  display: inline;
  text-indent: 10px;
  position: relative; /* to handle absolute positioning of span */
`;

// Rotating words animation
export const RotateWords = styled.div`
  display: inline-block;
  position: relative;
  span {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    font-weight: 700;
    animation: ${rotateWord} 9s linear infinite;
  }
  span:nth-child(1) {
    color: #ff0004;
  }
  span:nth-child(2) {
    color: #ff6e00;
    animation-delay: 3s;
  }
  span:nth-child(3) {
    color: #ffed00;
    animation-delay: 6s;
  }
`;

export const WordSwapContainer = styled.span`
  display: inline-block;
  position: relative;
  overflow: hidden;
  align-content: baseline;
`;

export const WordSwap = styled.span`
  position: relative;
  display: inline-block;
  text-align: left;

  &::before {
    top: 0;
    left: 0;
    content: '';
    opacity: 0;
    animation: swapWords 6s infinite;
  }

  /* Keyframes for swapping words */
  @keyframes swapWords {
    5% {
      opacity: 1;
    }
    28% {
      opacity: 1;
    }
    33% {
      opacity: 0;
      content: 'reviews';
    }
    38% {
      opacity: 1;
    }
    61% {
      opacity: 1;
    }
    66% {
      opacity: 0;
      content: 'bookings';
    }
    71% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      content: 'revenue';
    }
  }

  @media (max-width: 1024px) {
    font-size: 40px;
    text-align: center;
  }

  @media (max-width: 576px) {
    font-size: 30px;
    text-align: center;
  }
`;

// Styled Components
export const SetupText = styled.div`
  display: flex;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  align-items: left;
  justify-content: flex-start;
  font-size: 18px;
  margin-top: 20px;
`;

const slide = keyframes`
  0% { transform: translateX(0);}
  100% { transform: translateX(-50%); }
`;

export const FeaturesContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping */
  max-width: 600px; /* Set max width */
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};
`;

export const Feature = styled.div`
  width: fit-content;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-right: 10px;
  font-size: 15px;
  background: transparent;
  white-space: nowrap;

  ${(props) =>
    props.separateLine &&
    css`
      width: 310px;
      justify-content: space-between;
      padding: 5px;
    `}

  transition: all 0.3s ease;
  &:hover {
    color: ${(props) =>
      props.dark ? ({ theme }) => theme.color.primary : 'black'};
  }
`;

export const CarouselContainer = styled.div`
  width: 300px; /* Set the width of the container */
  overflow-x: hidden; /* Hide overflow */
  display: flex;
  align-items: center;

  ::after {
    width: 300px; /* Set the width of the container */
    overflow-x: hidden; /* Hide overflow */
    display: flex;
    align-items: center;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.color.primary} 0%,
      rgba(255, 255, 255, 0) 15%,
      rgba(255, 255, 255, 0) 85%,
      ${({ theme }) => theme.color.primary} 100%
    );
  }
`;

export const Slider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow-x: hidden;
`;

export const SlideTrack = styled.div`
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
  display: flex;
  width: calc(600px * 3); /* Double the number of slides for seamless loop */
  border-radius: 20px;
  animation: ${slide} 30s linear infinite; /* Sliding animation */
`;

export const Slide = styled.div`
  width: 300px;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-right: 10px;
  font-size: 13px;
  background: ${({ theme }) => theme.color.primary};
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.08);
`;

export const LogoCarouselContainer = styled(CarouselContainer)`
  width: 800px;

  @media (max-width: 1024px) {
    width: 500px;
  }

  @media (max-width: 576px) {
    width: 300px;
  }

  margin-top: -50px;
`;

export const LogoSlideTrack = styled(SlideTrack)`
  width: calc(250px * 7); /* Double the number of slides for seamless loop */
`;

export const LogoSlide = styled(Slide)`
  ${(props) =>
    props.fixedWidth &&
    css`
      min-width: 73px !important;
      max-width: 73px !important;
    `}
  background: transparent;
  border: none;
  align-items: center;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-content: middle;
  flex-direction: column;
  padding-top: 30px;
`;

export const DemoButton = styled(MediumExternalLinkPrimaryButton)`
  padding-left: 29px;
  padding-right: 29px;
  font-size: 18px;
  border-radius: 30px;
  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 576px) {
    font-size: 15px;
  }

  :hover {
    background-color: ${({ theme }) => theme.color.lightPrimary};
  }
`;

const switchText = keyframes`
  0%, 100% {
    content: 'Appointment Booker';
  }
  15% {    
    content: 'Client Concierge';
  }
  30% {
    content: 'Business Coach';
  }
  45% {
    content: 'Appointment Booker';
  }
`;

export const HighlightedHeaderText = styled.span`
  color: #f9dd94;
  display: inline-block;
  position: relative;
  z-index: 5;
  font-family: ${({ theme }) => theme.typography.logoFont};

  &::before {
    content: 'Auto-Pilot.';
    position: absolute;
    top: 0; /* Adjust as needed */
    left: 0; /* Adjust as needed */
    color: darkgray; /* Shadow color */
    z-index: -1; /* Places the shadow behind the text */
    transform: translate(4px, 4px); /* Adjust the offset of the shadow */
    font-family: ${({ theme }) =>
      theme.typography.logoFont}; /* Ensure same font-family for consistency */
    font-size: inherit; /* Ensure font-size matches main text */
    line-height: inherit; /* Ensure line-height matches main text */
  }

  &::after {
    z-index: -2; /* Ensure it is behind everything */
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg); /* Rotate the icon */
    top: -10px; /* Adjust position */
    left: 20px; /* Adjust position */
    font-size: 2rem; /* Adjust size */
  }
`;

export const LightningIcon = styled(AiFillThunderbolt)`
  color: ${({ theme }) => theme.color.gold};
  margin-bottom: -8px;
  display: inline-block;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
`;

export const HomepagePageContainerHeader = styled.div`
  letter-spacing: -1px;
  margin-bottom: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 27px;
  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.lightCharcoal};

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

export const AnimatedText = styled(HighlightedHeaderText)`
  width: 10em;
  text-align: start;
  &:after {
    animation: ${switchText} 30s 5;
  }
`;

export const BackgroundContainer = styled.div`
  ${ColumnFlexStyle}
  overflow-x: hidden;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  min-height: 100dvh;
  min-width: 100%;
`;

export const HomepagePageContainer = styled(PageContainer)`
  font-family: ${({ theme }) => theme.typography.baseFont};
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-left: 0px;
  background-color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  height: auto;
`;

export const HomepageContentContainer = styled(ContentContainer)`
  max-width: 100vw;
  padding: 0px;
  flex-grow: 1;
  height: 100%;
  padding-top: ${({ theme }) => theme.spacing.belowHeaderVerticalPadding * 2}px;
`;

const TopBubbleAgentStyle = css`
  ${(props) =>
    props.receptionist
      ? css`
          top: -8px;
          right: -70px;
          border: 1px solid ${(props) => props.theme.color.lightPrimary};
          background: linear-gradient(135deg, #a18cd1, #fbc2eb);
        `
      : props.marketer
      ? css`
          top: ${(props) => (props.size === 'xs' ? '-26px' : '28px')};
          right: ${(props) => (props.size === 'xs' ? '19px' : '-136px')};
          border: 1px solid ${(props) => props.theme.color.turquoise};
          background: linear-gradient(135deg, #66cdaa, #008080);
        `
      : css`
          top: ${(props) => (props.size === 'xs' ? '-26px' : '98px')};
          right: ${(props) => (props.size === 'xs' ? '49px' : '-96px')};
          border: 1px solid ${(props) => props.theme.color.darkBlue};
          background: linear-gradient(135deg, #6daedf, #3a6fa0);
        `}
`;

export const TopBubble = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 30px;
  padding: 6px 15px;
  width: fit-content;
  top: -8px;
  right: -70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${SmallGapStyle}
  ${TopBubbleAgentStyle}
`;

const BottomBubbleAgentStyle = css`
  ${(props) =>
    props.receptionist
      ? css`
          top: ${(props) => (props.size === 's' ? '42px' : '52px')};
          right: ${(props) => (props.size === 's' ? '-122px' : '-112px')};
        `
      : props.marketer
      ? css`
          top: ${(props) => (props.size === 'xs' ? '160px' : '90px')};
          right: ${(props) =>
            props.size === 'xs'
              ? '-60px'
              : props.size === 's'
              ? '-133px'
              : '-131px'};
          border: 1px solid ${(props) => props.theme.color.turquoise};
        `
      : css`
          top: ${(props) => (props.size === 'xs' ? '190px' : '160px')};
          right: ${(props) =>
            props.size === 'xs'
              ? '0px'
              : props.size === 's'
              ? '-110px'
              : '-100px'};
          border: 1px solid ${(props) => props.theme.color.darkBlue};
        `}
`;

export const BottomBubble = styled(TopBubble)`
  ${BottomBubbleAgentStyle}
`;

export const BubbleText = styled(TinyText)`
  ${AgentColorStyle}
`;

export const AgentIconText = styled(DarkGoldText)`
  font-size: 13px;
`;

export const LaunchIcon = styled(RocketIcon)`
  ${SmallIconSize}
  color: ${({ theme }) => theme.color.gold};
`;

export const LeadConvertedIcon = styled(BookedIcon)``;

export const WhiteLabelAgentTextContainer = styled.div`
  ${ColumnFlexStyle}
  margin-top: 20px;
  ${SmallGapStyle}
  ${CenterFlexStyle}
`;
