import { useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useMyCampaigns } from '../../../api/hooks/campaigns';
import { useAllEnterprises } from '../../../api/hooks/enterprise';
import { useMyChatAgentVersionSequences } from '../../../api/hooks/sequence';
import { GET_MY_CHATS_UPDATED_AT } from '../../../api/queries/chat';
import { MY_CHATS_SUBSCRIPTION } from '../../../api/subscriptions/chat';
import { getTypesenseClient } from '../../../api/typesense/typesense-client';
import {
  allKey,
  appointmentStatusesField,
  callIdsField,
  chatAppointmentStatuses,
  chatDisengagementReasons,
  chatInterceptionReasons,
  chatLocationField,
  chatMedia,
  chatMediumField,
  chatStatusField,
  chatStatuses,
  chatsSearchParamsCookieKey,
  disengagementReasonField,
  emptyArrayValue,
  interceptionReasonField,
  numUserMessagesField,
  productionChatMedia,
  sequenceIdsField,
  stagingFilterConversionDelay,
  superAdminRole,
  typesenseChatsSchemaName,
} from '../../../utils/constants';
import { getCookieExpiryObject } from '../../../utils/date';
import {
  baseChatSearchParams,
  bookedFilterKey,
  getArrayFromInputString,
  getTypesenseFilterString,
  needsAttentionKey,
  recentActiveKey,
  starredKey,
  updateSearchFiltersCookie,
} from '../../../utils/filters';
import {
  getUserAgency,
  getUserLocation,
  getUserTypesenseApiKey,
} from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';
import FilterChatsModal from '../../Modals/FilterChatsModal/FilterChatsModal';
import Snackbar from '../../Snackbar';
import ChatRenderer from '../ChatRenderer/ChatRenderer';
import { LoadingChatDrawer } from '../ChatRenderer/styled';

const basePageSize = 200;

const cookieExpiryObject = getCookieExpiryObject();

const defaultChatStatusFilterLength = chatStatuses.length;
const defaultChatAppointmentStatusesFilterLength =
  chatAppointmentStatuses.length;
const defaultDisengagementReasonsFilterLength = chatDisengagementReasons.length;
const defaultInterceptionReasonsFilterLength = chatInterceptionReasons.length;

const getNumFiltersApplied = (
  filters,
  allSequenceIdOptions,
  defaultChatMediaFilterLength,
  inMonitoringMode,
  numTotalLocationIds,
) => {
  let numFiltersApplied = 0;

  const filterKeys = Object.keys(filters);

  filterKeys.map((key) => {
    const filterValue = filters[key];
    if (
      [
        chatMediumField,
        chatStatusField,
        appointmentStatusesField,
        disengagementReasonField,
        interceptionReasonField,
        sequenceIdsField,
        chatLocationField,
      ].includes(key)
    ) {
      const appliedFilter = getArrayFromInputString(filterValue[0].value);
      if (key === chatLocationField && inMonitoringMode) {
        if (appliedFilter.length !== numTotalLocationIds) {
          numFiltersApplied += 1;
        }
      } else if (key === chatStatusField) {
        if (appliedFilter.length !== defaultChatStatusFilterLength) {
          numFiltersApplied += 1;
        }
      } else if (key === appointmentStatusesField) {
        if (
          appliedFilter.length !== defaultChatAppointmentStatusesFilterLength
        ) {
          numFiltersApplied += 1;
        }
      } else if (key === disengagementReasonField) {
        if (appliedFilter.length !== defaultDisengagementReasonsFilterLength) {
          numFiltersApplied += 1;
        }
      } else if (key === interceptionReasonField) {
        if (appliedFilter.length !== defaultInterceptionReasonsFilterLength) {
          numFiltersApplied += 1;
        }
      } else if (key === chatMediumField) {
        if (appliedFilter.length !== defaultChatMediaFilterLength) {
          numFiltersApplied += 1;
        }
      } else if (key === sequenceIdsField) {
        // TODO properly show filter for sequence IDs field
        if (appliedFilter.length !== allSequenceIdOptions) {
          numFiltersApplied += 1;
        }
      }
    } else if (key === numUserMessagesField || key === callIdsField) {
      numFiltersApplied += 1;
    }
  });

  return numFiltersApplied;
};

const getFilterString = (locationIds, filters, inMonitoringMode = false) => {
  const numLocationIds = locationIds?.length;

  if (!numLocationIds && !inMonitoringMode) {
    return '';
  }

  return getTypesenseFilterString(locationIds, filters);
};

const mediumFilterValue = [
  {
    key: 'medium',
    operator: 'equals',
    value: '[production, sms_production]',
  },
];
const presetFilters = {
  [recentActiveKey]: {
    medium: mediumFilterValue,
    disengagementReason: [
      {
        key: 'disengagementReason',
        operator: 'equals',
        value:
          '[wrong_number, moved_away, agent_incompetence, discontent, requests_human, benign_disengagement, -1]',
      },
    ],
    numUserMessages: [
      {
        key: 'numUserMessages',
        operator: 'gt',
        value: 0,
      },
    ],
  },
  [bookedFilterKey]: {
    medium: mediumFilterValue,
    appointmentStatuses: [
      {
        key: 'appointmentStatuses',
        operator: 'equals',
        value: '[appointment_booked]',
      },
    ],
  },
  [starredKey]: {
    starredByUserId: [
      {
        key: 'starredByUserId',
        operator: 'not_equal',
        value: '-1',
      },
    ],
  },
  [needsAttentionKey]: {
    resolutionStatus: [
      {
        key: 'resolutionStatus',
        operator: 'equals',
        value:
          '[conversation_human_agent_required, callback_requested_chat_event]',
      },
    ],
    userType: [
      {
        key: 'userType',
        operator: 'equals',
        value: '[client, lead]',
      },
    ],
    medium: [
      {
        key: 'medium',
        operator: 'equals',
        value: '[production, sms_production]',
      },
    ],
    numUserMessages: [
      {
        key: 'numUserMessages',
        operator: 'gt',
        value: 0,
      },
    ],
  },
};

const ChatQueryManager = ({
  selectedChatId,
  onSelectChat,
  modifiedByParent,
  onHandleParentModification,
}) => {
  const {
    inMonitoringMode,
    onAgency,
    user,
    cookies,
    setCookie,
    drawerOpen,
    drawerExpanded,
  } = useContext(BaseContext);

  const location = getUserLocation(user);
  const locationId = location?.id;
  const agency = getUserAgency(user);
  const agencyId = agency?.id;
  const role = user?.role;
  const isSuperAdmin = role === superAdminRole;

  const { enterprises } = useAllEnterprises({
    agencyId: isSuperAdmin && agencyId ? agencyId : null,
  });
  let allLocationIds = [];
  let filteredLocationId;
  const locationMap = {};
  if (inMonitoringMode || onAgency) {
    enterprises?.map((e) => {
      if (!e.isPaused) {
        allLocationIds.push(e.id);
        const name = e.isSingleLocation
          ? e.location
          : `${e.corporation} - ${e.location}`;
        locationMap[e.id] = name;
      }
    });
  } else {
    allLocationIds = [locationId];
    locationMap[locationId] = location.name;
    filteredLocationId = locationId;
  }
  const numTotalLocationIds = allLocationIds?.length;

  const typesenseApiKey = getUserTypesenseApiKey(user);
  const typesenseClient = getTypesenseClient(typesenseApiKey);

  const chatMediaToDisplay = isSuperAdmin ? chatMedia : productionChatMedia;
  const defaultChatMediaFilterLength = chatMediaToDisplay.length;

  const { campaigns } = useMyCampaigns({
    skipCondition: inMonitoringMode && isSuperAdmin,
    locationIds: onAgency && !isSuperAdmin ? allLocationIds : null,
    agencyId: inMonitoringMode || onAgency ? agencyId : null,
    viewingAgency: false,
  });

  const { data: sequences, loading: sequencesLoading } =
    useMyChatAgentVersionSequences({ skipCondition: inMonitoringMode });
  const allAgentVersionNames = Object.keys(sequences);
  let allSequenceIds = [];
  allAgentVersionNames.map((agentVersionName) => {
    const versionSequences = sequences[agentVersionName];
    allSequenceIds = [
      ...allSequenceIds,
      ...versionSequences.map((sequence) => sequence.id),
    ];
  });
  const allAgentVersionOptions = [emptyArrayValue, ...allSequenceIds];

  const { subscribeToMore, ...result } = useQuery(GET_MY_CHATS_UPDATED_AT, {
    fetchPolicy: 'network-only',
  });

  const storedChatSearchParams = cookies[chatsSearchParamsCookieKey];
  const chatSearchParamsFromCookie = storedChatSearchParams
    ? storedChatSearchParams
    : baseChatSearchParams;

  const [chats, setChats] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(
    result?.data?.getMyChatsUpdatedAt?.timestamp,
  );
  const [pageNumber, setPageNumber] = useState(
    chatSearchParamsFromCookie.pageNumber,
  );
  const [totalNumQueriedChats, setTotalNumQueriedChats] = useState(0);
  const [stagingSearchFilter, setStagingSearchFilter] = useState(
    chatSearchParamsFromCookie.searchFilter,
  );
  const [searchFilter, setSearchFilter] = useState(
    chatSearchParamsFromCookie.searchFilter,
  );
  const [filters, setFilters] = useState(chatSearchParamsFromCookie.filters);
  const [numFiltersApplied, setNumFiltersApplied] = useState();
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [sortParam, setSortParam] = useState(
    chatSearchParamsFromCookie.sortParam,
  );
  const [chatsLoading, setChatsLoading] = useState(false);
  const [shouldFetchMoreChats, setShouldFetchMoreChats] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [modifiedByChild, setModifiedByChild] = useState(false);

  // Have to use this for the useEffect, as using the filters object raw causes unnecessary queries
  let preset;
  const filterRes = getFilterString(
    inMonitoringMode ? [] : allLocationIds,
    filters,
    inMonitoringMode,
  );
  const filterString = filterRes.filterString;
  preset = filterRes.preset;

  const updateSearchFilter = (stagingSearchFilterValue) => {
    setStagingSearchFilter(stagingSearchFilterValue);
  };

  const onUpdateQuery = (field, newValue) => {
    let params = { ...chatSearchParamsFromCookie };
    params[field] = newValue;
    setCookie(chatsSearchParamsCookieKey, params, cookieExpiryObject);
  };

  const getChatsSearchParameters = () => {
    let searchParameters = {
      q: '*',
      per_page: basePageSize,
      page: pageNumber,
      filter_by: filterString,
      sort_by: sortParam,
    };

    if (searchFilter) {
      searchParameters = {
        ...searchParameters,
        q: searchFilter,
        query_by: 'userFirstName,userLastName',
      };
    }

    return searchParameters;
  };

  const onApplyFilters = (newFilters) => {
    updateSearchFiltersCookie(
      { ...chatSearchParamsFromCookie },
      newFilters,
      chatsSearchParamsCookieKey,
      setCookie,
    );

    setSnackbarMessage('Filters applied');
    setFilterModalOpen(false);
  };

  const onApplyPreset = (updatedPreset) => {
    const updatedFilter =
      updatedPreset === allKey ? {} : presetFilters[updatedPreset];
    if (inMonitoringMode && filters?.[chatLocationField]) {
      updatedFilter[chatLocationField] = filters?.[chatLocationField];
    }
    onApplyFilters(updatedFilter);
  };

  const onResetSearchFilter = () => {
    setStagingSearchFilter('');
  };

  const onResetFilters = () => {
    onApplyFilters(presetFilters[recentActiveKey]);
    setSnackbarMessage('Filters reset');
  };

  const onResetAllFilters = () => {
    onResetSearchFilter();
    onResetFilters();
  };

  const cookieFilters = chatSearchParamsFromCookie?.filters;
  const cookieSortParam = chatSearchParamsFromCookie?.sortParam;
  const cookieSearchFilter = chatSearchParamsFromCookie?.searchFilter;
  const updatedFilterRes = getFilterString(
    inMonitoringMode ? [] : allLocationIds,
    cookieFilters,
    inMonitoringMode,
  );
  const updatedFilterString = updatedFilterRes.filterString;
  preset = updatedFilterRes.preset;
  const filterChanged = updatedFilterString !== filterString;
  const sortChanged = cookieSortParam !== sortParam;
  const searchFilterChanged = cookieSearchFilter !== searchFilter;

  useEffect(() => {
    if (filterChanged || sortChanged || searchFilterChanged) {
      if (filterChanged) {
        setFilters(cookieFilters);
      }

      if (sortChanged) {
        setSortParam(cookieSortParam);
      }

      if (searchFilterChanged) {
        setStagingSearchFilter(cookieSearchFilter);
        setSearchFilter(cookieSearchFilter);
      }

      setPageNumber(1);
    }
  }, [filterChanged, sortChanged, searchFilterChanged]);

  useEffect(() => {
    if (!lastUpdatedAt) {
      const updatedTimestamp = result?.data?.getMyChatsUpdatedAt?.timestamp;
      setLastUpdatedAt(updatedTimestamp);
    }
  }, [result]);

  useEffect(() => {
    subscribeToMore({
      document: GET_MY_CHATS_UPDATED_AT,
      updateQuery: (prev, { subscriptionData }) => {
        const updatedTimestamp =
          subscriptionData?.data?.myChatUpdates?.timestamp;

        return {
          getMyChatsUpdatedAt: {
            timestamp: updatedTimestamp,
          },
        };
      },
    });
  }, [lastUpdatedAt]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        onUpdateQuery('searchFilter', stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  const totalNumPages = Math.ceil(totalNumQueriedChats / basePageSize);

  const fetchChats = async ({ append = false }) => {
    const searchParameters = getChatsSearchParameters();
    const data = await typesenseClient
      .collections(typesenseChatsSchemaName)
      .documents()
      .search(searchParameters);
    const documents = data.hits.map((hit) => hit.document) || [];

    if (append) {
      const updatedChats = [...chats, ...documents];
      if (updatedChats.length <= totalNumQueriedChats) {
        setChats(updatedChats);
      }
    } else {
      const found = data.found;
      setTotalNumQueriedChats(found);
      setChats(documents);
    }

    setChatsLoading(false);
    setShouldFetchMoreChats(false);

    return documents;
  };

  const onScrollToBottom = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;

    // I think the problem that I made a bunch of wonky changes to fix may have been caused by not having the && clause here:
    if (bottom && !shouldFetchMoreChats) {
      if (
        chats.length < totalNumQueriedChats &&
        pageNumber < totalNumPages &&
        !chatsLoading
      ) {
        setPageNumber(pageNumber + 1);
      }
    }
  };

  const onManuallyFetchMoreChats = () => {
    if (pageNumber <= totalNumPages) {
      setShouldFetchMoreChats(true);
    }
  };

  const onChildModification = () => {
    setModifiedByChild(true);
  };

  useEffect(() => {
    async function updateChats() {
      setChatsLoading(pageNumber);

      const updatedNumFiltersApplied = getNumFiltersApplied(
        filters,
        allAgentVersionOptions,
        defaultChatMediaFilterLength,
        inMonitoringMode,
        numTotalLocationIds,
      );
      setNumFiltersApplied(updatedNumFiltersApplied);

      const documents = await fetchChats({});

      if (selectedChatId === -1 && documents?.length) {
        const firstChat = documents[0];
        if (firstChat) {
          onSelectChat(firstChat.id);
        }
      }
    }

    if (
      !totalNumPages ||
      pageNumber <= totalNumPages ||
      modifiedByParent ||
      modifiedByChild
    ) {
      updateChats();
      if (modifiedByParent) {
        onHandleParentModification();
      }
      if (modifiedByChild) {
        setModifiedByChild(false);
      }
    }
  }, [
    lastUpdatedAt,
    filterString,
    searchFilter,
    sortParam,
    modifiedByParent,
    modifiedByChild,
  ]);

  useEffect(() => {
    async function fetchNextPageOfChats() {
      setChatsLoading(pageNumber);

      await fetchChats({ append: true });
    }

    if (
      pageNumber <= totalNumPages &&
      (shouldFetchMoreChats || (pageNumber > 1 && !chatsLoading))
    ) {
      fetchNextPageOfChats();
    }
  }, [pageNumber, shouldFetchMoreChats]);

  const isLoading = result?.loading;

  if (isLoading) {
    return (
      <LoadingChatDrawer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <LoadingIndicator fullScreen />
      </LoadingChatDrawer>
    );
  }

  return (
    <>
      <ChatRenderer
        chats={chats}
        preset={preset}
        onApplyPreset={onApplyPreset}
        onModification={onChildModification}
        totalNumQueriedChats={totalNumQueriedChats}
        chatsLoading={chatsLoading}
        lastUpdatedAt={lastUpdatedAt}
        subscribeToChatUpdates={() => {
          subscribeToMore({
            document: MY_CHATS_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              const updatedTimestamp =
                subscriptionData?.data?.myChatUpdates?.timestamp;
              setLastUpdatedAt(updatedTimestamp);
              return {
                getMyChatsUpdatedAt: {
                  timestamp: updatedTimestamp,
                },
              };
            },
          });
        }}
        selectedChatId={selectedChatId}
        onSelectChat={onSelectChat}
        onChangeSearchFilter={updateSearchFilter}
        onResetSearchFilter={onResetSearchFilter}
        searchFilterValue={stagingSearchFilter}
        sortParam={sortParam}
        onChangeSortParam={(value) => onUpdateQuery('sortParam', value)}
        onOpenFiltersModal={() => setFilterModalOpen(true)}
        numFiltersApplied={numFiltersApplied}
        onResetFilters={onResetAllFilters}
        onScrollToBottom={onScrollToBottom}
        onManuallyFetchMoreChats={onManuallyFetchMoreChats}
        numDisplayed={chats.length}
        totalNum={totalNumQueriedChats}
        pageNumber={pageNumber}
        totalNumPages={totalNumPages}
      />
      <FilterChatsModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        onApplyFilters={onApplyFilters}
        onResetFilters={onResetFilters}
        filters={filters}
        allLocationIds={allLocationIds}
        locationMap={locationMap}
        allCampaigns={inMonitoringMode && isSuperAdmin ? null : campaigns}
        sequences={sequences}
      />
      <Snackbar
        quick
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default ChatQueryManager;
