import { useMutation } from '@apollo/client';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useCatalogDiagnostics } from '../../../../api/hooks/enterprise';
import { TEST_CATALOG_BOOKABILITY } from '../../../../api/mutations/enterprise';
import {
  AddIcon,
  AutoScrollWrapper,
  CenteredDiv,
  CenteredDivWithExtraSmallGap,
  ClearInputIcon,
  ColumnCenteredDiv,
  CompatibilityIcon,
  CopyIdIcon,
  EmptyGapFlexDiv,
  ExpandViewIcon,
  ExtraSmallSecondaryButton,
  FullSizeEmptyGapColumnCenteredDiv,
  LargeNoDataIcon,
  LightPrimaryEssText,
  MediumDarkEssText,
  MenuItem,
  ModularWidthInput,
  ResetIcon,
  SmallCheckbox,
  SmallFilterIcon,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  SMCheckbox,
  StartAlignedFlexDiv,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  allKey,
  allServiceCategories,
  collapsedCategoryIdsCookieKey,
  completeStatusKey,
  contactTypesForServiceLabelMap,
  discontinuedSessionTypeStatus,
  dropInKey,
  ghlAppKey,
  incompleteStatusKey,
  packageKey,
  packagesLabel,
  requiresConsultationBookableSessionTypeStatus,
  sessionTypeBookableStatusMap,
  sessionTypeTypesMap,
  stagingFilterConversionDelay,
  textSeparatorChar,
  unbookableSessionTypeStatus,
  uncategorizedCategoryLabel,
  uncategorizedKey,
  unclassifiedKey,
  zenotiKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject, getTimestamp } from '../../../../utils/date';
import { formatNumber, safeParseInt } from '../../../../utils/numbers';
import {
  copyTextToClipboard,
  formatKeyToLabel,
  parseCookieValueString,
} from '../../../../utils/string';
import { checkIfUserIsSuperAdmin } from '../../../../utils/user';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../LoadingIndicator';
import { DownArrow, UpArrow } from '../../../Micro/ArrowIcon/styled';
import CatalogDiagnosticsModal from '../../../Modals/CatalogDiagnosticsModal';
import EditServiceModal from '../../../Modals/EditServiceModal/EditServiceModal';
import EditServicesInBulkModal from '../../../Modals/EditServicesInBulkModal/EditServicesInBulkModal';
import FilterServicesModal from '../../../Modals/FilterServicesModal/FilterServicesModal';
import PopperMenu from '../../../PopperMenu';
import {
  getBookableIcon,
  ListInputSection,
  OptionActionsContainer,
  OptionIconContainer,
  OptionItemContainer,
  OptionParagraphText,
  OptionsSearchBoxContainer,
  OptionSubitemText,
  OptionSubitemTextContainer,
  PromptContainer,
  PromptsSection,
} from '../../shared-training-components';
import {
  BookableDiagnosticIcon,
  UnbookableDiagnosticIcon,
  UnclassifiedDiagnosticIcon,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const BookableDiagnosticsIconComponent = <BookableDiagnosticIcon large />;
const UnbookableDiagnosticsIconComponent = <UnbookableDiagnosticIcon large />;
const UnclassifiedDiagnosticsIconComponent = (
  <UnclassifiedDiagnosticIcon large />
);

const defaultFilter = {
  type: allKey,
  bookableStatus: completeStatusKey,
  diagnosticStatus: allKey,
};
const defaultFilterString = `${defaultFilter.type}${defaultFilter.bookableStatus}${defaultFilter.diagnosticStatus}`;

const Services = ({
  loading,
  sessionTypeCatalog,
  onCreate,
  onUpdate,
  onSave,
  onSaveBulk,
  displayChangesSavedSnackbar,
  onUpdateCategoryAssignment,
  agentName,
  calendarPlatform,
  remainingHeight,
  snackbarSetter,
}) => {
  const { user, cookies, setCookie } = useContext(BaseContext);

  const isSuperAdmin = checkIfUserIsSuperAdmin(user);

  const displayDiagnostics = calendarPlatform === zenotiKey;
  const { data: diagnosticsMap, loading: catalogLoading } =
    useCatalogDiagnostics({
      skipCondition: !displayDiagnostics,
    });

  const collapsedCategoryIdsCookieValue =
    cookies[collapsedCategoryIdsCookieKey] || [];

  const [availableSessionTypes, setAvailableSessionTypes] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [stagingSearchFilter, setStagingSearchFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [collapsedCategoryIds, setCollapsedCategoryIds] = useState(
    parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
  );
  const [editingCategory, setEditingCategory] = useState();
  const [
    editCategoryAssignmentMenuAnchorEl,
    setEditCategoryAssignmentMenuAnchorEl,
  ] = useState();
  const [selectedServices, setSelectedServices] = useState({});
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editingService, setEditingService] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState();
  const [consultationMenuAnchorEl, setConsultationMenuAnchorEl] = useState();
  const [diagnosticsModalIsOpen, setDiagnosticsModalIsOpen] = useState(false);

  const type = filter?.type;
  const bookableStatus = filter?.bookableStatus;
  const diagnosticStatusFilter = filter?.diagnosticStatus;
  const filterString = `${filter?.type}${filter?.bookableStatus}${filter?.diagnosticStatus}`;
  const filterApplied = filterString !== defaultFilterString;

  const [testCatalogBookabilityMutation] = useMutation(
    TEST_CATALOG_BOOKABILITY,
    {
      onCompleted: async (data) => {
        setDiagnosticsModalIsOpen(false);
      },
    },
  );

  const editCategoryAssignmentMenuOpen = Boolean(
    editCategoryAssignmentMenuAnchorEl,
  );
  const editMenuOpen = Boolean(editMenuAnchorEl);
  const consultationMenuOpen = Boolean(consultationMenuAnchorEl);
  const categoriesMap = sessionTypeCatalog?.categories || {};
  const allCategories = Object.values(categoriesMap);
  const uncategorizedMap = sessionTypeCatalog?.[uncategorizedKey];

  const sortedAvailableSessionTypes = sortBy(availableSessionTypes, (s) =>
    s.categoryName === packagesLabel
      ? 'zzzz'
      : s.categoryName === unclassifiedKey || !s.categoryName
      ? 'zzz'
      : s.categoryName,
  );

  const consultationsMap = allCategories.reduce((acc, c) => {
    const consultationsForCategory = c?.consultations || [];

    // Add each consultation to the map using its ID as the key
    consultationsForCategory.forEach((consultation) => {
      acc[consultation.id] = consultation;
    });

    return acc;
  }, {});
  const allConsultations = sortBy(
    Object.values(consultationsMap),
    (c) => c.displayName,
  );

  const calendars = sessionTypeCatalog?.calendars || [];

  useEffect(() => {
    if (sessionTypeCatalog) {
      let allSessionTypes = [];
      allCategories.map((c) => {
        const allCategoryServices = [
          ...(c?.dropIns || []),
          ...(c?.consultations || []),
          ...(c?.addOns || []),
        ];
        allSessionTypes.push({
          categoryId: c.id,
          categoryName: c.name,
          services: allCategoryServices,
        });
      });
      allSessionTypes.push({
        categoryId: uncategorizedKey,
        categoryName: uncategorizedCategoryLabel,
        services: [
          ...(uncategorizedMap?.dropIns || []),
          ...(uncategorizedMap?.consultations || []),
          ...(uncategorizedMap?.addOns || []),
        ],
      });
      setAvailableSessionTypes(allSessionTypes);
    }
  }, [sessionTypeCatalog]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingSearchFilter !== searchFilter) {
        setSearchFilter(stagingSearchFilter);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingSearchFilter]);

  useEffect(() => {
    setCollapsedCategoryIds(
      parseCookieValueString(collapsedCategoryIdsCookieValue) || [],
    );
  }, [collapsedCategoryIdsCookieValue]);

  const isLoading = loading || catalogLoading;
  if (isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  const onChangeCollapsedCategories = (id, shouldAdd) => {
    let updatedCollapsedCategories;
    if (shouldAdd) {
      updatedCollapsedCategories = [...collapsedCategoryIds, id];
    } else {
      updatedCollapsedCategories = collapsedCategoryIds.filter((i) => i !== id);
    }

    const updatedCollapsedCategoriesString = parseCookieValueString(
      updatedCollapsedCategories,
    );

    setCookie(
      collapsedCategoryIdsCookieKey,
      updatedCollapsedCategoriesString,
      cookieExpiryObject,
    );
  };

  const updateCategoryAssignment = (editingCategory, assignedCategory) => {
    const categoryId = editingCategory?.id;
    onUpdateCategoryAssignment(categoryId, assignedCategory);
  };

  const onUpdateService = (
    originalService,
    updatedService,
    showSnackbarMessage = false,
  ) => {
    onUpdate(originalService, updatedService, showSnackbarMessage);
  };

  const onSaveBulkEdits = async (success) => {
    if (success) {
      setSelectedServices({});
    }
    await onSaveBulk(success);
  };

  const onTestCatalogBookability = () => {
    testCatalogBookabilityMutation();
  };

  const lowercaseSearchFilter = searchFilter?.toLowerCase();
  let hasMatchingServices = false;

  const allDisplayedServices = [];
  const categorySelectedMap = {};
  const categoryServicesMap = {};
  const formattedCategories = sortedAvailableSessionTypes?.map(
    (sessionTypeCategory) => {
      const categoryId = sessionTypeCategory.categoryId;
      let wholeCategorySelected = true;
      const categoryServiceIds = [];
      const services = sessionTypeCategory.services.filter((s) => {
        const filtered =
          (!searchFilter ||
            s.displayName?.toLowerCase().includes(lowercaseSearchFilter)) &&
          (type === allKey || s.type === type) &&
          (bookableStatus === allKey ||
            (bookableStatus === completeStatusKey &&
              ![
                unbookableSessionTypeStatus,
                discontinuedSessionTypeStatus,
              ].includes(s?.bookableStatus)) ||
            s?.bookableStatus === bookableStatus) &&
          (!displayDiagnostics ||
            diagnosticStatusFilter === allKey ||
            (diagnosticsMap[s.id] &&
              !diagnosticsMap[s.id]?.success &&
              diagnosticStatusFilter === incompleteStatusKey) ||
            (diagnosticsMap[s.id] &&
              diagnosticsMap[s.id]?.success &&
              diagnosticStatusFilter === completeStatusKey) ||
            (!diagnosticsMap[s.id] &&
              diagnosticStatusFilter === unclassifiedKey));

        if (filtered) {
          const id = s.id;
          categoryServiceIds.push(id);
          if (wholeCategorySelected && !selectedServices[id]) {
            wholeCategorySelected = false;
          }
        }

        return filtered;
      });
      categorySelectedMap[categoryId] =
        wholeCategorySelected && !!services.length;
      categoryServicesMap[categoryId] = categoryServiceIds;

      allDisplayedServices.push(...services);
      return { ...sessionTypeCategory, services };
    },
  );
  const numDisplayed = allDisplayedServices.length;
  const numSelected = Object.keys(selectedServices).length;
  const allServicesSelected = numSelected === numDisplayed;

  return (
    <>
      <PromptsSection hideOverflow>
        <PromptContainer overflow>
          <FullSizeEmptyGapColumnCenteredDiv topMargin={-12}>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <MediumDarkEssText>
                {numDisplayed} services shown
                {numSelected ? ` (${numSelected} selected)` : ''}
              </MediumDarkEssText>
            )}
            <OptionsSearchBoxContainer fixedHeight={50}>
              <ModularWidthInput
                value={stagingSearchFilter}
                onChange={(e) => setStagingSearchFilter(e.target.value)}
                placeholder='Search'
                InputProps={{
                  endAdornment: (
                    <ClearInputIcon
                      onClick={() => setStagingSearchFilter('')}
                    />
                  ),
                }}
                extraSmallHeight
                extraSmallWidth
                widthPercentage={45}
              />
              <CenteredDivWithExtraSmallGap>
                <Tooltip
                  title={<TooltipTitleText>Filter services</TooltipTitleText>}
                  placement='bottom'
                >
                  <span>
                    <ExtraSmallSecondaryButton
                      onClick={() => setFilterModalOpen(true)}
                    >
                      {filterApplied && (
                        <ResetIcon
                          error
                          absolute
                          small
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilter(defaultFilter);
                            snackbarSetter(`Filters reset`);
                          }}
                        />
                      )}
                      <SmallFilterIcon />
                    </ExtraSmallSecondaryButton>
                  </span>
                </Tooltip>
                <EmptyGapFlexDiv>
                  {calendarPlatform === zenotiKey && (
                    <Tooltip
                      title={
                        <TooltipTitleText>
                          Check service bookability against EMR
                        </TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <CompatibilityIcon
                        onClick={() => setDiagnosticsModalIsOpen(true)}
                      />
                    </Tooltip>
                  )}
                  {(!calendarPlatform || calendarPlatform === ghlAppKey) && (
                    <Tooltip
                      title={<TooltipTitleText>Add new</TooltipTitleText>}
                      placement='bottom'
                    >
                      <AddIcon
                        lightPrimary
                        pointer
                        onClick={() => setCreateModalOpen(true)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        {allServicesSelected ? `Unselect all` : 'Select all'}
                      </TooltipTitleText>
                    }
                    placement='bottom'
                  >
                    <SMCheckbox
                      lightPrimary
                      pointer
                      checked={allServicesSelected}
                      onClick={() => {
                        if (allServicesSelected) {
                          setSelectedServices({});
                        } else {
                          const updatedSelectedServices = {};
                          allDisplayedServices.map((s) => {
                            const id = s.id;
                            updatedSelectedServices[id] = s.displayName;
                          });
                          setSelectedServices(updatedSelectedServices);
                        }
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        {!numSelected
                          ? `Select services to perform a bulk edit`
                          : `Bulk edit selected services`}
                      </TooltipTitleText>
                    }
                    placement='bottom'
                  >
                    <span
                      onClick={() => {
                        if (numSelected) {
                          setBulkEditModalOpen(true);
                        }
                      }}
                    >
                      <ExpandViewIcon
                        disabled={!numSelected}
                        primary
                      />
                    </span>
                  </Tooltip>
                </EmptyGapFlexDiv>
              </CenteredDivWithExtraSmallGap>
            </OptionsSearchBoxContainer>
          </FullSizeEmptyGapColumnCenteredDiv>
          <ListInputSection height={remainingHeight}>
            <AutoScrollWrapper disableMaxHeight>
              {formattedCategories.map((sessionTypeCategory, idx) => {
                const { categoryId, categoryName, services } =
                  sessionTypeCategory;
                const categoryLabel = `${
                  categoryName || uncategorizedCategoryLabel
                }`;

                const allCategoryServicesSelected =
                  categorySelectedMap[categoryId];
                const collapsed = collapsedCategoryIds.includes(categoryId);

                const numServices = services.length;
                if (!hasMatchingServices && numServices) {
                  hasMatchingServices = true;
                }

                return numServices ? (
                  <>
                    <OptionItemContainer
                      top
                      widthPercentage={95}
                      bottomMargin={-10}
                    >
                      <StartAlignedFlexDiv>
                        <MediumDarkEssText>
                          {categoryLabel} (
                          {`${numServices}${
                            searchFilter ? ` filtered services` : ``
                          }`}
                          )
                        </MediumDarkEssText>
                        <CenteredDiv>
                          {collapsed ? (
                            <DownArrow
                              color='darkGray'
                              pointer
                              onClick={() => {
                                onChangeCollapsedCategories(categoryId, false);
                              }}
                            />
                          ) : (
                            <UpArrow
                              color='darkGray'
                              pointer
                              onClick={() => {
                                onChangeCollapsedCategories(categoryId, true);
                              }}
                            />
                          )}
                          <Tooltip
                            title={
                              <TooltipTitleText>
                                {allCategoryServicesSelected
                                  ? `Unselect all`
                                  : 'Select all'}{' '}
                                services for category
                              </TooltipTitleText>
                            }
                            placement='bottom'
                          >
                            <SmallCheckbox
                              darkGray
                              removePadding
                              checked={allCategoryServicesSelected}
                              onClick={() => {
                                const serviceIdsForCategory =
                                  categoryServicesMap[categoryId];
                                const updatedSelectedServices = {
                                  ...selectedServices,
                                };
                                serviceIdsForCategory.map((id) => {
                                  if (allCategoryServicesSelected) {
                                    delete updatedSelectedServices[id];
                                  } else {
                                    updatedSelectedServices[id] = true;
                                  }
                                });
                                setSelectedServices(updatedSelectedServices);
                              }}
                            />
                          </Tooltip>
                        </CenteredDiv>
                      </StartAlignedFlexDiv>
                    </OptionItemContainer>
                    {!collapsed && (
                      <>
                        {sortBy(services, (s) => s.displayName).map(
                          (s, idx) => {
                            const {
                              id,
                              category,
                              displayName,
                              displayDurationMinutes,
                              durationMinutes,
                              numberOfPeopleToBook,
                              followUpDays,
                              clientDepositAmount,
                              bookableStatus,
                              type,
                              consultationSessionTypeId,
                              priceSetting,
                            } = s;

                            const isPackage = categoryName === packagesLabel;
                            const formattedType = isPackage ? packageKey : type;

                            const formattedService = {
                              ...s,
                              type: formattedType,
                            };

                            const requiredConsultationName =
                              consultationsMap?.[consultationSessionTypeId]
                                ?.displayName;

                            const hasCustomDepositSettings =
                              clientDepositAmount != null;

                            const clientType = s.clientType;
                            const clientTypeLabel =
                              clientType === allKey
                                ? ''
                                : contactTypesForServiceLabelMap[clientType];

                            const numEligibleDays = s.eligibleDays?.length || 7;

                            const diagnostics =
                              !displayDiagnostics || isPackage
                                ? null
                                : !Object.keys(diagnosticsMap)?.length
                                ? unclassifiedKey
                                : diagnosticsMap[s.id] || incompleteStatusKey;
                            let diagnosticsText;
                            let diagnosticsStatus;
                            if (diagnostics) {
                              if (diagnostics === unclassifiedKey) {
                                diagnosticsText = `No catalog diagnostics have been run yet for your services`;
                                diagnosticsStatus = unclassifiedKey;
                              } else if (diagnostics === incompleteStatusKey) {
                                diagnosticsText = `No catalog diagnostics have been run yet for this service`;
                                diagnosticsStatus = incompleteStatusKey;
                              } else {
                                const timestamp = getTimestamp(
                                  diagnostics.date,
                                  false,
                                  false,
                                  true,
                                );
                                diagnosticsText = diagnostics.success ? (
                                  <>
                                    This service is bookable in Zenoti
                                    <br></br>
                                    (Last checked {timestamp})
                                  </>
                                ) : (
                                  <>
                                    This service is not bookable by your AI
                                    agent, please edit your settings in Zenoti
                                    to allow this to be booked
                                    <br></br>
                                    (Last checked {timestamp})
                                  </>
                                );
                                diagnosticsStatus = diagnostics.success
                                  ? completeStatusKey
                                  : incompleteStatusKey;
                              }
                            }

                            const shouldReturn =
                              !diagnostics ||
                              diagnosticStatusFilter === allKey ||
                              diagnosticStatusFilter === diagnosticsStatus;

                            const selected = !!selectedServices[id];

                            return (
                              shouldReturn && (
                                <OptionItemContainer
                                  bottom={idx === numServices - 1}
                                  widthPercentage={95}
                                >
                                  <OptionSubitemTextContainer>
                                    <OptionSubitemText start>
                                      {displayName}
                                      {formattedType !== dropInKey &&
                                        ` (${sessionTypeTypesMap[formattedType]})`}
                                      {isSuperAdmin && (
                                        <Tooltip
                                          title={
                                            <TooltipTitleText>
                                              Copy ID {id?.slice(0, 4)}
                                            </TooltipTitleText>
                                          }
                                          placement='bottom'
                                        >
                                          <CopyIdIcon
                                            addLargeLeftMargin
                                            addTopMargin
                                            onClick={() =>
                                              copyTextToClipboard(id)
                                            }
                                          />
                                        </Tooltip>
                                      )}
                                    </OptionSubitemText>
                                    <OptionParagraphText>
                                      {s.price === -1
                                        ? `Variable price`
                                        : s.price != null
                                        ? `$${formatNumber(s.price)}`
                                        : '(Price unknown)'}{' '}
                                      {s.units && `per ${s.units} `}
                                      {priceSetting === 'fixed_price'
                                        ? ''
                                        : '(Variable price) '}
                                      {hasCustomDepositSettings &&
                                        `${textSeparatorChar} Custom deposit `}
                                      {categoryName !==
                                        sessionTypeTypesMap[packageKey] && (
                                        <>
                                          {textSeparatorChar}{' '}
                                          {displayDurationMinutes != null &&
                                          durationMinutes != null
                                            ? displayDurationMinutes !==
                                              durationMinutes
                                              ? `${displayDurationMinutes} minutes (${durationMinutes} for staff)`
                                              : safeParseInt(
                                                  displayDurationMinutes,
                                                ) === 0
                                              ? `No time added on`
                                              : `${displayDurationMinutes} minutes`
                                            : 'Time length unknown'}
                                        </>
                                      )}
                                      {clientTypeLabel
                                        ? ` ${textSeparatorChar} Only for ${clientTypeLabel} `
                                        : ''}
                                      {numberOfPeopleToBook !== 1
                                        ? ` ${textSeparatorChar} ${numberOfPeopleToBook} guests/booking`
                                        : ''}
                                      {followUpDays != null
                                        ? ` ${textSeparatorChar} ${followUpDays} day follow-up`
                                        : ''}
                                      {numEligibleDays !== 7
                                        ? ` ${textSeparatorChar} ${numEligibleDays} eligible booking day${
                                            numEligibleDays === 1 ? '' : 's'
                                          }`
                                        : ''}
                                      <br></br>
                                      {category
                                        ? `Part of the '${formatKeyToLabel(
                                            category,
                                          )}' corpus category`
                                        : `Unassigned to a corpus category`}
                                    </OptionParagraphText>
                                  </OptionSubitemTextContainer>
                                  <OptionActionsContainer>
                                    {displayDiagnostics && (
                                      <OptionIconContainer
                                        status={diagnosticsStatus}
                                      >
                                        <Tooltip
                                          title={
                                            <TooltipTitleText>
                                              {diagnosticsText}
                                            </TooltipTitleText>
                                          }
                                        >
                                          <CenteredDiv>
                                            {diagnosticsStatus ===
                                            completeStatusKey
                                              ? BookableDiagnosticsIconComponent
                                              : diagnosticsStatus ===
                                                incompleteStatusKey
                                              ? UnbookableDiagnosticsIconComponent
                                              : UnclassifiedDiagnosticsIconComponent}
                                          </CenteredDiv>
                                        </Tooltip>
                                      </OptionIconContainer>
                                    )}
                                    <OptionIconContainer
                                      status={bookableStatus || unclassifiedKey}
                                      onClick={(e) => {
                                        setEditingService(formattedService);
                                        setEditMenuAnchorEl(e.currentTarget);
                                      }}
                                    >
                                      {getBookableIcon(
                                        bookableStatus,
                                        agentName,
                                        requiredConsultationName,
                                      )}
                                    </OptionIconContainer>
                                    {formattedType !== packageKey && (
                                      <OptionIconContainer>
                                        <Tooltip
                                          title={
                                            <TooltipTitleText>
                                              Edit
                                            </TooltipTitleText>
                                          }
                                        >
                                          <CenteredDiv
                                            onClick={() => {
                                              setEditingService(s);
                                              setEditModalOpen(true);
                                            }}
                                          >
                                            <ExpandViewIcon />
                                          </CenteredDiv>
                                        </Tooltip>
                                      </OptionIconContainer>
                                    )}
                                    <Tooltip
                                      title={
                                        <TooltipTitleText>
                                          {selected ? `Uns` : 'S'}elect
                                        </TooltipTitleText>
                                      }
                                    >
                                      <SmallCheckbox
                                        removePadding
                                        checked={selected}
                                        onClick={() => {
                                          if (selected) {
                                            const updatedSelectedServices = {
                                              ...selectedServices,
                                            };
                                            delete updatedSelectedServices[id];
                                            setSelectedServices(
                                              updatedSelectedServices,
                                            );
                                          } else {
                                            setSelectedServices({
                                              ...selectedServices,
                                              [id]: displayName,
                                            });
                                          }
                                        }}
                                      />
                                    </Tooltip>
                                  </OptionActionsContainer>
                                </OptionItemContainer>
                              )
                            );
                          },
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!hasMatchingServices &&
                      idx === sortedAvailableSessionTypes?.length - 1 && (
                        <ColumnCenteredDiv topMargin={50}>
                          <LargeNoDataIcon />
                          <LightPrimaryEssText>
                            No matching results
                          </LightPrimaryEssText>
                        </ColumnCenteredDiv>
                      )}
                  </>
                );
              })}
              <PopperMenu
                open={editCategoryAssignmentMenuOpen}
                anchorElement={editCategoryAssignmentMenuAnchorEl}
                onClose={() => {
                  setEditingCategory();
                  setEditCategoryAssignmentMenuAnchorEl();
                }}
                variant='offset'
                placement='bottom'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select corpus category to assign all '
                    {editingCategory?.name}' services to
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {sortBy(allServiceCategories, (c) => c.toLowerCase()).map(
                  (c) => {
                    const label = formatKeyToLabel(c);
                    return (
                      <MenuItem
                        onClick={() => {
                          updateCategoryAssignment(editingCategory, c);
                          setEditingCategory();
                          setEditCategoryAssignmentMenuAnchorEl();
                        }}
                      >
                        <SmallMenuItemText>{label}</SmallMenuItemText>
                      </MenuItem>
                    );
                  },
                )}
              </PopperMenu>
              <PopperMenu
                open={editMenuOpen}
                anchorElement={editMenuAnchorEl}
                onClose={() => setEditMenuAnchorEl()}
                variant='offset'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select booking status
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {Object.keys(sessionTypeBookableStatusMap).map((key) => {
                  const label = sessionTypeBookableStatusMap[key];
                  const isCurrent = editingService?.bookableStatus === key;
                  return (
                    <MenuItem
                      onClick={() => {
                        if (!isCurrent) {
                          if (
                            key ===
                            requiresConsultationBookableSessionTypeStatus
                          ) {
                            setConsultationMenuAnchorEl(editMenuAnchorEl);
                            setEditMenuAnchorEl();
                          } else {
                            onUpdateService(editingService, {
                              ...editingService,
                              bookableStatus: key,
                              consultationSessionTypeId: null,
                            });
                            setEditMenuAnchorEl();
                            displayChangesSavedSnackbar();
                          }
                        }
                      }}
                      disabled={isCurrent}
                    >
                      <SmallMenuItemText>
                        {label}
                        {isCurrent && ` (Current)`}
                      </SmallMenuItemText>
                    </MenuItem>
                  );
                })}
              </PopperMenu>
              <PopperMenu
                open={consultationMenuOpen}
                anchorElement={consultationMenuAnchorEl}
                onClose={() => setConsultationMenuAnchorEl()}
                variant='offset'
              >
                <SmallMenuItemTitleContainer disableRipple>
                  <SmallMenuItemTitleText>
                    Select consultation required
                  </SmallMenuItemTitleText>
                </SmallMenuItemTitleContainer>
                {allConsultations.map((c) => {
                  const label = c.displayName;
                  return (
                    <MenuItem
                      onClick={() => {
                        onUpdateService(editingService, {
                          ...editingService,
                          bookableStatus:
                            requiresConsultationBookableSessionTypeStatus,
                          consultationSessionTypeId: c.id,
                        });
                        setConsultationMenuAnchorEl();
                        displayChangesSavedSnackbar();
                      }}
                    >
                      <SmallMenuItemText>{label}</SmallMenuItemText>
                    </MenuItem>
                  );
                })}
              </PopperMenu>
            </AutoScrollWrapper>
          </ListInputSection>
        </PromptContainer>
      </PromptsSection>
      <EditServiceModal
        isOpen={createModalOpen || editModalOpen}
        onClose={() => {
          if (createModalOpen) {
            setCreateModalOpen(false);
          } else {
            setEditingService();
            setEditModalOpen(false);
          }
        }}
        onSave={(updatedService) => {
          onUpdateService(editingService, updatedService, true);
          setEditModalOpen(false);
          setEditingService();
        }}
        onCreate={async (createdService) => {
          onCreate(createdService);
          setCreateModalOpen(false);
        }}
        onSaveUpdates={(updatedService) => {
          onSave(updatedService);
          setEditModalOpen(false);
        }}
        sessionType={editingService}
        categories={categoriesMap}
        calendars={calendars}
        consultations={allConsultations}
        isEditing={editModalOpen}
      />
      <EditServicesInBulkModal
        editingServices={selectedServices}
        isOpen={bulkEditModalOpen}
        onClose={() => setBulkEditModalOpen(false)}
        onSave={onSaveBulkEdits}
        calendars={calendars}
      />
      <CatalogDiagnosticsModal
        isOpen={diagnosticsModalIsOpen}
        onClose={() => setDiagnosticsModalIsOpen(false)}
        onTest={onTestCatalogBookability}
      />
      <FilterServicesModal
        filter={filter}
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        displayDiagnostics={displayDiagnostics}
        onSave={(newFilter) => {
          setFilter(newFilter);
          setFilterModalOpen(false);
          displayChangesSavedSnackbar();
        }}
      />
    </>
  );
};

export default Services;
