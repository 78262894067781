import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { DELAY_CAMPAIGN_FOLLOW_UP } from '../../../api/mutations/chat';
import {
  MediumPrimaryButton,
  MediumSecondaryButton,
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
} from '../../../styles/shared-styled-components';
import { dropdownInputType, emptyStringValue } from '../../../utils/constants';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';

const options = [
  {
    key: emptyStringValue,
    label: 'Indefinitely',
    sublabel: `Your agent won't follow-up again for this campaign`,
  },
  {
    key: 1,
    label: '1 day',
    sublabel: `Your agent will follow-up tomorrow`,
  },
  {
    key: 2,
    label: '2 days',
    sublabel: `Your agent will follow-up in 2 days`,
  },
  {
    key: 3,
    label: '3 days',
    sublabel: `Your agent will follow-up in 3 days`,
  },
  {
    key: 5,
    label: '5 days',
    sublabel: `Your agent will follow-up in 5 days`,
  },
  {
    key: 7,
    label: '1 week',
    sublabel: `Your agent will follow-up in 1 week`,
  },
  {
    key: 14,
    label: '2 weeks',
    sublabel: `Your agent will follow-up in 2 weeks`,
  },
  {
    key: 30,
    label: '1 month',
    sublabel: `Your agent will follow-up in 1 month`,
  },
];

const PauseFollowUpsModal = ({ isOpen, onClose, onSave, chatId }) => {
  const [pauseFollowUpsMutation, { loading }] = useMutation(
    DELAY_CAMPAIGN_FOLLOW_UP,
  );

  const [delayDays, setDelayDays] = useState();

  const handleSave = () => {
    let timeslot;
    if (delayDays === emptyStringValue) {
      timeslot = emptyStringValue;
    } else {
      const dt = new Date();
      dt.setDate(dt.getDate() + delayDays);
      timeslot = dt.toISOString();
    }

    pauseFollowUpsMutation({
      variables: {
        chatId,
        timeslot,
      },
      onCompleted: async (data) => {
        onClose();
        await onSave();
      },
    });
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <MLText>Pause follow-ups for chat</MLText>
        <Input
          label='Delay'
          value={delayDays}
          onChange={(e) => setDelayDays(e.target.value)}
          type={dropdownInputType}
          options={options}
        />

        <ModalActions>
          <MediumPrimaryButton
            disabled={!delayDays}
            onClick={() => {
              handleSave();
            }}
          >
            Pause follow-ups
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={onClose}>Close</MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default PauseFollowUpsModal;
