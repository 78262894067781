import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useAllAgencies } from '../../../api/hooks/enterprise';
import {
  EDIT_LOCATION_AGENCY,
  MODIFY_LOCATION_PAUSED_STATUS,
} from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import { SmallPromptInput } from '../../../components/Training/shared-training-components';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  ContentContainer,
  FullSizeColumnCenteredDivWithGap,
  PageContainer,
  PageTitleText,
} from '../../../styles/shared-styled-components';
import { dropdownInputType, superAdminRole } from '../../../utils/constants';
import { getUserAgency, getUserLocation } from '../../../utils/user';
import { EnableButton, PauseButton } from './styled';

const Settings = () => {
  const {
    drawerOpen,
    drawerExpanded,
    user,
    refetchUser,
    viewingAgency,
    inMonitoringMode,
  } = useContext(BaseContext);
  const isSuperAdmin = user?.role === superAdminRole;
  const canModifyAgency = isSuperAdmin && !viewingAgency && !inMonitoringMode;

  const { agencies } = useAllAgencies({ skipCondition: !canModifyAgency });
  const agencyOptions = agencies?.map((a) => {
    return { key: a.id, label: a.name };
  });

  const location = getUserLocation(user);
  const isPaused = location?.isPaused;
  const agency = getUserAgency(user);
  const [agencyId, setAgencyId] = useState(agency?.id);

  const [editLocationAgencyMutation, { loading: agencyEditLoading }] =
    useMutation(EDIT_LOCATION_AGENCY);
  const [modifyLocationPausedStatusMutation, { loading }] = useMutation(
    MODIFY_LOCATION_PAUSED_STATUS,
  );

  const [snackbarMessage, setSnackbarMessage] = useState();

  const onModifyAgency = (updatedAgencyId) => {
    setAgencyId(updatedAgencyId);

    editLocationAgencyMutation({
      variables: {
        agencyId: updatedAgencyId,
      },
      onCompleted: async () => {
        await refetchUser();
      },
    });
  };

  const onModifyPausedStatus = () => {
    modifyLocationPausedStatusMutation({
      variables: {
        isPaused: !isPaused,
      },
      onCompleted: async (data) => {
        const success = data.modifyLocationPausedStatus;

        if (success) {
          await refetchUser();
          setSnackbarMessage(`Status updated`);
        } else {
          setSnackbarMessage(`Error updating status`);
        }
      },
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Settings`}
        description={`Settings`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <PageTitleText>Settings</PageTitleText>
          <FullSizeColumnCenteredDivWithGap>
            {canModifyAgency && (
              <SmallPromptInput
                label='Agency'
                value={agencyId}
                type={dropdownInputType}
                options={agencyOptions}
                onChange={(e) => {
                  onModifyAgency(e.target.value);
                }}
              />
            )}
            {isPaused ? (
              <EnableButton onClick={() => onModifyPausedStatus()}>
                Re-launch agent
              </EnableButton>
            ) : (
              <PauseButton onClick={() => onModifyPausedStatus()}>
                Pause agent
              </PauseButton>
            )}
          </FullSizeColumnCenteredDivWithGap>
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Settings;
