import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useCampaignTemplates } from '../../../api/hooks/campaigns';
import { SYNC_CAMPAIGN_TEMPLATE } from '../../../api/mutations/campaign';
import {
  BaseCard,
  CardHeaderContainer,
  ColumnCenteredDiv,
  ColumnDivWithGap,
  DarkMLText,
  LargeTinyText,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallCheckbox,
  StartAlignedDarkEssText,
  StartAlignedFlexDiv,
} from '../../../styles/shared-styled-components';
import { textSeparatorChar } from '../../../utils/constants';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';

const CampaignTemplateModal = ({ isOpen, onClose, onSync }) => {
  const { user } = useContext(BaseContext);

  const { templates, loading } = useCampaignTemplates({});
  const [syncTemplateMutation, { loading: syncLoading }] = useMutation(
    SYNC_CAMPAIGN_TEMPLATE,
  );

  const [selectedTemplate, setSelectedTemplate] = useState();

  const handleSync = () => {
    syncTemplateMutation({
      variables: {
        campaignId: selectedTemplate?.id,
      },
      onCompleted: async (data) => {
        handleClose();
        await onSync();
      },
    });
  };

  const handleClose = () => {
    setSelectedTemplate();
    onClose();
  };

  if (loading || syncLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Select Campaign Template</DarkMLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper
              columnFlex
              largeGap
            >
              <MediumGapColumnCenteredDiv>
                <ColumnCenteredDiv>
                  <>
                    {templates?.map((template) => {
                      const { id, name, locationName } = template;
                      const selected = id === selectedTemplate?.id;
                      return (
                        <BaseCard
                          disableBoxShadow
                          maxWidth='80%'
                        >
                          <ColumnDivWithGap>
                            <CardHeaderContainer>
                              <StartAlignedDarkEssText smallLineHeight>
                                {name} {textSeparatorChar} From '{locationName}'
                                location
                              </StartAlignedDarkEssText>
                            </CardHeaderContainer>
                            <StartAlignedFlexDiv removeGap>
                              <SmallCheckbox
                                onClick={() => {
                                  setSelectedTemplate(selected ? null : { id });
                                }}
                              />
                              <LargeTinyText>
                                {selected
                                  ? 'Template selected'
                                  : 'Select template'}
                              </LargeTinyText>
                            </StartAlignedFlexDiv>
                          </ColumnDivWithGap>
                        </BaseCard>
                      );
                    })}
                  </>
                </ColumnCenteredDiv>
              </MediumGapColumnCenteredDiv>
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumPrimaryButton
              onClick={() => {
                if (selectedTemplate) {
                  handleSync();
                }
              }}
              disabled={!selectedTemplate}
            >
              Clone template
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Cancel
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CampaignTemplateModal;
