import styled, { css } from 'styled-components';
import {
  BaseDrawerContainer,
  CenterFlexStyle,
  ChatDrawerWidth,
  CollapsedDrawerWidthValue,
  ColumnFlexStyle,
  EmptyGapColumnCenteredDiv,
  ExpandedDrawerWidthValue,
  ExtraSmallGapStyle,
  ExtraTinyText,
  FilterIcon,
  SMIconSize,
  SMMIconSize,
  SmallGapStyle,
  SortIcon,
  TextInput,
  UnderlineStyle,
} from '../../../styles/shared-styled-components';
import { ClientSearchBoxHeight } from '../../Search/ClientSearchBox/styled';

export const ChatDrawer = styled(BaseDrawerContainer).attrs({
  removeScroll: true,
})`
  border-right: 2px solid ${({ theme }) => theme.color.muiInput};
  left: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + 2}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + 2}px`
      : '0px'};
  width: ${ChatDrawerWidth}px;
  padding: 0px;
`;

export const LoadingChatDrawer = styled(ChatDrawer)`
  z-index: 0;
  left: ${(props) =>
    props.drawerExpanded
      ? `${ExpandedDrawerWidthValue + 2}px`
      : props.drawerOpen
      ? `${CollapsedDrawerWidthValue + 2}px`
      : `${CollapsedDrawerWidthValue}px`};
`;

export const ChatDrawerHeaderContainer = styled(EmptyGapColumnCenteredDiv)`
  padding: 6px 8px;
  padding-right: 4px;
  border-bottom: 2px dotted ${({ theme }) => theme.color.muiInput};
`;

export const ChatQueryContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${SmallGapStyle}
`;

export const QuerySubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 5px);
  margin-left: 5px;
  margin-right: 0px;
`;

export const ResetFilterContainer = styled(QuerySubContainer)`
  margin-bottom: 7px;
  margin-top: -5px;
`;

export const ResetFilterText = styled(ExtraTinyText)`
  ${UnderlineStyle}
  ${(props) =>
    props.disabled
      ? css`
          color: ${({ theme }) => theme.color.muiInput};
          text-decoration-color: ${({ theme }) =>
            theme.color.muiInputLightGray};
        `
      : css`
          color: ${({ theme }) => theme.color.muiInputDarkGray};
          cursor: pointer;
        `}
`;

const IconSize = ClientSearchBoxHeight - 3;

export const ChatSearchBox = styled(TextInput).attrs({
  fixedHeight: ClientSearchBoxHeight,
  useExtraSmallText: true,
})``;

export const ChatFilterIconContainer = styled.div`
  position: relative;
  height: ${IconSize}px;
  width: ${IconSize}px;
  ${CenterFlexStyle}
  border-radius: 50%;
  padding: 0px 5px;
  color: ${({ theme }) => theme.color.lightPrimary};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.color.lightPrimary};
    color: white;
  }
`;

export const ChatFilterIcon = styled(FilterIcon)`
  color: unset;
  ${SMMIconSize}
`;

export const NumChatFiltersAppliedIconContainer = styled.div`
  position: absolute;
  right: 1px;
  top: 1px;
  font-size: 9px;
  font-family: ${({ theme }) => theme.typography.baseFont};
  background-color: ${({ theme }) => theme.color.brightGreen};
  color: white;
  padding: 1px 4px;
  border-radius: 50%;
`;

export const ChatResultsTextContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  margin-top: 8px;
  margin-bottom: 3px;
`;

export const ChatSortIconContainer = styled(ChatFilterIconContainer)`
  padding: 0px;
`;

export const ChatSortIcon = styled(SortIcon)`
  color: unset;
  ${SMIconSize}
`;

export const ChatTypeMenuContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 3px;
  padding-bottom: 0px;
  width: 100%;
  margin-top: -2px;
  margin-bottom: -10px;
  ${ExtraSmallGapStyle}
  /* For WebKit-based browsers (Chrome, Edge, Safari) */
  ::-webkit-scrollbar {
    width: 1px; /* Adjusts the thickness */
  }
  ::-webkit-scrollbar-track {
    background: transparent; /* Makes the track invisible */
    width: 2px;
    height: 1px;
  }
`;

export const ChatTypeCard = styled.div`
  cursor: pointer;
  ${(props) =>
    props.selected
      ? css`
          border: 1px solid ${props.theme.color.brightGreen};
          color: ${props.theme.color.brightGreen};
          &:hover {
            color: white;
            background-color: ${props.theme.color.brightGreen};
          }
        `
      : css`
          border: 1px solid ${props.theme.color.muiInput};
          color: ${props.theme.color.mediumDarkText};
          &:hover {
            color: white;
            background-color: ${props.theme.color.brightGreen};
          }
        `}
  padding: 3px 8px;
  border-radius: 8px;
  min-width: fit-content;
`;
