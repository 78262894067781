import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AdaptablePrimaryButton,
  AdaptableSecondaryButton,
  BackIcon,
  ContentContainer,
  InputsContainer,
  LightDarkSMText,
  NavigationButtonContainer,
  NavigationSection,
  NextIcon,
  PageContainer,
} from '../../../styles/shared-styled-components';
import { valueIsEmpty } from '../../../utils/data';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';
import Header from '../../NavBar/Header';
import {
  FormSubtitleText,
  FormTitleText,
  HeaderTextContainer,
  TopContainer,
} from '../form-styled-components';

const FormPage = ({
  flow = null,
  title,
  subtitle = '',
  currentStep,
  totalNumSteps,
  isLoading,
  inputs,
  continueEnabled,
  continueButtonText = 'Continue',
  backEnabled = true,
  backButtonText = 'Back',
  onContinue,
  onBack,
}) => {
  const { width, drawerOpen, drawerExpanded } = useContext(BaseContext);

  const inputsContainerRef = useRef(null);

  const [remainingHeight, setRemainingHeight] = useState(0);
  const [hasLoaded, setHasLoaded] = useState(false);

  // Setting the remaining height - need to watch variables that trigger re-renders
  useEffect(() => {
    const handleResize = () => {
      const divHeight =
        inputsContainerRef?.current?.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const heightDifference = windowHeight - divHeight;
      setRemainingHeight(heightDifference);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    setHasLoaded(true);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, subtitle, hasLoaded]);

  const includesSteps =
    !valueIsEmpty(currentStep) && !valueIsEmpty(totalNumSteps);

  return (
    <>
      <Header />
      {isLoading ? (
        <LoadingIndicator fullScreen />
      ) : (
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <TopContainer>
              <HeaderTextContainer addTopMargin={!!flow}>
                <div>
                  <FormTitleText>{title}</FormTitleText>
                  {includesSteps && (
                    <FormSubtitleText>
                      ({currentStep}/{totalNumSteps})
                    </FormSubtitleText>
                  )}
                </div>
                {subtitle && <LightDarkSMText>{subtitle}</LightDarkSMText>}
              </HeaderTextContainer>
            </TopContainer>
            <InputsContainer
              ref={inputsContainerRef}
              remainingHeight={remainingHeight}
              smallGap
              bottomPadding={40}
            >
              {/* To avoid the screen width rattling onLoad */}
              {remainingHeight > 0 && (
                <>
                  {inputs}
                  <NavigationSection expanded={drawerExpanded}>
                    <NavigationButtonContainer>
                      {backEnabled && (
                        <AdaptableSecondaryButton onClick={onBack}>
                          <BackIcon /> {backButtonText}
                        </AdaptableSecondaryButton>
                      )}
                      <AdaptablePrimaryButton
                        enabled={continueEnabled}
                        onClick={onContinue}
                      >
                        {continueButtonText} <NextIcon />
                      </AdaptablePrimaryButton>
                    </NavigationButtonContainer>
                  </NavigationSection>
                </>
              )}
            </InputsContainer>
          </ContentContainer>
        </PageContainer>
      )}
    </>
  );
};

export default FormPage;
