import { gql } from '@apollo/client';

export const CREATE_ENTERPRISE = gql`
  mutation createEnterprise($data: JSON!) {
    createEnterprise(data: $data) {
      enterprise {
        agency
        business {
          id
          name
          website
          type
          hqAddress
        }
        location {
          id
          businessId
          name
          address
          timezone
          connectedApps {
            name
            initialized
          }
          agents {
            id
          }
        }
      }
      role
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $website: String
    $address: String!
    $lat: String!
    $lng: String!
    $timezone: String!
    $postalCode: String!
  ) {
    createLocation(
      name: $name
      website: $website
      address: $address
      lat: $lat
      lng: $lng
      timezone: $timezone
      postalCode: $postalCode
    ) {
      id
      businessId
      name
      address
      connectedApps {
        name
        initialized
      }
    }
  }
`;

export const EDIT_LOCATION_ONBOARDING = gql`
  mutation editLocationOnboarding($onboardingStatus: JSON!) {
    editLocationOnboarding(onboardingStatus: $onboardingStatus) {
      success
    }
  }
`;

export const EDIT_AGENT_SESSION_TYPE_CATEGORY_LIBRARY = gql`
  mutation editAgentSessionTypeCategoryLibrary(
    $agentId: String!
    $categoryIds: [String!]!
    $shouldInclude: Boolean!
  ) {
    editAgentSessionTypeCategoryLibrary(
      agentId: $agentId
      categoryIds: $categoryIds
      shouldInclude: $shouldInclude
    ) {
      success
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!, $included: Boolean!) {
    createCategory(name: $name, included: $included) {
      success
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation editCategory(
    $id: String!
    $name: String!
    $included: Boolean!
    $shouldDisplay: Boolean!
  ) {
    editCategory(
      id: $id
      name: $name
      included: $included
      shouldDisplay: $shouldDisplay
    ) {
      success
    }
  }
`;

export const CREATE_SESSION_TYPE = gql`
  mutation createSessionType($data: JSON!) {
    createSessionType(data: $data)
  }
`;

export const CREATE_SESSION_TYPE_GROUP = gql`
  mutation createSessionTypeGroup($name: String!) {
    createSessionTypeGroup(name: $name) {
      success
    }
  }
`;

export const CREATE_STAFF = gql`
  mutation createStaff(
    $firstName: String!
    $lastName: String!
    $gender: String!
    $isBookable: Boolean!
    $eligibleSessionTypeIds: [String]
  ) {
    createStaff(
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      isBookable: $isBookable
      eligibleSessionTypeIds: $eligibleSessionTypeIds
    ) {
      success
    }
  }
`;

export const EDIT_STAFF = gql`
  mutation editStaff(
    $id: String!
    $firstName: String!
    $lastName: String!
    $gender: String!
    $isBookable: Boolean!
    $shouldMaskName: Boolean!
    $eligibleSessionTypeIds: [String]
  ) {
    editStaff(
      id: $id
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      isBookable: $isBookable
      shouldMaskName: $shouldMaskName
      eligibleSessionTypeIds: $eligibleSessionTypeIds
    ) {
      success
    }
  }
`;

export const EDIT_STAFF_BOOKING_ELIGIBILITY = gql`
  mutation editStaffBookingEligibility($staff: JSON!) {
    editStaffBookingEligibility(staff: $staff) {
      success
    }
  }
`;

export const CREATE_PROMO = gql`
  mutation createPromo($campaignId: String!) {
    createPromo(campaignId: $campaignId) {
      id
      name
      description
      startDate
      endDate
      createdAt
    }
  }
`;

export const EDIT_PROMO = gql`
  mutation editPromo(
    $id: String!
    $description: String!
    $staff: [JSON]
    $sessionTypes: [JSON]
    $discountType: String!
    $discountAmount: Float!
    $startDate: String
    $endDate: String
  ) {
    editPromo(
      id: $id
      description: $description
      staff: $staff
      sessionTypes: $sessionTypes
      discountType: $discountType
      discountAmount: $discountAmount
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      description
      discountType
      discountAmount
      startDate
      endDate
      createdAt
      updatedAt
      staff
      sessionTypes
    }
  }
`;

export const ATTACH_EXISTING_PROMO_TO_CAMPAIGN = gql`
  mutation attachExistingPromoToCampaign(
    $campaignId: String!
    $promoId: String!
  ) {
    attachExistingPromoToCampaign(campaignId: $campaignId, promoId: $promoId) {
      success
    }
  }
`;

export const DELETE_PROMO = gql`
  mutation deletePromo($id: String!) {
    deletePromo(id: $id) {
      success
    }
  }
`;

export const REPLACE_EXISTING_TEMPLATE_OR_SCRIPT_FOR_CAMPAIGN = gql`
  mutation replaceExistingTemplateOrScriptForCampaign(
    $campaignId: String!
    $templateId: String
    $scriptId: String
  ) {
    replaceExistingTemplateOrScriptForCampaign(
      campaignId: $campaignId
      templateId: $templateId
      scriptId: $scriptId
    ) {
      success
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(id: $id) {
      success
    }
  }
`;

export const EDIT_SERVICE_SETTINGS = gql`
  mutation editServiceSettings(
    $followUp: JSON!
    $deposit: JSON!
    $cancellation: JSON!
  ) {
    editServiceSettings(
      followUp: $followUp
      deposit: $deposit
      cancellation: $cancellation
    ) {
      success
    }
  }
`;

export const RESYNC_SERVICES = gql`
  mutation resyncSessionTypes {
    resyncSessionTypes {
      success
    }
  }
`;

export const TEST_CATALOG_BOOKABILITY = gql`
  mutation testCatalogBookability {
    testCatalogBookability {
      success
    }
  }
`;

export const DELETE_ENTERPRISE = gql`
  mutation deleteEnterprise {
    deleteEnterprise {
      success
    }
  }
`;

export const EDIT_MEMBERSHIP = gql`
  mutation editMembership($id: String!, $name: String!, $isActive: Boolean!) {
    editMembership(id: $id, name: $name, isActive: $isActive) {
      success
    }
  }
`;

export const EDIT_MEMBERSHIPS_ACTIVE_STATUS = gql`
  mutation editMembershipsActiveStatus($ids: [String!]!, $isActive: Boolean!) {
    editMembershipsActiveStatus(ids: $ids, isActive: $isActive) {
      success
    }
  }
`;

export const MODIFY_LOCATION_PAUSED_STATUS = gql`
  mutation modifyLocationPausedStatus($isPaused: Boolean!) {
    modifyLocationPausedStatus(isPaused: $isPaused) {
      success
    }
  }
`;

export const SAVE_ORGANIZATION_TEMPLATE = gql`
  mutation saveOrganizationTemplate($content: JSON!) {
    saveOrganizationTemplate(content: $content) {
      success
    }
  }
`;

export const SYNC_FROM_ORGANIZATION_TEMPLATE = gql`
  mutation syncFromOrganizationTemplate($templateId: String!) {
    syncFromOrganizationTemplate(templateId: $templateId) {
      success
    }
  }
`;

export const SAVE_CONFIG = gql`
  mutation saveConfig($data: JSON!) {
    saveConfig(data: $data) {
      success
    }
  }
`;

export const UPLOAD_CONFIG_ASSET = gql`
  mutation uploadConfigAsset(
    $type: String!
    $fileName: String!
    $file: Upload!
  ) {
    uploadConfigAsset(type: $type, fileName: $fileName, file: $file) {
      success
    }
  }
`;

export const EDIT_LOCATION_AGENCY = gql`
  mutation editLocationAgency($agencyId: String) {
    editLocationAgency(agencyId: $agencyId) {
      success
    }
  }
`;

export const EDIT_MESSAGING_PLATFORM = gql`
  mutation editMessagingPlatform($messagingPlatform: String!) {
    editMessagingPlatform(messagingPlatform: $messagingPlatform) {
      success
    }
  }
`;
