import React, { useContext } from 'react';
import { WhiteLabelLogo } from '../../pages-website/Homepage/styles/style-Partners';
import Paths from '../../Paths';
import { LinkTextSpan } from '../../styles/shared-styled-components';
import { getWhiteLabelId, whiteLabelConfigMap } from '../../utils/white-label';
import { BaseContext } from '../Auth/AuthRouter/AuthRouter';
import {
  FooterContainer,
  FooterContentContainer,
  FooterText,
  LeftFooterTextContainer,
  RightFooterTextContainer,
} from './styled';

const whiteLabelId = getWhiteLabelId();
const whiteLabelConfig = whiteLabelId ? whiteLabelConfigMap[whiteLabelId] : '';
const whiteLabelCompany = whiteLabelConfig?.company;
const logoConfig = whiteLabelConfig?.companyLogo;
const logo = logoConfig?.url;
const logoHeight = logoConfig?.height;
const logoWidth = logoConfig?.width;

const Footer = () => {
  const { width } = useContext(BaseContext);
  const topPaddingType = !logo ? null : width < 700 ? 'large' : 'small';

  return (
    <FooterContainer topPaddingType={topPaddingType}>
      <FooterContentContainer>
        <LeftFooterTextContainer>
          {logo && (
            <WhiteLabelLogo
              src={logo}
              height={logoHeight}
              width={logoWidth}
            />
          )}
          <FooterText>
            <LinkTextSpan
              href={Paths.privacyPolicy}
              target='_blank'
              useLargerOffset={false}
            >
              Privacy Policy
            </LinkTextSpan>
          </FooterText>
          <FooterText>
            {' '}
            <LinkTextSpan
              href={Paths.eula}
              target='_blank'
              useLargerOffset={false}
            >
              End-User License Agreement
            </LinkTextSpan>
          </FooterText>
        </LeftFooterTextContainer>
        <RightFooterTextContainer>
          <FooterText position='end'>
            © 2024 MedAcquire Inc.
            <br></br>
            dba LiveIQ AI
            {whiteLabelCompany
              ? `, in partnership with ${whiteLabelCompany}`
              : ''}
            <br></br>
            All rights reserved.
            <br></br>
            <br></br>
            635 W 42nd St, Suite 5T, Avenue Center, New York, NY 10036, United
            States
            <br></br>
            9:00AM-5:00PM
            <br></br>
            (646) 808-7583
          </FooterText>
        </RightFooterTextContainer>
      </FooterContentContainer>
    </FooterContainer>
  );
};

export default Footer;
