import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign(
    $locationId: String!
    $name: String!
    $type: CampaignType!
    $startDate: String
    $endDate: String
    $targetClients: Boolean!
    $targetLeads: Boolean!
    $agentId: String!
    $messageNewLeads: Boolean
    $lastContactDelayDays: Int
  ) {
    createCampaign(
      locationId: $locationId
      name: $name
      type: $type
      startDate: $startDate
      endDate: $endDate
      targetClients: $targetClients
      targetLeads: $targetLeads
      agentId: $agentId
      messageNewLeads: $messageNewLeads
      lastContactDelayDays: $lastContactDelayDays
    ) {
      id
      name
      status
      createdAt
    }
  }
`;

export const ADD_MEMBERS_TO_CAMPAIGN = gql`
  mutation addMembersToCampaign(
    $campaignId: String!
    $contactIds: [String!]
    $filter: String
    $createSmartList: Boolean
  ) {
    addMembersToCampaign(
      campaignId: $campaignId
      contactIds: $contactIds
      filter: $filter
      createSmartList: $createSmartList
    ) {
      campaign {
        id
        name
        memberIds
      }
      numAdded
    }
  }
`;

export const DELETE_MEMBERS_FROM_ALL_CAMPAIGNS = gql`
  mutation deleteMembersFromAllCampaigns(
    $contactIds: [String!]!
    $permanentlyDelete: Boolean!
  ) {
    deleteMembersFromAllCampaigns(
      contactIds: $contactIds
      permanentlyDelete: $permanentlyDelete
    ) {
      id
      name
      status
      memberIds
      numMembers
      agent {
        id
        name
      }
      promoId
      smartList {
        enabled
        filter
      }
      createdAt
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: String!) {
    deleteCampaign(id: $id) {
      success
    }
  }
`;

export const EDIT_CAMPAIGN = gql`
  mutation editCampaign(
    $id: String!
    $name: String!
    $type: CampaignType!
    $status: CampaignStatus!
    $startDate: String
    $endDate: String
    $targetClients: Boolean!
    $targetLeads: Boolean!
    $messageNewLeads: Boolean!
    $lastContactDelayDays: Int!
    $tagsToExclude: [String]
  ) {
    editCampaign(
      id: $id
      name: $name
      type: $type
      status: $status
      startDate: $startDate
      endDate: $endDate
      targetClients: $targetClients
      targetLeads: $targetLeads
      messageNewLeads: $messageNewLeads
      lastContactDelayDays: $lastContactDelayDays
      tagsToExclude: $tagsToExclude
    ) {
      success
    }
  }
`;

export const DELETE_UNCONTACTED_CAMPAIGN_MEMBERS = gql`
  mutation deleteUncontactedCampaignMembers($id: String!) {
    deleteUncontactedCampaignMembers(id: $id) {
      success
    }
  }
`;

export const UPDATE_SMART_LIST = gql`
  mutation updateSmartList($id: String!, $filter: String!) {
    updateSmartList(id: $id, filter: $filter) {
      success
    }
  }
`;

export const DELETE_SMART_LIST = gql`
  mutation deleteSmartList($campaignId: String!) {
    deleteSmartList(campaignId: $campaignId) {
      success
    }
  }
`;

export const SYNC_CAMPAIGN_TEMPLATE = gql`
  mutation syncCampaignTemplate($campaignId: String!) {
    syncCampaignTemplate(campaignId: $campaignId) {
      success
    }
  }
`;

export const CREATE_CAMPAIGN_LANDING_PAGE = gql`
  mutation createCampaignLandingPage($campaignId: String!) {
    createCampaignLandingPage(campaignId: $campaignId)
  }
`;
