import { sortBy } from 'lodash';
import { default as React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OptionsFilterDropdown from '../../../../components/Analytics/OptionsFilterDropdown/OptionsFilterDropdown';
import { FilterSectionContainer } from '../../../../components/Analytics/shared-analytics-styles';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import FilterAppointmentsModal from '../../../../components/Modals/FilterAppointmentsModal';
import Header from '../../../../components/NavBar/Header';
import {
  FilterButtonHangingChip,
  FilterButtonHangingChipText,
} from '../../../../components/Search/ClientSearch/styled';
import ClientSearchBox from '../../../../components/Search/ClientSearchBox/ClientSearchBox';
import Snackbar from '../../../../components/Snackbar';
import { EmptyDataContainer } from '../../../../components/Training/shared-training-components';
import Paths from '../../../../Paths';
import {
  BaseCard,
  CardActionsContainer,
  CardHeaderContainer,
  CardPageSection,
  CenteredDivWithLargerGap,
  ColumnDivWithGap,
  ContentContainer,
  DarkEssText,
  EndAlignedFlexDiv,
  ExtraSmallPrimaryCopyIcon,
  ExtraSmallSecondaryButton,
  ExtraSmallText,
  FilterIcon,
  LightDarkLargeTinyText,
  LightDarkTextSpan,
  MediumGapColumnCenteredDiv,
  NoDataIcon,
  PageContainer,
  PageTitleText,
  SecondaryBackIcon,
  StartAlignedMediumDarkLargeTinyText,
  TinyPrimaryButton,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  activeMemberContactTypeKey,
  appointmentContactTypeLabelMap,
  ascendingKey,
  cancelledStatusKey,
  clientContactTypeKey,
  completeStatusKey,
  descendingKey,
  eitherKey,
  expiredMemberContactTypeKey,
  inboundKey,
  leadContactType,
  noShowStatusKey,
  scheduledStatusKey,
  serviceSaleType,
  stagingFilterConversionDelay,
  superAdminRole,
  suspendedMemberContactTypeKey,
  textSeparatorChar,
} from '../../../../utils/constants';
import { dateToTextFormat, getTimestamp } from '../../../../utils/date';
import { getFormattedName, getInitials } from '../../../../utils/name';
import { formatNumber } from '../../../../utils/numbers';
import {
  copyTextToClipboard,
  formatKeyToLabel,
  getStringWithCapitalizedFirstLetter,
} from '../../../../utils/string';
import { CountContainer, StatusChip } from './styled';

const sortOptions = [
  {
    key: `revenue_${descendingKey}`,
    label: 'Revenue (most to least)',
  },
  {
    key: `revenue_${ascendingKey}`,
    label: 'Revenue (least to most)',
  },
  {
    key: `status_${ascendingKey}`,
    label: 'Appointment status (best to worst)',
  },
  {
    key: `status_${descendingKey}`,
    label: 'Appointment status (worst to best)',
  },
  {
    key: `created_${ascendingKey}`,
    label: 'Created Date (earliest to latest)',
  },
  {
    key: `created_${descendingKey}`,
    label: 'Created Date (latest to earliest)',
  },
  {
    key: `date_${ascendingKey}`,
    label: 'Scheduled Date (earliest to latest)',
  },
  {
    key: `date_${descendingKey}`,
    label: 'Scheduled Date (latest to earliest)',
  },
  {
    key: `clientType_${ascendingKey}`,
    label: 'Client type (least to most familiar)',
  },
  {
    key: `clientType_${descendingKey}`,
    label: 'Client type (most to least familiar)',
  },
  {
    key: `clientName_${ascendingKey}`,
    label: 'Client name (asc.)',
  },
  {
    key: `clientName_${descendingKey}`,
    label: 'Client name (desc.)',
  },
];

const statusOrder = [
  completeStatusKey,
  scheduledStatusKey,
  cancelledStatusKey,
  noShowStatusKey,
];
const clientTypeOrder = [
  leadContactType,
  clientContactTypeKey,
  expiredMemberContactTypeKey,
  suspendedMemberContactTypeKey,
  activeMemberContactTypeKey,
];

const directBookingKeys = ['chat', 'call'];

const Appointments = ({
  appointments,
  locationMap,
  clients,
  loading,
  agentName,
  campaigns,
  inDemoMode,
}) => {
  const navigate = useNavigate();

  const { drawerOpen, drawerExpanded, user } = useContext(BaseContext);
  const isSuperAdmin = user?.role === superAdminRole;

  const [sortField, setSortField] = useState({
    key: 'revenue',
    descending: true,
  });
  const [contactNameSearch, setContactNameSearch] = useState('');
  const [stagingContactNameSearch, setStagingContactNameSearch] = useState('');
  const [filter, setFilter] = useState({ bookedBy: eitherKey });
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stagingContactNameSearch !== contactNameSearch) {
        setContactNameSearch(stagingContactNameSearch);
      }
    }, stagingFilterConversionDelay);

    return () => clearTimeout(timeout);
  }, [stagingContactNameSearch]);

  const onSaveFilter = (updatedFilter) => {
    setFilterModalOpen(false);
    setFilter({ ...updatedFilter });
  };

  const bookedByFilter = filter?.bookedBy;
  const bookedByFilterApplied = bookedByFilter !== eitherKey;

  const formattedAppointments = [];
  const allAppointments = Object.values(appointments);
  allAppointments.map((a) => {
    const client = clients?.[a.clientId];
    const clientName = client?.name || '';
    const booker = a?.attribution?.booker;

    if (
      (!contactNameSearch ||
        clientName.toLowerCase().includes(contactNameSearch.toLowerCase())) &&
      (!bookedByFilterApplied ||
        (directBookingKeys.includes(booker) && bookedByFilter === 'true') ||
        (!directBookingKeys.includes(booker) && bookedByFilter === 'false'))
    ) {
      const items = a.items;
      const revenue =
        items?.length && a.status === completeStatusKey
          ? items?.reduce((total, obj) => total + obj.amount, 0)
          : a.status === completeStatusKey
          ? 0
          : -1;
      formattedAppointments.push({
        ...a,
        clientName: clientName || 'Name unknown',
        chatId: client?.chatId,
        revenue,
      });
    }
  });

  let sortedAppointments = sortBy(formattedAppointments, (a) => {
    const key = sortField.key;
    if (key === 'status') {
      return statusOrder.indexOf(a.status);
    }
    if (key === 'clientType') {
      return clientTypeOrder.indexOf(a.clientType);
    }
    return a[key];
  });
  if (sortField.descending) {
    sortedAppointments = sortedAppointments.reverse();
  }
  const totalNumAppointments = allAppointments.length;
  const numFilteredAppointments = sortedAppointments.length;

  const campaignMap = {};
  campaigns.map((c) => {
    campaignMap[c.key] = c.label;
  });

  const displayLocationName = Object.keys(locationMap)?.length > 1;

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <CenteredDivWithLargerGap bottomMargin={10}>
            <Tooltip
              title={<TooltipTitleText>Back to dashboard</TooltipTitleText>}
              placement='left'
            >
              <SecondaryBackIcon onClick={() => navigate(Paths.dashboard)} />
            </Tooltip>
            <PageTitleText>Appointments</PageTitleText>
          </CenteredDivWithLargerGap>
          <CardPageSection>
            <FilterSectionContainer spaced>
              <CountContainer>
                <LightDarkLargeTinyText>
                  {inDemoMode
                    ? ''
                    : contactNameSearch || bookedByFilterApplied
                    ? `${numFilteredAppointments} filtered appointment${
                        numFilteredAppointments === 1 ? '' : 's'
                      }`
                    : `${totalNumAppointments} total appointment${
                        totalNumAppointments === 1 ? '' : 's'
                      }`}{' '}
                </LightDarkLargeTinyText>
              </CountContainer>
              <ClientSearchBox
                entityLabel='client'
                value={stagingContactNameSearch}
                onChange={(e) => setStagingContactNameSearch(e.target.value)}
              />
              <EndAlignedFlexDiv gap={10}>
                <Tooltip
                  title={
                    <TooltipTitleText>Filter appointments</TooltipTitleText>
                  }
                  placement='bottom'
                >
                  <ExtraSmallSecondaryButton
                    onClick={() => setFilterModalOpen(true)}
                  >
                    <FilterIcon />
                    {bookedByFilterApplied ? (
                      <FilterButtonHangingChip>
                        <FilterButtonHangingChipText>
                          1
                        </FilterButtonHangingChipText>
                      </FilterButtonHangingChip>
                    ) : (
                      <></>
                    )}
                  </ExtraSmallSecondaryButton>
                </Tooltip>
                <OptionsFilterDropdown
                  label='Sort by'
                  options={sortOptions}
                  isMultiSelect={false}
                  filtered={[
                    `${sortField.key}_${
                      sortField.descending
                        ? `${descendingKey}`
                        : `${ascendingKey}`
                    }`,
                  ]}
                  onChange={(updatedSortField) => {
                    const values = updatedSortField.split('_');
                    setSortField({
                      key: values[0],
                      descending: values[1] === descendingKey,
                    });
                  }}
                />
              </EndAlignedFlexDiv>
            </FilterSectionContainer>
            <MediumGapColumnCenteredDiv>
              {sortedAppointments.length ? (
                <>
                  {sortedAppointments.map((a) => {
                    const {
                      id,
                      clientId,
                      items,
                      date,
                      locationId,
                      staff,
                      status,
                      services,
                      clientType,
                      attribution,
                    } = a;
                    const client = clients[clientId];
                    const clientName = inDemoMode
                      ? getInitials(
                          client?.name?.split(' ')[0] || '-',
                          client?.name?.split(' ')[1] || '-',
                        )
                      : getFormattedName(client?.name || 'Unknown client');

                    const staffName = inDemoMode
                      ? getInitials(
                          staff?.split(' ')[0] || '-',
                          staff?.split(' ')[1] || '-',
                        ) || 'AC'
                      : getFormattedName(staff);

                    const hasItems = items?.length;
                    const total = items?.reduce(
                      (total, obj) => total + obj.amount,
                      0,
                    );
                    const dateStr = dateToTextFormat(date);
                    const locationText = displayLocationName
                      ? ` at ${locationMap[locationId]}`
                      : '';
                    const title =
                      status === completeStatusKey
                        ? `${clientName}${locationText} - ${
                            hasItems
                              ? total
                                ? `Received $${formatNumber(total)}`
                                : `Free Appointment`
                              : `Revenue Unknown`
                          }`
                        : `${clientName} - ${formatKeyToLabel(status)}`;
                    const campaignKey = attribution.campaignId;
                    const campaignNameText =
                      campaignKey === inboundKey
                        ? `through an inbound phone call`
                        : `as part of the '${campaignMap[campaignKey]}' campaign`;
                    const attributionText = `Booked ${
                      attribution.booker === 'chat'
                        ? `directly by ${agentName}`
                        : `indirectly prompted by ${agentName}`
                    }, ${campaignNameText} (${getTimestamp(
                      a.created,
                      false,
                      true,
                      false,
                    )})`;

                    return (
                      <BaseCard>
                        <ColumnDivWithGap>
                          <CardHeaderContainer>
                            <DarkEssText>
                              {title}
                              <LightDarkTextSpan>
                                &nbsp;&nbsp;
                                {textSeparatorChar}&nbsp;&nbsp;
                                {dateStr}
                              </LightDarkTextSpan>
                            </DarkEssText>
                            <StatusChip
                              status={status}
                              fixedMinWidth={80}
                            >
                              {formatKeyToLabel(status)}
                            </StatusChip>
                          </CardHeaderContainer>
                          <StartAlignedMediumDarkLargeTinyText topMargin={10}>
                            🗓️&nbsp;&nbsp;{attributionText}
                            <br></br>
                            {clientType === leadContactType &&
                            [completeStatusKey, scheduledStatusKey].includes(
                              status,
                            ) ? (
                              <>🙌&nbsp;&nbsp;New client converted!</>
                            ) : (
                              <>
                                👤&nbsp;&nbsp;
                                {appointmentContactTypeLabelMap[clientType]}
                              </>
                            )}
                            <br></br>
                            <br></br>
                            {status === completeStatusKey ? (
                              <>
                                {hasItems ? (
                                  <>
                                    Received:{' '}
                                    {sortBy(items, (i) => i.amount)
                                      .reverse()
                                      .map(
                                        (i) =>
                                          `${i.name} ($${formatNumber(
                                            i.amount,
                                          )}${
                                            i.type !== serviceSaleType
                                              ? ` - ${getStringWithCapitalizedFirstLetter(
                                                  i.type,
                                                )}`
                                              : ''
                                          })`,
                                      )
                                      .join(', ')}
                                  </>
                                ) : services?.length ? (
                                  <>Received: {services.join(', ')} </>
                                ) : (
                                  <>Services unknown</>
                                )}
                              </>
                            ) : (
                              <>
                                {status === scheduledStatusKey
                                  ? `Scheduled`
                                  : `Was scheduled`}{' '}
                                to receive: {services.join(', ')}
                              </>
                            )}
                            <br></br>
                            {staffName
                              ? `With staff: ${staffName}`
                              : `Staff unknown`}
                          </StartAlignedMediumDarkLargeTinyText>
                        </ColumnDivWithGap>
                        <CardActionsContainer>
                          <TinyPrimaryButton
                            largePadding
                            onClick={() => {
                              const chatPath = `${Paths.chats}?chat-id=${client.chatId}`;
                              navigate(chatPath);
                            }}
                          >
                            View chat
                          </TinyPrimaryButton>
                          {isSuperAdmin && (
                            <ExtraSmallPrimaryCopyIcon
                              onClick={() => {
                                copyTextToClipboard(id);
                              }}
                            />
                          )}
                        </CardActionsContainer>
                      </BaseCard>
                    );
                  })}
                </>
              ) : (
                <>
                  <EmptyDataContainer>
                    <NoDataIcon />
                    <ExtraSmallText>No appointments found</ExtraSmallText>
                  </EmptyDataContainer>
                </>
              )}
            </MediumGapColumnCenteredDiv>
          </CardPageSection>
        </ContentContainer>
      </PageContainer>
      <FilterAppointmentsModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filter={filter}
        onSave={onSaveFilter}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Appointments;
