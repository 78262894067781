import { useMutation } from '@apollo/client';
import React, { useContext, useRef, useState } from 'react';
import {
  SAVE_CONFIG,
  UPLOAD_CONFIG_ASSET,
} from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Snackbar from '../../../components/Snackbar';
import {
  PromptSubtitleText,
  SmallPromptInput,
  SpacedPromptCheckboxContainer,
} from '../../../components/Training/shared-training-components';
import MetaSetter from '../../../components/Utils/MetaSetter';
import Paths from '../../../Paths';
import {
  AvatarEditContainer,
  ColumnCenteredDiv,
  FileInput,
  InputContainerForMediumGap,
  InputTitleContainer,
  LargeAgentAvatarContainer,
  SmallCheckbox,
  SmallPrimaryEditIcon,
  SmallText,
} from '../../../styles/shared-styled-components';
import { getUserAgency } from '../../../utils/user';
import { CustomAvatar } from './styled';

const Config = () => {
  const { drawerOpen, drawerExpanded, user, refetchUser } =
    useContext(BaseContext);

  const [saveConfigMutation, { loading }] = useMutation(SAVE_CONFIG);
  const [uploadFilesMutation, { loading: uploading }] =
    useMutation(UPLOAD_CONFIG_ASSET);

  const logoInputRef = useRef();
  const avatarInputRef = useRef();

  const onClickUpload = (ref) => {
    ref.current.click();
  };

  const onUpload = async (event, type) => {
    const file = event.target.files[0];

    uploadFilesMutation({
      variables: {
        type: 'agency',
        fileName: type,
        file: file,
      },
      onCompleted: async (data) => {
        const success = data.uploadConfigAsset;

        if (success) {
          setSnackbarMessage('Uploaded successfully');
          await refetchUser();
        }
      },
    });
  };

  const agency = getUserAgency(user);
  const agencyName = agency?.name;
  const config = agency?.config;
  console.log('agency', agency);

  const [whitelabel, setWhitelabel] = useState(config?.whitelabel || false);
  const [appName, setAppName] = useState(config?.appName || '');
  const [url, setUrl] = useState(config?.url || '');
  const [agentName, setAgentName] = useState(config?.agentName || 'Madison');
  const [snackbarMessage, setSnackbarMessage] = useState();

  const logoUrl = config?.logoUrl;
  const agentAvatar = config?.agentAvatar;

  const onSave = () => {
    saveConfigMutation({
      variables: {
        data: {
          type: 'agency',
          config: {
            whitelabel,
            appName,
            url,
            logoUrl,
            agentAvatar,
            agentName,
          },
        },
      },
      onCompleted: async (data) => {
        const success = data?.saveConfig?.success;
        if (success) {
          await refetchUser();
          setSnackbarMessage(`Saved`);
        }
      },
    });
  };

  const inputs = [
    <InputContainerForMediumGap
      removeMargin
      gap={10}
    >
      <InputTitleContainer>
        <SmallText>App</SmallText>
      </InputTitleContainer>
      <ColumnCenteredDiv>
        <SpacedPromptCheckboxContainer
          marginTop={8}
          center
        >
          <SmallCheckbox
            checked={whitelabel}
            onChange={() => {
              setWhitelabel(!whitelabel);
            }}
          />
          <PromptSubtitleText>White label</PromptSubtitleText>
        </SpacedPromptCheckboxContainer>
        {whitelabel && (
          <>
            <LargeAgentAvatarContainer
              topMargin={8}
              bottomMargin={5}
              allowEditing
            >
              {logoUrl ? (
                <CustomAvatar src={logoUrl} />
              ) : (
                <CustomAvatar
                  src={'/small-logo.png'}
                  contain
                />
              )}
              <AvatarEditContainer onClick={() => onClickUpload(logoInputRef)}>
                <SmallPrimaryEditIcon />
                <FileInput
                  ref={logoInputRef}
                  onChange={(e) => onUpload(e, 'logoUrl')}
                />
              </AvatarEditContainer>
            </LargeAgentAvatarContainer>
            <SmallPromptInput
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              useExtraSmallWidth
              useSmallText
              label='URL'
              smallGap
            />
            <SmallPromptInput
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              useExtraSmallWidth
              useSmallText
              label='App name'
              smallGap
            />
          </>
        )}
      </ColumnCenteredDiv>
    </InputContainerForMediumGap>,
    <InputContainerForMediumGap
      removeMargin
      gap={10}
    >
      <InputTitleContainer>
        <SmallText>Agent</SmallText>
      </InputTitleContainer>
      <LargeAgentAvatarContainer
        topMargin={8}
        bottomMargin={5}
        allowEditing
      >
        {agentAvatar ? (
          <CustomAvatar src={agentAvatar} />
        ) : (
          <CustomAvatar src={Paths.receptionistAvatar} />
        )}
        <AvatarEditContainer onClick={() => onClickUpload(avatarInputRef)}>
          <SmallPrimaryEditIcon />
          <FileInput
            ref={avatarInputRef}
            onChange={(e) => onUpload(e, 'agentAvatar')}
          />
        </AvatarEditContainer>
      </LargeAgentAvatarContainer>
      <ColumnCenteredDiv>
        <SmallPromptInput
          value={agentName}
          onChange={(e) => setAgentName(e.target.value)}
          useExtraSmallWidth
          useSmallText
          label='Agent name'
        />
      </ColumnCenteredDiv>
    </InputContainerForMediumGap>,
  ];

  if (loading || uploading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Config`}
        description={`Config`}
      />
      <FormPage
        title={`${agencyName} Config`}
        isLoading={loading}
        inputs={inputs}
        onContinue={onSave}
        backEnabled={false}
        continueEnabled={true}
        continueButtonText={'Save'}
      />
      <Snackbar
        quick
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Config;
