import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import {
  GET_APPOINTMENT_CLIENTS,
  GET_CALL_ANALYTICS,
  GET_CAMPAIGN_ANALYTICS,
  GET_INTERCEPTIONS,
  GET_MY_ANALYTICS,
} from '../queries/analytics';

export const useMyAnalytics = ({ locationIds, campaignIds, start, end }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_ANALYTICS, {
    variables: {
      locationIds,
      campaignIds,
      start,
      end,
    },
    fetchPolicy: 'network-only',
  });

  return {
    error,
    loading,
    refetch,
    analytics: get(data, 'getMyAnalytics', null),
  };
};

export const useCampaignAnalytics = ({
  skipCondition,
  campaignIds,
  start,
  end,
}) => {
  const { data, error, loading, refetch } = useQuery(GET_CAMPAIGN_ANALYTICS, {
    variables: {
      campaignIds,
      start,
      end,
    },
    skip: skipCondition,
    fetchPolicy: 'network-only',
  });

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getCampaignAnalytics', null),
  };
};

export const useCallAnalytics = ({ skipCondition, start, end }) => {
  const { data, error, loading, refetch } = useQuery(GET_CALL_ANALYTICS, {
    variables: {
      start,
      end,
    },
    skip: skipCondition,
    fetchPolicy: 'network-only',
  });

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getCallAnalytics', null),
  };
};

export const useAppointmentClients = ({ clientIds, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_APPOINTMENT_CLIENTS, {
    variables: {
      clientIds,
    },
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    clients: get(data, 'getAppointmentClients', null),
  };
};

export const useInterceptions = ({ chats, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_INTERCEPTIONS, {
    variables: {
      chats,
    },
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    interceptions: get(data, 'getInterceptions', null),
  };
};
