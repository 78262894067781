import React from 'react';
import { DarkGoldText } from '../../../styles/shared-styled-components';
import {
  TestimonialContainer,
  TestimonialHeaderSubText,
  TestimonialHeaderText,
  TestimonialMedia,
  TestimonialMediaContainer,
  TestimonialTextContainer,
} from '../styles/style-Testimonials';

const Testimonials = ({ darkMode }) => {
  return (
    <div id='testimonials'>
      <TestimonialContainer>
        <TestimonialTextContainer>
          <p>
            <TestimonialHeaderText>
              Hear how Stanton, a multi-unit VIO franchisee achieved{' '}
              <DarkGoldText>>9x ROI</DarkGoldText> for his business with LiveIQ
            </TestimonialHeaderText>
          </p>
          <TestimonialHeaderSubText>
            The LiveIQ front desk
            <DarkGoldText>
              {' '}
              booked appointments, converted leads, and upsold memberships
            </DarkGoldText>{' '}
            all on its own!
          </TestimonialHeaderSubText>
        </TestimonialTextContainer>
        <TestimonialMediaContainer>
          <TestimonialMedia dark={darkMode}>
            <iframe
              width='389'
              height='692'
              src='https://www.youtube.com/embed/zSPp4E-gZtM'
              title='LiveIQ Testimonial [VIO MedSpa]'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              referrerpolicy='strict-origin-when-cross-origin'
              allowfullscreen
            ></iframe>
          </TestimonialMedia>
        </TestimonialMediaContainer>
      </TestimonialContainer>
    </div>
  );
};

export default Testimonials;
