import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { STORE_TWILIO_CONFIG } from '../../../api/mutations/app-connections';
import {
  ColumnCenteredDiv,
  DarkMLText,
  Modal,
  ModalActions,
  ModalContainer,
  PrimaryButton,
} from '../../../styles/shared-styled-components';
import { phoneInputType } from '../../../utils/constants';
import Input from '../../Form/Input/Input';

const TwilioModal = ({ isOpen, onClose, onSuccess, location }) => {
  const [storeTwilioConfigMutation] = useMutation(STORE_TWILIO_CONFIG);

  const [number, setNumber] = useState(
    location?.messagingServicePhoneNumber || '',
  );
  const [messagingServiceAccountSid, setMessagingServiceAccountSid] = useState(
    location?.messagingServiceAccountSid || '',
  );
  const [messagingServiceSid, setMessagingServiceSid] = useState(
    location?.messagingServiceSid || '',
  );
  const [authToken, setAuthToken] = useState('');

  const onSave = () => {
    storeTwilioConfigMutation({
      variables: {
        data: {
          managedByLiveIq: false,
          number,
          authToken,
          messagingServiceAccountSid,
          messagingServiceSid,
        },
      },
      onCompleted: async () => {
        await onSuccess();
        setAuthToken();
      },
    });
  };

  const continueEnabled =
    !!number && !!messagingServiceAccountSid && !!messagingServiceSid;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer>
        <DarkMLText>Twilio Config</DarkMLText>
        <Input
          id='number'
          value={number}
          onChange={(value) => {
            setNumber(value);
          }}
          label='Phone number'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
          type={phoneInputType}
        />
        <Input
          id='account-sid'
          value={messagingServiceAccountSid}
          onChange={(e) => {
            setMessagingServiceAccountSid(e.target.value);
          }}
          label='Account SID'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
        />
        <Input
          id='messaging-service-sid'
          value={messagingServiceSid}
          onChange={(e) => {
            setMessagingServiceSid(e.target.value);
          }}
          label='Messaging Service SID'
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
        />
        <Input
          id='auth-token'
          value={authToken}
          onChange={(e) => {
            setAuthToken(e.target.value);
          }}
          label={`Auth token`}
          isRequired={false}
          removeGap={true}
          useSmallWidth={true}
        />
        <ModalActions>
          <ColumnCenteredDiv>
            <PrimaryButton
              disabled={!continueEnabled}
              onClick={onSave}
            >
              Save
            </PrimaryButton>
          </ColumnCenteredDiv>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default TwilioModal;
