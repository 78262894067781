import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  ColumnDiv,
  ColumnFlexStyle,
  ExtraLargeGapStyle,
  FullWidthColumnCenteredDiv,
  MediumGapStyle,
  PanelStyle,
  SmallGapStyle,
  SMGapStyle,
  StartAlignedMediumDarkTinyText,
} from '../../../../styles/shared-styled-components';

export const Container = styled(ColumnDiv)`
  ${ExtraLargeGapStyle}
  margin-bottom: 0px;
`;

export const NoLocationsCreatedContainer = styled.div`
  ${ColumnFlexStyle}
  ${MediumGapStyle}
  margin-top: 30px;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  gap: ${(props) => props.addGap && `15px`};
`;

export const TopPanelSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SMGapStyle}
  width: 80%;
  height: 90%;
`;

export const TopPanel = styled.div`
  ${ColumnFlexStyle}
  flex: 1;
  height: 90%;
  justify-content: space-between;
  align-items: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
  ${SmallGapStyle}
  padding: 20px;
  ${PanelStyle}
`;

export const QueryPanelContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 20px;
  height: 90%;
  justify-content: flex-start;
  align-items: center;
`;

export const QueryPanel = styled(TopPanel)`
  border: 1px dotted ${({ theme }) => theme.color.lightBlue};
  width: 100%;
  min-width: 100px;
  padding-top: ${(props) => !props.center && '15px'};
  padding-bottom: ${(props) => !props.center && '25px'};
  justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
  align-items: ${(props) => props.center && 'center'};
  gap: 0px;
  cursor: default;
  position: relative;
  &:hover {
    transform: unset;
    background-color: unset;
  }
`;

export const AvatarSection = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const QueryFieldText = styled(StartAlignedMediumDarkTinyText).attrs({
  centered: true,
})`
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.color.lightPrimary};
    `}
  &:hover {
    color: ${({ theme }) => theme.color.lightPrimary};
  }
`;

export const SectionDiv = styled.div`
  border: 2px dotted ${({ theme }) => theme.color.muiInputLightGray};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  display: flex;
  position: relative;
  justify-content: space-between;
`;

export const SectionComponentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${MediumGapStyle}
  width: 100%;
  margin-top: 30px;
`;

export const SectionTitleContainer = styled.div`
  position: absolute;
  top: -15px;
  left: 30px;
  background-color: white;
  padding: 0px 10px;
`;

export const ColumnSectionContentDiv = styled(FullWidthColumnCenteredDiv)`
  padding-top: 50px;
  ${SMGapStyle}
`;

export const SectionTopPanelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SubSectionTopPanel = styled(TopPanelSection)`
  width: calc(100% - 30px);
  ${AutoHorizontalMargins}
  ${SmallGapStyle}
`;
