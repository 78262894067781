import gql from 'graphql-tag';

export const GET_MY_CAMPAIGNS = gql`
  query getMyCampaigns(
    $agencyId: String
    $viewingAgency: Boolean
    $locationIds: [String]
    $shallow: Boolean!
  ) {
    getMyCampaigns(
      agencyId: $agencyId
      viewingAgency: $viewingAgency
      locationIds: $locationIds
      shallow: $shallow
    ) {
      id
      locationId
      name
      type
      status
      startDate
      endDate
      memberIds
      numMembers
      numContacted
      agent {
        id
        name
      }
      promoId
      landingPageId
      targetClients
      targetLeads
      template {
        id
        type
      }
      messageNewLeads
      lastContactDelayDays
      tagsToExclude
      smartList {
        enabled
        filter
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTACT_TAGS = gql`
  query getContactTags {
    getContactTags
  }
`;

export const GET_CAMPAIGN_TEMPLATES = gql`
  query getCampaignTemplates {
    getCampaignTemplates
  }
`;

export const GET_CAMPAIGN_LANDING_PAGE = gql`
  query getCampaignLandingPage($id: String!) {
    getCampaignLandingPage(id: $id)
  }
`;
