import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StorefrontIcon from '@mui/icons-material/Storefront';
import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  AutoScrollWrapper,
  BookableStatusTextStyle,
  CardStyle,
  CenteredDiv,
  CenterFlexStyle,
  CheckedRadioButton,
  CollapsedDrawerWidthValue,
  ColumnCenteredDiv,
  ColumnDiv,
  ColumnFlexStyle,
  DirectlyBookableIcon,
  EditIcon,
  EmptyGapColumnCenteredDiv,
  EssGapStyle,
  EssIconSize,
  ExpandedDrawerWidthValue,
  ExtraSmallGapStyle,
  ExtraSmallIconSize,
  ExtraTinyText,
  FlexDiv,
  FullSizeColumnCenteredDiv,
  IndirectlyBookableIcon,
  LargeGapStyle,
  LightDarkEssText,
  LightDarkExtraTinyText,
  LightDarkTinyText,
  MediumDarkEssText,
  MediumDarkExtraSmallText,
  MediumDarkLargeTinyText,
  MediumDarkSmallText,
  MediumDarkTinyText,
  MediumGapStyle,
  MLGapStyle,
  ModulesDrawerWidth,
  PackageIcon,
  RadioButton,
  RedCancelIcon,
  RequiresConsultationIcon,
  ScrollStyle,
  SmallCheckbox,
  SmallerTextInputWidthStyle,
  SmallGapStyle,
  SMGapStyle,
  SMMIconSize,
  StartAlignedMediumDarkLargeTinyText,
  SubmodulesDrawerWidth,
  TextInputWidthStyle,
  TinyText,
  Tooltip,
  TooltipTitleText,
  UnbookableServiceIcon,
  UncheckedRadioButton,
} from '../../styles/shared-styled-components';
import {
  bookableThroughPackageSessionTypeStatus,
  completeStatusKey,
  directlyBookableSessionTypeStatus,
  discontinuedSessionTypeStatus,
  incompleteStatusKey,
  indirectlyBookableSessionTypeStatus,
  requiresConsultationBookableSessionTypeStatus,
  sourcePackageBookableSessionTypeStatus,
  unbookableSessionTypeStatus,
  unclassifiedKey,
} from '../../utils/constants';
import Input from '../Form/Input';

const FullSizeSectionStyle = css`
  height: ${(props) =>
    !props.unsetHeight &&
    `calc(100dvh - ${
      props.theme.spacing.headerHeight +
      props.theme.spacing.headerVerticalPadding * 2 -
      200
    }px)`};
  width: calc(100% - 80px);
`;

export const PromptsSection = styled(AutoScrollWrapper)`
  ${ColumnFlexStyle}
  ${LargeGapStyle}
  ${FullSizeSectionStyle}
  position: relative;
  overflow: ${(props) => props.hideOverflow && 'hidden'};
  margin-top: -10px;
`;

export const BaseAutoSaveContainer = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50px;
`;

export const PromptContainer = styled(ColumnCenteredDiv)`
  overflow: ${(props) => props.overflow && 'auto'};
  position: relative;
  padding-top: ${(props) => (props.removePadding ? '0px' : '8px')};
  ${(props) =>
    props.addLargeGap
      ? css`
          ${LargeGapStyle}
        `
      : props.addMlGap
      ? css`
          ${MLGapStyle}
        `
      : ''};
`;

export const SpacedPromptCheckboxContainer = styled(FlexDiv)`
  ${SmallerTextInputWidthStyle}
  justify-content: ${(props) =>
    props.center ? 'center' : !props.start ? `space-between` : 'flex-start'};
  margin-bottom: ${(props) => props.bottomMargin && `${props.bottomMargin}px`};
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
`;

export const PromptTitleText = styled(MediumDarkSmallText)``;

export const PromptSubtitleText = styled(MediumDarkEssText)``;

export const PromptHeaderTextContainer = styled(CenteredDiv)`
  ${TextInputWidthStyle}
  margin: 10px 0px;
  ${ExtraSmallGapStyle}
`;

export const PromptHeaderTextObjectContainer = styled(CenteredDiv)``;

export const PromptHeaderText = styled(MediumDarkLargeTinyText)``;

export const PromptInput = styled(Input).attrs((props) => ({
  removeGap: !props.smallGap,
  useExtraSmallGap: props.smallGap,
  useSmallWidth: true,
  useFullWidth: !!props.useFullWidth,
  includeDeleteButton: props.includeDeleteButton,
  onDelete: props.onDelete,
}))``;

export const PromptCheckboxContainer = styled(EmptyGapColumnCenteredDiv)`
  ${SmallerTextInputWidthStyle}
`;

export const PromptCheckboxContentContainer = styled.div`
  ${CenterFlexStyle}
  justify-content: space-between;
  ${SmallGapStyle}
  width: calc(50%);
  min-width: 300px;
`;

export const EmptyDataContainer = styled(FullSizeColumnCenteredDiv)`
  justify-content: flex-start;
  padding-top: 40px;
  ${(props) =>
    props.removeGap
      ? css`
          gap: 0px;
        `
      : css`
          ${SMGapStyle}
        `}
`;

export const ListInputTitleContainer = styled.div`
  ${CenterFlexStyle}
  ${(props) =>
    props.addBottomMargin &&
    css`
      margin-bottom: 10px;
    `}
`;

const ListInputSectionWidthStyle = css`
  ${TextInputWidthStyle}
  width: ${(props) =>
    props.widthPercentage ? `${props.widthPercentage}%` : `60%`};
  min-width: calc(min(80%, 300px));
  max-width: 780px;
  overflow: auto;
  padding-bottom: ${(props) =>
    props.bottomPadding && `${props.bottomPadding}px`};
`;

export const ListInputSection = styled.div`
  ${ListInputSectionWidthStyle}
  display: flex;
  ${ColumnFlexStyle}
  ${SmallGapStyle}
  overflow-x: hidden;
  min-height: ${(props) => `${props.height - 280}px`};
  max-height: ${(props) => `${props.height - 280}px`};
`;

export const MarkCompleteContainer = styled.div`
  position: fixed;
  bottom: 0px;
  height: 45px;
  ${ListInputSectionWidthStyle}
  border-top: 2px dotted ${({ theme }) => theme.color.muiInput};
  background-color: white;
`;

export const ListInputContainer = styled(FlexDiv)`
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px dotted ${({ theme }) => theme.color.muiInput};
`;

export const ListInputTextContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-start;
  justify-content: center;
`;

export const PromptScrollContainer = styled(AutoScrollWrapper)`
  ${ColumnFlexStyle}
  ${MLGapStyle}
`;

export const ListBannerContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 5px;
  ${CenterFlexStyle}
  border-bottom: 2.5px dotted ${({ theme }) => theme.color.lightError};
  position: relative;
`;

export const ListBannerTextContainer = styled.div`
  position: absolute;
  background-color: white;
  padding: 0px 8px;
`;

export const MappingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${FullSizeSectionStyle}
  position: relative;
  ${ScrollStyle}
  ${(props) =>
    props.saving &&
    css`
      background-color: ${({ theme }) => theme.color.offWhite};
      opacity: 0.4;
      z-index: 1000;
      color: white;
      fontsize: 1.5rem;
    `}
`;

const SectionBorderStyle = css`
  border-right: ${(props) =>
    !props.hideBorder && `1px dotted ${props.theme.color.muiInput}`};
`;

const TextAlignmentStyle = css`
  text-align: ${(props) =>
    props.start ? `start` : props.end ? `end` : `center`};
`;

const remainingHeightBottomPadding = 100;

export const MappingResolutionContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-start;
  ${LargeGapStyle}
  flex: 1.2;
  ${SectionBorderStyle}
  padding-right: 30px;
  height: fit-content;
  min-height: ${(props) =>
    props.remainingHeight + remainingHeightBottomPadding}px;
  position: relative;
`;

const confirmMenuLeftOffset = 34;
const horizontalPadding = 20;

export const ConfirmMenuContainer = styled.div`
  position: fixed;
  bottom: 0px;
  height: 50px;
  width: ${(props) =>
    props.width + confirmMenuLeftOffset - 1 - horizontalPadding * 2}px;
  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.color.muiInput};
  padding: 5px ${horizontalPadding}px;
  ${CenterFlexStyle}
  justify-content: space-between;
  ${SmallGapStyle}
  left: ${(props) =>
    props.drawerExpanded
      ? `${
          ExpandedDrawerWidthValue +
          ModulesDrawerWidth +
          SubmodulesDrawerWidth +
          6
        }px`
      : props.drawerOpen
      ? `${
          CollapsedDrawerWidthValue +
          ModulesDrawerWidth +
          SubmodulesDrawerWidth +
          6
        }px`
      : `${ModulesDrawerWidth + SubmodulesDrawerWidth}px`};
`;

export const ServicesAutoSaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 150px;
`;

export const ServicesAutoSaveText = styled(LightDarkTinyText)`
  font-size: 12px;
  line-height: 1.1;
  word-wrap: break-word;
  text-align: end;
`;

export const ResolutionTextContainer = styled(MappingResolutionContainer)`
  ${EssGapStyle}
  width: 100%;
  border: 0px;
  flex: 0;
`;

export const ResolutionTitleText = styled(LightDarkEssText)`
  line-height: 1.2;
  ${TextAlignmentStyle}
`;

export const ResolutionDescriptionText = styled(
  StartAlignedMediumDarkLargeTinyText,
)`
  line-height: 1.3;
`;

export const ResolutionActionsContainer = styled(ColumnCenteredDiv)`
  ${SMGapStyle}
  align-items: flex-start;
  width: 80%;
  margin: 0px 4px;
  padding-bottom: 80px;
`;

export const ResolutionActionCard = styled(ColumnCenteredDiv)`
  ${CardStyle}
  position: relative;
  cursor: pointer;
  width: 100%;
`;

export const CancelIconContainer = styled.div`
  position: absolute;
  right: -6px;
  top: -5px;
  background-color: white;
  border-radius: 50%;
  height: fit-content;
  width: fit-content;
  ${CenterFlexStyle}
`;

export const ActionConnectorTextContainer = styled(CenteredDiv)`
  width: 100%;
`;

export const MappingActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1.1;
  ${SectionBorderStyle}
  margin: 0px 30px;
  padding-right: 30px;
  min-height: ${(props) =>
    props.remainingHeight + remainingHeightBottomPadding}px;
`;

export const ActionsContainer = styled(ColumnCenteredDiv)`
  ${SMGapStyle}
  width: 100%;
  margin-top: 10px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ActionCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${CardStyle}
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  padding: ${({ theme }) => `${theme.spacing.smallContainerPadding}px`};
  border: 1.5px
    ${(props) =>
      props.selected
        ? `solid ${props.theme.color.brightGreen}`
        : props.disabled
        ? `dotted ${props.theme.color.muiInput}`
        : `solid ${props.theme.color.muiInput}`};
  width: 80%;
  ${AutoHorizontalMargins}
  cursor: ${(props) => !props.disabled && `pointer`};
  ${ExtraSmallGapStyle}
`;

export const ActionIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectCheckbox = styled(SmallCheckbox)`
  color: ${(props) =>
    props.disabled && `${props.theme.color.disabled} !important`};
`;

export const SelectRadioButton = styled(CheckedRadioButton)`
  color: ${(props) => props.disabled && props.theme.color.disabled};
  ${(props) =>
    props.large &&
    css`
      width: 18px;
      height: 18px;
    `}
`;

export const UnselectedRadioButton = styled(UncheckedRadioButton)`
  color: ${(props) =>
    props.disabled ? props.theme.color.disabled : props.theme.color.primary};
  ${(props) =>
    props.large &&
    css`
      width: 18px;
      height: 18px;
    `}
`;

export const DisabledIcon = styled(UncheckedRadioButton)``;

export const ActionTextContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: flex-end;
`;

export const ActionTitleText = styled(MediumDarkLargeTinyText)`
  color: ${(props) => props.disabled && props.theme.color.disabledText};
  text-align: end;
`;

export const ActionSubtitleText = styled(LightDarkTinyText)`
  color: ${(props) => props.disabled && props.theme.color.disabledText};
  text-align: end;
  line-height: 1.2;
`;

export const ActionDescriptionText = styled(ActionTitleText)`
  text-align: start;
  line-height: 1.3;
`;

export const OfferingsListContainer = styled(ColumnCenteredDiv)`
  margin: 10px 0px;
  ${ExtraSmallGapStyle}
`;

export const OfferingContainer = styled.div`
  border: 1px dotted ${({ theme }) => theme.color.muiInput};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  padding: 5px 10px;
  position: relative;
  width: calc(100% - 20px);
  background-color: white;
`;

export const OfferingCancelIconContainer = styled(CancelIconContainer)``;

export const OfferingCancelIcon = styled(RedCancelIcon)`
  ${ExtraSmallIconSize}
`;

export const OfferingTitleText = styled(MediumDarkTinyText)`
  line-height: 1.2;
`;

export const OfferingAttachmentSection = styled.div`
  margin-top: -7px;
  z-index: -1;
`;

export const OfferingAttachmentContainer = styled(OfferingContainer)`
  margin-top: -7px;
  position: relative;
  border: 1px dashed ${({ theme }) => theme.color.superLightPrimary};
`;

export const OfferingAttachmentButtonContainer = styled.div`
  border: 1px dotted ${({ theme }) => theme.color.muiInputLightDarkGray};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  padding: 3px 10px;
  padding-top: 8px;
  border-top: 0px;
  margin-top: -14px;
  cursor: pointer;
  color: ${(props) =>
    props.selected
      ? `${props.theme.color.lightPrimary}`
      : `${props.theme.color.lightDarkText}`};
  &:hover {
    border-color: ${({ theme }) => theme.color.lightPrimary};
    color: ${({ theme }) => theme.color.lightPrimary};
  }
`;

export const OfferingAttachmentText = styled(LightDarkExtraTinyText)`
  color: inherit;
`;

export const MustAddOfferingsTextContainer = styled(CenteredDiv)``;

export const MustAddOfferingsText = styled(TinyText)`
  color: ${({ theme }) => theme.color.muiInputLightDarkGray};
`;

export const ResolutionExplanationContainer = styled.div`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  cursor: pointer;
  margin-top: 10px;
`;

export const ResolutionExplanationTextContainer = styled(
  EmptyGapColumnCenteredDiv,
)`
  ${SmallGapStyle}
`;

export const ResolutionExplanationHeaderTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const ExplanationIcon = styled(EditIcon)`
  ${EssIconSize}
  color: ${({ theme }) => theme.color.lightText};
  margin-top: -2px;
  cursor: pointer;
`;

export const ResolutionExplanationText = styled(MediumDarkTinyText)`
  line-height: 1.2;
  color: ${({ theme }) => theme.color.lightDarkText};
  font-style: oblique 13deg;
`;

export const SelectOptionsContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-end;
  justify-content: flex-start;
  flex: 0.9;
  ${MediumGapStyle}
`;

export const EmptySelectOptionsContainer = styled(FullSizeColumnCenteredDiv)`
  height: 80%;
  ${MediumGapStyle}
`;

export const EmptySelectOptionsText = styled(LightDarkEssText)`
  line-height: 1.2;
`;

export const SelectOptionsTitleTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const OptionWidthStyle = css`
  width: calc(100% - 50px);
`;

export const OptionsSearchBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${OptionWidthStyle}
  ${SMGapStyle}
  height: ${(props) => props.fixedHeight && `${props.fixedHeight}px`};
  ${(props) =>
    props.moveUp
      ? css`
          margin-bottom: -20px;
          margin-top: -10px;
        `
      : css`
          margin-bottom: 20px;
          margin: 10px 0px;
        `}
  margin-top: ${(props) => props.topMargin && `${props.topMargin}px`};
`;

export const OptionsListContainer = styled(EmptyGapColumnCenteredDiv)`
  ${OptionWidthStyle}
  justify-content: flex-start;
  align-items: flex-end;
  height: ${(props) => `${props.remainingHeight - 200}px`};
  overflow: auto;
`;

export const OptionItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5px;
  width: ${(props) =>
    props.widthPercentage ? `${props.widthPercentage}%` : `100%`};
  margin-bottom: ${(props) =>
    props.bottom
      ? '15px'
      : props.bottomMargin
      ? `${props.bottomMargin}px`
      : props.top
      ? `-12px`
      : ''};
  ${EssGapStyle}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
    ${(props) =>
    props.selected
      ? css`
          border: 1px dotted ${props.theme.color.brightGreen};
          border-radius: 10px;
        `
      : css`
          border: 1px solid white;
          border-bottom: ${(props) =>
            !props.bottom &&
            !props.removeBottomMargin &&
            !props.top &&
            `1px dotted ${props.theme.color.muiInput}`};
        `}
`;

export const OptionSubitemTextContainer = styled(ColumnDiv)`
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

export const OptionSubitemText = styled(MediumDarkTinyText)`
  text-align: ${(props) => (props.start ? `start` : `end`)};
  line-height: 1.15;
  word-break: break-word;
  color: ${(props) => props.selected && props.theme.color.primary};
`;

export const OptionSubitemSubtitleText = styled(ExtraTinyText)`
  ${BookableStatusTextStyle}
  text-align: ${(props) => props.end && `end`};
  line-height: 1.2;
`;

export const OptionParagraphText = styled(LightDarkTinyText)`
  color: ${(props) =>
    props.success
      ? props.theme.color.brightGreen
      : props.fail
      ? props.theme.color.lightError
      : 'default'};
`;

export const OptionActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${ExtraSmallGapStyle}
`;

export const OptionCheckbox = styled(SmallCheckbox)`
  padding: ${(props) => props.disablePadding && '0px'};
`;

export const OptionRadioButton = styled(RadioButton)``;

export const OptionIconContainer = styled.div`
  border-radius: 50%;
  border: 1px dotted ${({ theme }) => theme.color.muiInput};
  ${SMMIconSize}
  padding: 1px;
  ${CenterFlexStyle}
  cursor: ${(props) => !props.unclickable && 'pointer'};
  ${(props) =>
    props.status === directlyBookableSessionTypeStatus ||
    props.status === completeStatusKey
      ? css`
          color: ${({ theme }) => theme.color.brightGreen};
          border-color: ${({ theme }) => theme.color.brightGreen};
          &:hover {
            color: ${({ theme }) => theme.color.deepGreen};
          }
        `
      : props.status === bookableThroughPackageSessionTypeStatus
      ? css`
          color: ${({ theme }) => theme.color.secondary};
          border-color: ${({ theme }) => theme.color.secondary};
          &:hover {
            color: ${({ theme }) => theme.color.secondary};
          }
        `
      : props.status === indirectlyBookableSessionTypeStatus
      ? css`
          color: ${({ theme }) => theme.color.orange};
          border-color: ${({ theme }) => theme.color.orange};
          &:hover {
            color: ${({ theme }) => theme.color.orange};
          }
        `
      : props.status === requiresConsultationBookableSessionTypeStatus
      ? css`
          color: ${({ theme }) => theme.color.lessDeepBlue};
          border-color: ${({ theme }) => theme.color.lessDeepBlue};
          &:hover {
            color: ${({ theme }) => theme.color.deepBlue};
          }
        `
      : props.status === unbookableSessionTypeStatus ||
        props.status === discontinuedSessionTypeStatus
      ? css`
          color: ${({ theme }) => theme.color.superLightError};
          border-color: ${({ theme }) => theme.color.superLightError};
          &:hover {
            color: ${({ theme }) => theme.color.lightError};
          }
        `
      : props.status === incompleteStatusKey
      ? css`
          color: ${({ theme }) => theme.color.lightError};
          border-color: ${({ theme }) => theme.color.lightError};
          &:hover {
            color: ${({ theme }) => theme.color.error};
          }
        `
      : props.status === unclassifiedKey
      ? css`
          color: ${({ theme }) => theme.color.disabledText};
          border-color: ${({ theme }) => theme.color.disabledText};
          &:hover {
            color: ${({ theme }) => theme.color.disabledText};
          }
        `
      : css`
          color: ${({ theme }) => theme.color.lightPrimary};
          border-color: ${({ theme }) => theme.color.lightPrimary};
          &:hover {
            color: ${({ theme }) => theme.color.primary};
          }
        `}
`;

export const PromptDialsSectionWrapper = styled.div``;

export const PromptDialsSectionContainer = styled.div`
  height: 100%;
`;

const DialsSectionOffsetValue = 35;

export const PromptDialsSection = styled(ColumnCenteredDiv)`
  height: ${(props) =>
    props.top ? `calc(50% - ${DialsSectionOffsetValue}px)` : `calc(50% - 5px)`};
  width: calc(50% - 20px);
  float: left;
  justify-content: flex-start;
  border-left: ${(props) =>
    props.borderLeft && `1px dotted ${props.theme.color.muiInput}`};
  border-right: ${(props) =>
    props.borderRight && `1px dotted ${props.theme.color.muiInput}`};
  border-top: ${(props) =>
    props.borderTop && `1px dotted ${props.theme.color.muiInput}`};
  border-bottom: ${(props) =>
    props.borderBottom && `1px dotted ${props.theme.color.muiInput}`};
  padding-top: ${(props) => props.topPadding && `${DialsSectionOffsetValue}px`};
  padding-left: ${(props) => props.leftPadding && `10px`};
  padding-right: ${(props) => props.rightPadding && `10px`};
`;

export const PromptDialsSectionTitleText = styled(MediumDarkSmallText)``;

export const PromptDialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  ${SMGapStyle}
`;

export const PromptDialContainer = styled(ColumnCenteredDiv)`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.gap &&
    css`
      ${MediumGapStyle}
    `}
`;

export const PromptDialTitleText = styled(MediumDarkExtraSmallText)`
  line-height: 1.2;
  min-height: 20px;
  text-align: ${(props) => props.align && `${props.align}`};
`;

export const PromptDialElementContainer = styled.div`
  min-height: 70px;
  ${CenterFlexStyle}
  width: 100%;
`;

export const SmallPromptInput = styled(Input).attrs((props) => ({
  removeGap: !props.smallGap,
  useExtraSmallGap: props.smallGap,
  fixedHeight: 50,
  useSmallText: true,
  useExtraSmallWidth: true,
}))``;

export const PromptDialSubtitleText = styled(LightDarkTinyText)`
  text-align: ${(props) => props.align && `${props.align}`};
  line-height: 1.2;
  word-wrap: break-word;
  min-height: 50px;
`;

export const SmallListOptionsContainer = styled(ColumnDiv)`
  justify-content: flex-start;
`;

const DirectlyBookableIconComponent = ({ agentName }) => (
  <Tooltip
    title={
      <TooltipTitleText>
        {`${agentName} can book directly into your calendar`}
      </TooltipTitleText>
    }
    placement='bottom'
  >
    <DirectlyBookableIcon />
  </Tooltip>
);
const BookableThroughPackageIconComponent = () => (
  <Tooltip
    title={
      <TooltipTitleText>
        Only bookable as part of custom LiveIQ packages
      </TooltipTitleText>
    }
    placement='bottom'
  >
    <PackageIcon />
  </Tooltip>
);
const IndirectlyBookableIconComponent = () => (
  <Tooltip
    title={
      <TooltipTitleText>
        Clients must call the business to book
      </TooltipTitleText>
    }
    placement='bottom'
  >
    <IndirectlyBookableIcon />
  </Tooltip>
);
const RequiresConsultationIconComponent = ({ consultationName }) => (
  <Tooltip
    title={
      <TooltipTitleText>
        {`Requires consultation '${consultationName}' before booking`}
      </TooltipTitleText>
    }
    placement='bottom'
  >
    <RequiresConsultationIcon />
  </Tooltip>
);
const SourcePackageIconComponent = () => (
  <Tooltip
    title={<TooltipTitleText>Source package</TooltipTitleText>}
    placement='bottom'
  >
    <PackageIcon />
  </Tooltip>
);
const UnbookableIconComponent = () => (
  <Tooltip
    title={<TooltipTitleText>Unbookable</TooltipTitleText>}
    placement='bottom'
  >
    <UnbookableServiceIcon />
  </Tooltip>
);

export const getBookableIcon = (status, agentName, consultationName) => {
  return status === directlyBookableSessionTypeStatus ? (
    <DirectlyBookableIconComponent agentName={agentName} />
  ) : status === indirectlyBookableSessionTypeStatus ? (
    <IndirectlyBookableIconComponent />
  ) : status === requiresConsultationBookableSessionTypeStatus ? (
    <RequiresConsultationIconComponent consultationName={consultationName} />
  ) : status === bookableThroughPackageSessionTypeStatus ? (
    <BookableThroughPackageIconComponent />
  ) : status === sourcePackageBookableSessionTypeStatus ? (
    <SourcePackageIconComponent />
  ) : (
    <UnbookableIconComponent />
  );
};

export const EditingModeComponent = styled.div`
  border-radius: 50%;
  border: 1px solid
    ${(props) =>
      props.mode === locationEditingMode
        ? props.theme.color.lightPrimary
        : props.mode === portfolioEditingMode
        ? props.theme.color.lightOrange
        : props.theme.color.turquoise};
  ${CenterFlexStyle}
  padding: 2px;
  cursor: pointer;
  height: 27px;
  width: 27px;
  margin-left: 3px;
  position: relative;
`;

export const LocationIcon = styled(LocationOnIcon)`
  color: ${({ theme }) => theme.color.lightPrimary};
  height: ${(props) => (props.small ? '20px' : '30px')};
`;

export const PortfolioIcon = styled(StorefrontIcon)`
  color: ${({ theme }) => theme.color.orange};
  height: ${(props) => (props.small ? '20px' : '21px')};
`;

export const CorporationIcon = styled(BusinessIcon)`
  color: ${({ theme }) => theme.color.turquoise};
  height: ${(props) => (props.small ? '20px' : '22px')};
`;

export const locationEditingMode = 'location';
export const portfolioEditingMode = 'portfolio';
export const corporationEditingMode = 'corporation';

export const OverhangTextContainer = styled.div`
  position: absolute;
  top: -13px;
  background-color: white;
  border: 1px solid
    ${(props) =>
      props.mode === locationEditingMode
        ? props.theme.color.lightPrimary
        : props.mode === portfolioEditingMode
        ? props.theme.color.lightOrange
        : props.theme.color.turquoise};
  border-radius: 8px;
  width: max-content;
  transform: translateX(56%);
  padding: 2px 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
