import { getWhiteLabelId, whiteLabelConfigMap } from '../utils/white-label';

const whiteLabelId = getWhiteLabelId();

const whiteLabelColorScheme = whiteLabelId
  ? whiteLabelConfigMap[whiteLabelId]?.color
  : null;

const color = {
  primary: whiteLabelColorScheme ? whiteLabelColorScheme.primary : '#7161a0',
  lightPrimary: whiteLabelColorScheme
    ? whiteLabelColorScheme.lightPrimary
    : '#9c90bd',
  primaryHover: whiteLabelColorScheme
    ? whiteLabelColorScheme.primaryHover
    : '#8d81b3',
  lightPrimaryRgba: 'rgba(156, 144, 189, 0.8)',
  primaryHoverRgba: 'rgb(141, 129, 179, 0.8)',
  superLightPrimary: '#cec8de',
  superDuperLightPrimary: '#e2deeb',
  secondary: 'rgb(58, 133, 136)',
  secondaryRgba: 'rgba(58, 133, 136,0.6)',
  secondaryHover: 'rgba(58, 133, 136, 0.8)',
  lightSecondary: '#83b0b6',
  lightSecondaryRgba: 'rgba(131, 176, 182, 0.3)',
  superLightSecondary: '#cddfe2',
  superDuperLightSecondary: '#e7f0f1',
  lightText: '#77716f',
  mediumText: 'rgba(58, 53, 65, 0.83)',
  mediumDarkText: '#645a56',
  lightDarkText: '#847e7b',
  error: '#d8504d',
  lightError: '#FF8488',
  mediumLightError: '#e6afb0',
  superLightError: '#e6afb0',
  warning: '#eabb9f',
  incomplete: '#df8885',
  disabled: 'rgba(0, 0, 0, 0.12)',
  disabledText: 'rgba(0, 0, 0, 0.3)',
  darkText: '#504a43',
  gold: '#F9e77e',
  fadedGold: '#fdf5cb',
  fadedGoldRgba: 'rgba(253, 245, 203, 0.1)',
  darkGold: '#706213',
  darkGray: '#4a4a4a',
  gray: '#6c6c73',
  defaultGray: '#b9b9b9',
  lightGray: 'rgb(245, 245, 245)',
  superLightGray: '#f4f4f3',
  lightHover: '#f5f5f5',
  muiInput: '#cbcbcb',
  muiInputLightGray: '#eaeaea',
  veryLightGray: '#f2f2f2',
  muiInputLightDarkGray: '#c6c6c6',
  muiInputDarkGray: '#717171',
  brightGreen: 'rgb(37, 179, 77)',
  brightGreenRgba: 'rgba(37, 179, 77, 0.4)',
  deepGreen: '#61a362',
  lightGreen: '#66ca82',
  offwhite: '#fafafa',
  pink: '#f69ec4',
  peach: '#f9dd94',
  blue: '#92cbdf',
  deepBlue: '#2884fc',
  lessDeepBlue: '#539dfd',
  lightBlue: '#8fbece',
  darkBlue: '#83b7c9',
  darkerBlue: '#425c65',
  lightPeach: '#Fbf1e7',
  underReview: '#f2d3ad',
  orange: '#D49A80',
  lightOrange: '#ffbc74',
  quickbooks: '#2CA01C',
  turquoise: '#80c3c6',
  turquoiseRgba: '#cfeff1',
  darkTurquoise: '#0897a0',
  darkTeal: '#004d4d',
  // Light palette
  lightPalettePurple: 'rgba(198,192,217,1)',
  lightPaletteGreen: 'rgba(205,223,226,1)',
  charcoal: '#2f2f2f',
  lightCharcoal: '#4f4f4f',
};

export default color;
