import styled from 'styled-components';

export const ContactPhoneNumberChatContainer = styled.div`
  border-radius: 10px;
  border: 1px solid
    ${(props) =>
      props.complete
        ? props.theme.color.brightGreen
        : props.theme.color.lightPrimary};
  width: fit-content;
  padding: 3px 10px;
  margin-top: 9px;
  cursor: pointer;
  margin-bottom: 7px;
`;
