import { useMutation } from '@apollo/client';
import React, { useContext } from 'react';
import { MODIFY_CHAT_STAR } from '../../../api/mutations/chat';
import {
  AgentIcon,
  AppointmentBookedChip,
  AppointmentBookedIcon,
  AppointmentBookingMonitoringIcon,
  AppointmentWithNoteIcon,
  BaseChipText,
  DisengagedIcon,
  EndAlignedFlexDiv,
  ErrorTextExclamationIcon,
  LightDarkSuperTinyText,
  LightExtraTinyText,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import { getChatAppointmentStatus } from '../../../utils/chat';
import {
  appointmentBookedStatus,
  appointmentDepositRequiredStatus,
  appointmentMonitoringStatus,
  bookingSystemWebDemoChatMediumKey,
  chatDisengagementReasonLabelMap,
  chatInterceptionReasonLabelMap,
  completeStatusKey,
  disengagedChatStatusKey,
  emptyArrayValue,
  emptyStringValue,
  fakeNames,
  internalContactType,
  resolutionStatusMap,
} from '../../../utils/constants';
import { getTimestamp } from '../../../utils/date';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import {
  BookingSystemIconContainer,
  BookingSystemTestIcon,
  ChatCardContainer,
  ClientNameText,
  DemoIcon,
  DemoIconContainer,
  EndChatCardContainer,
  FilledStarIcon,
  InternalIcon,
  InternalIconContainer,
  LeftSideContainer,
  RightSideContainer,
  UnfilledStarIcon,
} from './styled';

const displayIndividualIcons = false;

const ChatCard = ({ chat, idx, position, onClick, onModifyStar, selected }) => {
  const { inDemoMode } = useContext(BaseContext);

  const [modifyChatStarMutation] = useMutation(MODIFY_CHAT_STAR);

  const totalMessages = chat.numTotalMessages;
  const totalCalls = chat.callIds?.filter(
    (id) => id !== emptyArrayValue,
  ).length;
  const messageText = `${totalMessages} message${
    totalMessages !== 1 ? 's' : ''
  }`;
  const callText = `${totalCalls} call${totalCalls !== 1 ? 's' : ''}`;

  const countText =
    totalMessages && totalCalls
      ? `${messageText}, ${callText}`
      : totalMessages
      ? messageText
      : totalCalls
      ? callText
      : '';

  let clientFirstName = chat.userFirstName;
  let clientLastName = chat.userLastName;
  if (inDemoMode && idx < fakeNames.length) {
    clientFirstName = clientFirstName?.[0];
    clientLastName = clientLastName?.[0];
  }
  const phoneNumber = chat.userPhone;
  const lastMessageSentAt = getTimestamp(chat.lastMessageSentAt, false, true);

  // Make the top chat a prod chat
  const isBookingSystemTest = chat.medium === bookingSystemWebDemoChatMediumKey;
  const isDemoChat = chat.medium.includes('demo');
  const isInternalChat =
    chat.userType === internalContactType || chat.type === 'reminders';
  const chatStatus = chat.status;

  const { appointmentStatus, count } = getChatAppointmentStatus(
    chat?.appointmentStatuses,
  );
  const interceptionReason = chat?.interceptionReason;
  const disengagementReason = chat?.disengagementReason;
  const starredByUserId = chat?.starredByUserId;
  const starred = starredByUserId && starredByUserId !== emptyStringValue;

  const resolutionStatus = chat?.resolutionStatus;
  const unresolved =
    ![emptyStringValue, completeStatusKey].includes(resolutionStatus) &&
    resolutionStatus;

  const onClickStar = () => {
    modifyChatStarMutation({
      variables: {
        id: chat.id,
        star: !starred,
      },
      onCompleted: async (data) => {
        onModifyStar();
      },
    });
  };

  return (
    <ChatCardContainer
      position={position}
      onClick={onClick}
      selected={selected}
    >
      <LeftSideContainer>
        <ClientNameText
          selected={selected}
          capitalize
        >
          {inDemoMode
            ? `${clientFirstName}.${clientLastName}.`
            : getFormattedFullNameFromUser(
                {
                  firstName: clientFirstName,
                  lastName: clientLastName,
                },
                false,
                null,
                phoneNumber,
              )}
        </ClientNameText>
        <LightExtraTinyText>{countText}</LightExtraTinyText>
      </LeftSideContainer>
      <RightSideContainer>
        {displayIndividualIcons ? (
          <>
            {appointmentStatus === appointmentBookedStatus ? (
              <Tooltip
                title={
                  <TooltipTitleText>
                    {count > 1
                      ? `${count} Appointments Booked`
                      : `Appointment Booked`}
                  </TooltipTitleText>
                }
                placement='top'
              >
                <div style={{ position: 'relative', height: '100%' }}>
                  <AppointmentBookedIcon />
                  {count > 1 && (
                    <AppointmentBookedChip>
                      <BaseChipText>{count}</BaseChipText>
                    </AppointmentBookedChip>
                  )}
                </div>
              </Tooltip>
            ) : appointmentStatus === appointmentDepositRequiredStatus ? (
              <Tooltip
                title={
                  <TooltipTitleText>
                    Appointment requires deposit
                  </TooltipTitleText>
                }
                placement='top'
              >
                <AppointmentWithNoteIcon selected={selected} />
              </Tooltip>
            ) : appointmentStatus === appointmentMonitoringStatus ? (
              <Tooltip
                title={
                  <TooltipTitleText>
                    Monitoring potential booking
                  </TooltipTitleText>
                }
                placement='top'
              >
                <span>
                  <AppointmentBookingMonitoringIcon
                    selected={selected}
                    topMargin={2}
                  />
                </span>
              </Tooltip>
            ) : interceptionReason !== emptyStringValue && !inDemoMode ? (
              <Tooltip
                title={
                  <TooltipTitleText>
                    Intercepted:{' '}
                    {chatInterceptionReasonLabelMap[interceptionReason]}
                  </TooltipTitleText>
                }
                placement='top'
              >
                <AgentIcon
                  selected={selected}
                  topMargin={-1}
                />
              </Tooltip>
            ) : (disengagementReason !== emptyStringValue ||
                chatStatus === disengagedChatStatusKey) &&
              !inDemoMode ? (
              <Tooltip
                title={
                  <TooltipTitleText>
                    Disengaged
                    {disengagementReason !== emptyStringValue
                      ? `: ${chatDisengagementReasonLabelMap[disengagementReason]}`
                      : ''}
                  </TooltipTitleText>
                }
                placement='top'
              >
                <DisengagedIcon
                  selected={selected}
                  topMargin={-1}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </>
        ) : (
          <EndChatCardContainer>
            <LightDarkSuperTinyText>{lastMessageSentAt}</LightDarkSuperTinyText>
            <EndAlignedFlexDiv gap={3}>
              {unresolved && (
                <Tooltip
                  title={
                    <TooltipTitleText>
                      Unresolved - {resolutionStatusMap[resolutionStatus]}
                    </TooltipTitleText>
                  }
                  placement='top'
                >
                  <ErrorTextExclamationIcon
                    large
                    rightMargin={-6}
                    topMargin={2}
                  />
                </Tooltip>
              )}
              {isBookingSystemTest ? (
                <BookingSystemIconContainer>
                  <Tooltip
                    title={
                      <TooltipTitleText>
                        Booking system test chat
                      </TooltipTitleText>
                    }
                    placement='top'
                  >
                    <BookingSystemTestIcon />
                  </Tooltip>
                </BookingSystemIconContainer>
              ) : isDemoChat ? (
                <DemoIconContainer>
                  <Tooltip
                    title={<TooltipTitleText>Demo chat</TooltipTitleText>}
                    placement='top'
                  >
                    <DemoIcon />
                  </Tooltip>
                </DemoIconContainer>
              ) : isInternalChat ? (
                <InternalIconContainer>
                  <Tooltip
                    title={<TooltipTitleText>Internal chat</TooltipTitleText>}
                    placement='top'
                  >
                    <InternalIcon />
                  </Tooltip>
                </InternalIconContainer>
              ) : (
                <></>
              )}
              {starred ? (
                <FilledStarIcon
                  onClick={(e) => {
                    e.preventDefault();
                    onClickStar();
                  }}
                />
              ) : (
                <UnfilledStarIcon
                  selected={selected}
                  topMargin={-1}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickStar();
                  }}
                />
              )}
            </EndAlignedFlexDiv>
          </EndChatCardContainer>
        )}
      </RightSideContainer>
    </ChatCardContainer>
  );
};

export default ChatCard;
