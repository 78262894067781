import { default as React, useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCampaignLandingPage } from '../../../../api/hooks/campaigns';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import Header from '../../../../components/NavBar/Header';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import Paths from '../../../../Paths';
import {
  CenteredDivWithLargerGap,
  ContentContainer,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  PageContainer,
  PageHeaderContainer,
  PageSubtitleText,
  PageTitleBottomBorderContainer,
  ScrollWrapper,
  SecondaryBackIcon,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import { getUserLocationId } from '../../../../utils/user';

const EditLandingPage = () => {
  const navigate = useNavigate();
  const { user, drawerOpen, drawerExpanded, inDemoMode, cookies, setCookie } =
    useContext(BaseContext);
  const locationId = getUserLocationId(user);

  const contentContainerRef = useRef();

  const { landingPageId } = useParams();
  const { landingPage, loading } = useCampaignLandingPage({
    id: landingPageId,
  });

  const [slug, setSlug] = useState('');

  const resetEditingData = () => {
    setSlug();
  };

  const onClickBack = () => {
    resetEditingData();
    navigate(Paths.campaigns);
  };

  const onSave = () => {};

  const saveEnabled = true;

  return (
    <>
      <MetaSetter
        title={`Edit Promo`}
        description={`Edit Promo`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        removeAllTopMargin
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
          fullWidth
          hideOverflow
          removeGap
          topPadding={0}
          center
        >
          <PageHeaderContainer
            ref={contentContainerRef}
            fixed
          >
            <CenteredDivWithLargerGap>
              <Tooltip
                title={<TooltipTitleText>Back to campaigns</TooltipTitleText>}
                placement='left'
              >
                <SecondaryBackIcon onClick={() => onClickBack()} />
              </Tooltip>
              <PageSubtitleText>Editing Campaign LandingPage</PageSubtitleText>
            </CenteredDivWithLargerGap>
            <PageTitleBottomBorderContainer />
          </PageHeaderContainer>
          <ScrollWrapper topPadding={80}>
            <MediumGapColumnCenteredDiv>
              <CenteredDivWithLargerGap topMargin={20}>
                <MediumPrimaryButton
                  disabled={!saveEnabled}
                  onClick={() => onSave()}
                >
                  Save
                </MediumPrimaryButton>
                <MediumSecondaryButton onClick={() => onClickBack()}>
                  Cancel
                </MediumSecondaryButton>
              </CenteredDivWithLargerGap>
            </MediumGapColumnCenteredDiv>
          </ScrollWrapper>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default EditLandingPage;
