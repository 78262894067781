import { sortBy } from 'lodash';
import React from 'react';
import {
  MediumDarkExtraSmallText,
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
  SmallCopyIcon,
  SmallPrimaryButton,
} from '../../../styles/shared-styled-components';
import { getTimestamp } from '../../../utils/date';
import { copyTextToClipboard, formatKeyToLabel } from '../../../utils/string';

const createTypeKey = 'create';
const rescheduleTypeKey = 'reschedule';
const cancelTypeKey = 'cancel';
const confirmationTypeKey = 'confirmation';
const messageTypeLabelMap = {
  [createTypeKey]: 'Appointment Created',
  [rescheduleTypeKey]: 'Appointment Rescheduled',
  [cancelTypeKey]: 'Appointment Cancelled',
  [confirmationTypeKey]: 'Appointment Confirmation',
};

const CalendarAppointmentModal = ({ isOpen, onClose, appointment }) => {
  const handleClose = () => {
    onClose();
  };

  const clientName = appointment?.client?.name || 'Unknown client';
  const status = formatKeyToLabel(appointment?.status);
  const metadata = appointment?.metadata;
  const sentForParent = metadata?.alreadySentForId;
  const updateTimestamps = Object.keys(metadata?.updates || {}) || [];
  const messages = [];
  if (metadata?.confirmationMessage) {
    messages.push({
      type: confirmationTypeKey,
      message: metadata.confirmationMessage,
      sentAt: metadata.confirmationMessageSentAt,
    });
  }
  updateTimestamps.map((ts) => {
    const update = metadata.updates[ts];
    const action = update.action;
    messages.push({
      type: action,
      message: update.message,
      sentAt: ts,
    });
  });
  const sortedMessages = sortBy(messages, (m) => m.sentAt);
  console.log('a', appointment);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>
          Appointment{' '}
          <SmallCopyIcon
            onClick={() => copyTextToClipboard(appointment.sourceId)}
          />
        </MLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper>
            <MediumDarkExtraSmallText>
              {clientName} - {status}
              <br></br>
              {appointment?.time}
              <br></br>
              Booked {getTimestamp(appointment?.createdAt, false, false, true)}
              <br></br>
              <br></br>
              Message Log:
              {sortedMessages?.length ? (
                <>
                  {sortedMessages.map((m) => {
                    const message = m.message;
                    const timestamp = message
                      ? getTimestamp(m.sentAt, false, false, true)
                      : '';
                    const label = messageTypeLabelMap[m.type];
                    return (
                      <>
                        <br></br>
                        {label}:{' '}
                        {message
                          ? `"${m.message}" (Sent ${timestamp})`
                          : sentForParent
                          ? 'Processed through parent appointment'
                          : 'No message sent'}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <br></br>
                  {metadata?.alreadySentForId
                    ? 'Sent for parent of appointment group'
                    : 'None sent'}
                </>
              )}
            </MediumDarkExtraSmallText>
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions>
          <SmallPrimaryButton onClick={() => handleClose()}>
            Close
          </SmallPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default CalendarAppointmentModal;
