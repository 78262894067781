import { default as React, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import Header from '../../../../components/NavBar/Header';
import Snackbar from '../../../../components/Snackbar';
import Paths from '../../../../Paths';
import {
  ColumnCenteredDiv,
  ContentContainer,
  ExtraSmallSecondaryButton,
  FullSizeColumnCenteredDiv,
  LightDarkEssText,
  MediumDarkEssText,
  MediumNoDataIcon,
  PageContainer,
  PageTitleText,
  SmallAddIcon,
  StartAlignedFlexDiv,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  completeStatusKey,
  inProgressStatusKey,
  metricsLabelObject,
  upcomingStatusKey,
} from '../../../../utils/constants';
import { dateToTextFormat } from '../../../../utils/date';
import {
  CreatedGoalsContainer,
  DeleteGoalCardActionIconContainer,
  DeleteGoalIcon,
  EditGoalIcon,
  GoalCard,
  GoalCardActionIconContainer,
  GoalCardActionsContainer,
  GoalCardFieldContainer,
  GoalCardFieldTitleText,
  GoalCardTitleText,
  SwimLane,
  SwimLaneCardsContainer,
  SwimLaneTitleContainer,
  SwimLaneTitlesContainer,
  SwimLanesContainer,
  TrackGoalProgressIcon,
} from './styled';

const EmptyDataComponent = (
  <FullSizeColumnCenteredDiv topMargin={20}>
    <MediumNoDataIcon />
    <LightDarkEssText>No requests</LightDarkEssText>
  </FullSizeColumnCenteredDiv>
);

const Feedback = () => {
  const navigate = useNavigate();
  const { user, drawerOpen, drawerExpanded, fullyConnected, width } =
    useContext(BaseContext);

  const goals = [];

  const upcomingGoals = goals?.filter(
    (g) => g.status === upcomingStatusKey || g.status == null,
  );
  const inProgressGoals = goals?.filter(
    (g) => g.status === inProgressStatusKey,
  );
  const completedGoals = goals?.filter((g) => g.status === completeStatusKey);

  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const onDeleteGoal = (id) => {};

  const onClickNavigateToGoal = (goalId) => {
    navigate(Paths.editGoal.replace(':goalId', goalId));
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          {loading ? (
            <LoadingIndicator fullScreen />
          ) : (
            <>
              <ColumnCenteredDiv>
                <PageTitleText>Agent Feedback</PageTitleText>
              </ColumnCenteredDiv>
              <CreatedGoalsContainer>
                <StartAlignedFlexDiv>
                  <ExtraSmallSecondaryButton mediumPadding>
                    <SmallAddIcon /> Create feedback request
                  </ExtraSmallSecondaryButton>
                </StartAlignedFlexDiv>
                <SwimLaneTitlesContainer>
                  <SwimLaneTitleContainer start>
                    <MediumDarkEssText>
                      Upcoming ({upcomingGoals.length})
                    </MediumDarkEssText>
                  </SwimLaneTitleContainer>
                  <SwimLaneTitleContainer>
                    <MediumDarkEssText>
                      In Progress ({inProgressGoals.length})
                    </MediumDarkEssText>
                  </SwimLaneTitleContainer>
                  <SwimLaneTitleContainer end>
                    <MediumDarkEssText>
                      Completed ({completedGoals.length})
                    </MediumDarkEssText>
                  </SwimLaneTitleContainer>
                </SwimLaneTitlesContainer>
                <SwimLanesContainer>
                  <SwimLane start>
                    <SwimLaneCardsContainer>
                      {upcomingGoals?.length ? (
                        <>
                          {upcomingGoals.map((g) => {
                            const goalMetricNames = g?.conditions?.map(
                              (c) => metricsLabelObject[c.metricName],
                            );
                            const numMetrics = goalMetricNames?.length;
                            const metricString =
                              numMetrics > 0
                                ? goalMetricNames?.join(', ')
                                : 'None selected';

                            const dueDateString = g.dueDate
                              ? dateToTextFormat(g.dueDate)
                              : 'None selected';

                            return (
                              <GoalCard start>
                                <GoalCardTitleText
                                  onClick={() => onClickNavigateToGoal(g.id)}
                                >
                                  {g.name || 'Untitled'}
                                </GoalCardTitleText>
                                <GoalCardFieldContainer>
                                  <GoalCardFieldTitleText>
                                    Metric{numMetrics > 1 && 's'}:
                                  </GoalCardFieldTitleText>
                                  <MediumDarkEssText>
                                    {metricString}
                                  </MediumDarkEssText>
                                </GoalCardFieldContainer>
                                <GoalCardFieldContainer>
                                  <GoalCardFieldTitleText>
                                    End Date:
                                  </GoalCardFieldTitleText>
                                  <MediumDarkEssText>
                                    {dueDateString}
                                  </MediumDarkEssText>
                                </GoalCardFieldContainer>
                                <GoalCardActionsContainer>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Track Progress
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <GoalCardActionIconContainer>
                                      <TrackGoalProgressIcon />
                                    </GoalCardActionIconContainer>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>Edit</TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <GoalCardActionIconContainer
                                      onClick={() =>
                                        onClickNavigateToGoal(g.id)
                                      }
                                    >
                                      <EditGoalIcon />
                                    </GoalCardActionIconContainer>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Delete
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <DeleteGoalCardActionIconContainer
                                      onClick={() => onDeleteGoal(g.id)}
                                    >
                                      <DeleteGoalIcon />
                                    </DeleteGoalCardActionIconContainer>
                                  </Tooltip>
                                </GoalCardActionsContainer>
                              </GoalCard>
                            );
                          })}
                        </>
                      ) : (
                        EmptyDataComponent
                      )}
                    </SwimLaneCardsContainer>
                  </SwimLane>
                  <SwimLane>
                    <SwimLaneCardsContainer>
                      {inProgressGoals?.length ? (
                        <>
                          {inProgressGoals.map((g) => {
                            const goalMetricNames = g?.conditions?.map(
                              (c) => metricsLabelObject[c.metricName],
                            );
                            const numMetrics = goalMetricNames?.length;
                            const metricString =
                              numMetrics > 0
                                ? goalMetricNames?.join(', ')
                                : 'None selected';

                            const dueDateString = g.dueDate
                              ? dateToTextFormat(g.dueDate)
                              : 'None selected';

                            return (
                              <GoalCard>
                                <GoalCardTitleText
                                  onClick={() => onClickNavigateToGoal(g.id)}
                                >
                                  {g.name || 'Untitled'}
                                </GoalCardTitleText>
                                <GoalCardFieldContainer>
                                  <GoalCardFieldTitleText>
                                    Metric{numMetrics > 1 && 's'}:
                                  </GoalCardFieldTitleText>
                                  <MediumDarkEssText>
                                    {metricString}
                                  </MediumDarkEssText>
                                </GoalCardFieldContainer>
                                <GoalCardFieldContainer>
                                  <GoalCardFieldTitleText>
                                    End Date:
                                  </GoalCardFieldTitleText>
                                  <MediumDarkEssText>
                                    {dueDateString}
                                  </MediumDarkEssText>
                                </GoalCardFieldContainer>
                                <GoalCardActionsContainer>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Track Progress
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <GoalCardActionIconContainer>
                                      <TrackGoalProgressIcon />
                                    </GoalCardActionIconContainer>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>Edit</TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <GoalCardActionIconContainer
                                      onClick={() =>
                                        onClickNavigateToGoal(g.id)
                                      }
                                    >
                                      <EditGoalIcon />
                                    </GoalCardActionIconContainer>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Delete
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <DeleteGoalCardActionIconContainer
                                      onClick={() => onDeleteGoal(g.id)}
                                    >
                                      <DeleteGoalIcon />
                                    </DeleteGoalCardActionIconContainer>
                                  </Tooltip>
                                </GoalCardActionsContainer>
                              </GoalCard>
                            );
                          })}
                        </>
                      ) : (
                        EmptyDataComponent
                      )}
                    </SwimLaneCardsContainer>
                  </SwimLane>
                  <SwimLane end>
                    <SwimLaneCardsContainer>
                      {completedGoals?.length ? (
                        <>
                          {completedGoals.map((g) => {
                            const goalMetricNames = g?.conditions?.map(
                              (c) => metricsLabelObject[c.metricName],
                            );
                            const numMetrics = goalMetricNames?.length;
                            const metricString =
                              numMetrics > 0
                                ? goalMetricNames?.join(', ')
                                : 'None selected';

                            const dueDateString = g.dueDate
                              ? dateToTextFormat(g.dueDate)
                              : 'None selected';

                            return (
                              <GoalCard end>
                                <GoalCardTitleText
                                  onClick={() => onClickNavigateToGoal(g.id)}
                                >
                                  {g.name || 'Untitled'}
                                </GoalCardTitleText>
                                <GoalCardFieldContainer>
                                  <GoalCardFieldTitleText>
                                    Metric{numMetrics > 1 && 's'}:
                                  </GoalCardFieldTitleText>
                                  <MediumDarkEssText>
                                    {metricString}
                                  </MediumDarkEssText>
                                </GoalCardFieldContainer>
                                <GoalCardFieldContainer>
                                  <GoalCardFieldTitleText>
                                    End Date:
                                  </GoalCardFieldTitleText>
                                  <MediumDarkEssText>
                                    {dueDateString}
                                  </MediumDarkEssText>
                                </GoalCardFieldContainer>
                                <GoalCardActionsContainer>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Track Progress
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <GoalCardActionIconContainer>
                                      <TrackGoalProgressIcon />
                                    </GoalCardActionIconContainer>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>Edit</TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <GoalCardActionIconContainer
                                      onClick={() =>
                                        onClickNavigateToGoal(g.id)
                                      }
                                    >
                                      <EditGoalIcon />
                                    </GoalCardActionIconContainer>
                                  </Tooltip>
                                  <Tooltip
                                    title={
                                      <TooltipTitleText>
                                        Delete
                                      </TooltipTitleText>
                                    }
                                    placement='bottom'
                                  >
                                    <DeleteGoalCardActionIconContainer
                                      onClick={() => onDeleteGoal(g.id)}
                                    >
                                      <DeleteGoalIcon />
                                    </DeleteGoalCardActionIconContainer>
                                  </Tooltip>
                                </GoalCardActionsContainer>
                              </GoalCard>
                            );
                          })}
                        </>
                      ) : (
                        EmptyDataComponent
                      )}
                    </SwimLaneCardsContainer>
                  </SwimLane>
                </SwimLanesContainer>
              </CreatedGoalsContainer>
            </>
          )}
        </ContentContainer>
      </PageContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  );
};

export default Feedback;
