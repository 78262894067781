import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useCalendarForDate } from '../../../../api/hooks/calendar';
import { BaseContext } from '../../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import CalendarAppointmentModal from '../../../../components/Modals/CalendarAppointmentModal/CalendarAppointmentModal';
import Header from '../../../../components/NavBar/Header';
import RangePopperMenuCalendar from '../../../../components/Schedule/Calendar/RangePopperMenuCalendar';
import Snackbar from '../../../../components/Snackbar';
import MetaSetter from '../../../../components/Utils/MetaSetter';
import {
  SmallTableRowText,
  TableHeaderText,
} from '../../../../pages-website/Homepage/styles/style-CompetitorComparison';
import {
  ColumnCenteredDiv,
  ContentContainer,
  EndAlignedFlexDiv,
  FullSizeColumnCenteredDiv,
  FullWidthEndAlignedDiv,
  HorizontallyScrollableDiv,
  ListEditIcon,
  MediumDarkTinyText,
  PageContainer,
  PageTitleText,
  Table,
  TableBody,
  TableCell,
  TableCellHeader,
  TableCellStack,
  TableHead,
  TableRow,
  Tooltip,
  TooltipTitleText,
} from '../../../../styles/shared-styled-components';
import {
  completeStatusKey,
  inProgressStatusKey,
  scheduledStatusKey,
} from '../../../../utils/constants';
import { getCookieExpiryObject } from '../../../../utils/date';
import { formatKeyToLabel } from '../../../../utils/string';
import { CalendarSection } from './styled';

const features = [
  {
    description: 'Deep Integrations',
    subtext: 'Booking system, CRM, data warehouse',
    liveiq: 'Connected to all your key systems',
    competitor: <>Often require a separate calendar&nbsp;&nbsp;❓</>,
  },
  {
    description: 'Powerful Customizations',
    subtext:
      'Service recommendations, deposits, cancellations, interaction style',
    competitor: <>Very limited customization offered&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Intelligent Upselling',
    subtext: 'Membership plans, add-ons, packages',
    competitor: <>Very limited customization offered&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Omni-Channel',
    subtext: 'Phone calls, texts, email, social media',
    competitor: <>Usually just focused on texts&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Flexible Campaigns',
    subtext:
      'Book new appointments, get reviews post-visit, dynamically fill cancellations',
    competitor: <>Typically only handle inbound requests&nbsp;&nbsp;❌</>,
  },
  {
    description: 'Seamless Human Interaction',
    subtext: 'Intelligent escalation, simple interception',
    competitor: <>Sometimes offered&nbsp;&nbsp;❓</>,
  },
  {
    description: 'Multi-Location Tools',
    subtext: 'Analytics, templates, white-labelling',
    competitor: <>Not offered&nbsp;&nbsp;❌</>,
  },
];

const slotDuration = 30;
const slotMap = {};
const slots = [
  { label: '7:00', minutes: 420 },
  { label: '7:30', minutes: 450 },
  { label: '8:00', minutes: 480 },
  { label: '8:30', minutes: 510 },
  { label: '9:00', minutes: 540 },
  { label: '9:30', minutes: 570 },
  { label: '10:00', minutes: 600 },
  { label: '10:30', minutes: 630 },
  { label: '11:00', minutes: 660 },
  { label: '11:30', minutes: 690 },
  { label: '12:00', minutes: 720 },
  { label: '12:30', minutes: 750 },
  { label: '1:00', minutes: 780 },
  { label: '1:30', minutes: 810 },
  { label: '2:00', minutes: 840 },
  { label: '2:30', minutes: 870 },
  { label: '3:00', minutes: 900 },
  { label: '3:30', minutes: 930 },
  { label: '4:00', minutes: 960 },
  { label: '4:30', minutes: 990 },
  { label: '5:00', minutes: 1020 },
  { label: '5:30', minutes: 1050 },
  { label: '6:00', minutes: 1080 },
  { label: '6:30', minutes: 1110 },
  { label: '7:00', minutes: 1140 },
  { label: '7:30', minutes: 1170 },
  { label: '8:00', minutes: 1200 },
];
slots.map((s) => {
  slotMap[s.minutes] = { label: s.label, key: s.minutes, appts: [] };
});
const numSlots = slots.length;

const dateCookieKey = 'calendar_date_cookie_key';
const cookieExpiry = getCookieExpiryObject();

const baseDate = new Date();

const Calendar = () => {
  const { cookies, setCookie, drawerOpen, drawerExpanded } =
    useContext(BaseContext);

  const storedDateCookie = cookies[dateCookieKey] || baseDate;
  const storedDateCookieString = new Date(storedDateCookie).toISOString();

  const [date, setDate] = useState(storedDateCookie);
  const [appointments, setAppointments] = useState([]);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [appointmentViewing, setAppointmentViewing] = useState();
  const [dateMenuAnchorEl, setDateMenuAnchorEl] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dateMenuOpen = Boolean(dateMenuAnchorEl);
  const formattedDate = new Date(date).toISOString();

  const { data: calendar, loading } = useCalendarForDate({
    date: formattedDate,
  });

  let providers = sortBy(Object.keys(calendar || {}), (p) =>
    p && p !== 'undefined' ? 0 : 1,
  );
  const numProviders = providers.length;
  const providerString = providers.join(',');

  const onChangeDate = (updatedDate) => {
    setCookie(dateCookieKey, updatedDate, cookieExpiry);
  };

  useEffect(() => {
    setDate(storedDateCookie);
  }, [storedDateCookieString]);

  useEffect(() => {
    if (calendar) {
      const appointmentMap = {};
      providers.map((p) => {
        const staffName = p && p !== 'undefined' ? p : 'Unknown provider';
        const appts = calendar[p];
        const providerMap = cloneDeep(slotMap);
        appts.map((a) => {
          const appointmentStart = a.minutesSinceMidnight;
          const duration = a.durationMinutes;
          const appointmentEnd = appointmentStart + duration;

          let included = false;
          slots.map((s) => {
            const slotStart = s.minutes;
            const slotEnd = s.minutes + slotDuration;
            if (
              (appointmentStart >= slotStart &&
                (appointmentEnd <= slotEnd || appointmentStart < slotEnd)) ||
              (appointmentEnd >= slotEnd && appointmentEnd <= slotEnd) ||
              (appointmentStart <= slotStart &&
                (appointmentEnd >= slotEnd ||
                  (appointmentEnd > slotStart && appointmentEnd <= slotEnd)))
            ) {
              const startOffset =
                slotStart >= appointmentStart
                  ? 0
                  : ((appointmentStart - slotStart) / slotDuration) * 100;
              const endOffset =
                slotEnd <= appointmentEnd
                  ? 0
                  : ((slotEnd - appointmentEnd) / slotDuration) * 100;
              const appointmentToAttach = {
                ...a,
                included: !included ? true : false,
                startOffset,
                endOffset,
              };
              providerMap[slotStart]['appts'] = [
                ...(providerMap[slotStart]['appts'] || []),
                appointmentToAttach,
              ];
              included = true;
            }
          });
        });
        appointmentMap[staffName] = { slots: providerMap, name: staffName };
      });
      const staffAppointments = Object.values(appointmentMap);
      setAppointments(staffAppointments);
    }
  }, [providerString, date]);

  const contentContainerRef = useRef();
  const hasData = !!Object.keys(calendar || {}).length;
  const dateLabel = new Date(date).toDateString();

  return (
    <>
      <MetaSetter
        title={`Calendar`}
        description={`Calendar`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
        removeScroll
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
          ref={contentContainerRef}
          hideOverflow
        >
          <ColumnCenteredDiv>
            <PageTitleText>Calendar</PageTitleText>
          </ColumnCenteredDiv>
          <FullWidthEndAlignedDiv>
            {loading && (
              <>
                <LoadingIndicator size={20} />
              </>
            )}
            <EndAlignedFlexDiv
              gap={10}
              border
              onClick={(e) => setDateMenuAnchorEl(e.currentTarget)}
            >
              <MediumDarkTinyText>{dateLabel}</MediumDarkTinyText>
              <ListEditIcon />
            </EndAlignedFlexDiv>
          </FullWidthEndAlignedDiv>
          <CalendarSection>
            <RangePopperMenuCalendar
              start={date}
              range={false}
              onReset={() => {
                setDate();
              }}
              onSave={(updatedValue) => {
                onChangeDate(updatedValue);
                setDateMenuAnchorEl();
              }}
              open={dateMenuOpen}
              anchorElement={dateMenuAnchorEl}
              onClose={() => setDateMenuAnchorEl()}
            />
            <HorizontallyScrollableDiv>
              <Table>
                <TableHead>
                  <TableRow height={10}>
                    <TableCellHeader width={300}>
                      <TableHeaderText>Provider</TableHeaderText>
                    </TableCellHeader>
                    {slots.map((s, idx) => (
                      <TableCellHeader
                        width={95}
                        end={idx === numSlots - 1}
                      >
                        <MediumDarkTinyText>{s.label}</MediumDarkTinyText>
                      </TableCellHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hasData ? (
                    <>
                      {appointments.map((a, sidx) => {
                        const staffName = a.name;
                        const providerSlots = Object.values(a.slots);
                        return (
                          <TableRow height={40}>
                            <TableCell
                              width={300}
                              isLastRow={sidx === numProviders - 1}
                            >
                              <SmallTableRowText>{staffName}</SmallTableRowText>
                            </TableCell>
                            {providerSlots.map((s, idx) => {
                              const slotAppts = s.appts;
                              return (
                                <TableCell
                                  width={300}
                                  removePadding
                                  end={idx === numSlots - 1}
                                >
                                  <FullSizeColumnCenteredDiv>
                                    {slotAppts.map((a) => {
                                      const clientName =
                                        a?.client?.name || 'Unknown client';
                                      const shortenedName =
                                        clientName.length > 9
                                          ? `${clientName.slice(0, 6)}...`
                                          : clientName;
                                      const status = a.status;
                                      const color =
                                        status === scheduledStatusKey
                                          ? 'blue'
                                          : status === completeStatusKey
                                          ? 'green'
                                          : status === inProgressStatusKey
                                          ? 'orange'
                                          : 'red';
                                      const startOffset = a.startOffset;
                                      const endOffset = a.endOffset;
                                      return (
                                        <Tooltip
                                          title={
                                            <TooltipTitleText>
                                              {clientName} -
                                              {formatKeyToLabel(status)}
                                              <br></br>
                                              {a.time}
                                              <br></br>({a.durationMinutes}{' '}
                                              mins)
                                            </TooltipTitleText>
                                          }
                                        >
                                          <TableCellStack
                                            color={color}
                                            showText={a.included}
                                            startOffset={startOffset}
                                            endOffset={endOffset}
                                            onClick={() => {
                                              setAppointmentModalOpen(true);
                                              setAppointmentViewing(a);
                                            }}
                                          >
                                            {a.included ? shortenedName : '.'}
                                          </TableCellStack>
                                        </Tooltip>
                                      );
                                    })}
                                  </FullSizeColumnCenteredDiv>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <>No data</>
                  )}
                </TableBody>
              </Table>
            </HorizontallyScrollableDiv>
          </CalendarSection>
        </ContentContainer>
      </PageContainer>
      <CalendarAppointmentModal
        isOpen={appointmentModalOpen}
        onClose={() => setAppointmentModalOpen(false)}
        appointment={appointmentViewing}
      />
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        quick
      />
    </>
  );
};

export default Calendar;
