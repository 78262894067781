import { emptyStringValue } from './constants';

export const getWhiteLabelId = () => {
  const id = process.env.REACT_APP_WHITE_LABEL_ID;
  return !id || id?.toString() === emptyStringValue ? null : id;
};

const genericAgentAvatar =
  'https://storage.googleapis.com/liveiq-assets/partner-logos/whitelabel/generic-agent-avatar.avif';

export const whiteLabelConfigMap = {
  autobook: {
    company: 'Jump Forward Media',
    name: 'Autobook',
    title: 'Autobook by Jump Forward Media',
    url: 'https://jumpforwardmedia.com',
    tagline: 'AI that books your MedSpa leads on Auto-Pilot',
    subtitle: 'Built by the experts at Jump Forward Media',
    companyLogo: {
      url: 'https://storage.googleapis.com/liveiq-assets/partner-logos/whitelabel/jump-logo.png',
      height: 50,
      width: 50,
    },
    agentAvatar:
      'https://storage.googleapis.com/liveiq-assets/partner-logos/whitelabel/agent-avatar.png',
    logo: {
      url: '/autobook.jpeg',
      transparent: '/autobook-transparent.png',
      height: 50,
      width: 50,
      marginLeft: -7,
    },
    demo: {
      url: 'https://clients.jumpforwardmedia.com/book-with-hayden',
    },
    favicon: 'autobook-transparent.png',
    color: {
      primary: '#5483ab',
      lightPrimary: '#6c95bd',
      primaryHover: '#2E71A6',
    },
  },
  patient_rhino: {
    company: 'Patient Rhino',
    name: 'MedSpaAI',
    title: 'MedSpaAI',
    url: 'https://patientrhino.com/',
    tagline: 'AI custom-built to grow your MedSpa',
    subtitle: 'Built by the experts at Patient Rhino',
    companyLogo: {
      url: 'https://storage.googleapis.com/liveiq-assets/partner-logos/whitelabel/pr.png',
      height: 50,
      width: 100,
    },
    agentAvatar: genericAgentAvatar,
    logo: {
      url: '/medspa_ai-transparent.png',
      transparent: '/medspa_ai-transparent.png',
      height: 50,
      width: 100,
      marginLeft: -7,
      marginRight: 10,
    },
    demo: {
      url: 'https://patientrhino.com/',
    },
    favicon: 'medspa_ai-transparent.png',
    color: {
      primary: '#1b75bb',
      lightPrimary: '#3E9FEC',
      primaryHover: '#035FA8',
    },
  },
};
