import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  CenteredDivWithExtraSmallGap,
  CopyIdIcon,
  DarkSMText,
  SmallLogo,
  TinyText,
  Tooltip,
  TooltipTitleText,
} from '../../../styles/shared-styled-components';
import {
  businessOwnerRole,
  consultantRole,
  franchiseBusinessType,
  multiLocationBusinessType,
  multiLocationPrivilegedRoles,
  singleLocationBusinessType,
  superAdminRole,
} from '../../../utils/constants';
import { copyTextToClipboard } from '../../../utils/string';
import {
  getUserAgency,
  getUserBusiness,
  getUserCorporation,
  getUserLocation,
} from '../../../utils/user';
import {
  drawerExpandedKey,
  screenWidthIsIpadSizeOrSmaller,
  screenWidthIsSmallMobileSizeOrSmaller,
} from '../../../utils/view';
import { getWhiteLabelId } from '../../../utils/white-label';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoginButton from '../../Auth/LoginButton';
import SignupButton from '../../Auth/SignupButton';
import SelectLocationModal from '../../Modals/SelectLocationModal/SelectLocationModal';
import Drawer from '../Drawer';
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown';
import { ProfileMenuButtonPlaceholder } from '../ProfileDropdown/styled';
import {
  AgencyBadge,
  AppBar,
  AuthButtonsContainer,
  LogoContainer,
  SwitchBusinessIcon,
  Toolbar,
  ToolbarContainerLeft,
  ToolbarContainerRight,
  ToolbarDivider,
} from './styled';

const whiteLabelId = getWhiteLabelId();
const whiteLabelConfig = whiteLabelId ? Paths.whiteLabel[whiteLabelId] : null;
const homepagePath = whiteLabelConfig
  ? whiteLabelConfig['url']
  : Paths.homepage;

export default function Header({ blankMode = false, label = null }) {
  const {
    inDemoMode,
    inMonitoringMode,
    viewingAgency,
    width,
    user,
    userLoading,
    role,
    drawerOpen,
    drawerExpanded,
    isShapeShifter,
  } = useContext(BaseContext);
  const { pathname: pathName } = useLocation();
  const navigate = useNavigate();

  const isSmallMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width);
  const isIpadScreen = screenWidthIsIpadSizeOrSmaller(width);

  const [searchParams] = useSearchParams();

  const utm = searchParams?.get('utm');
  const selected = searchParams?.get('selected');
  let urlParams = utm ? `?utm=${utm}` : '';
  if (selected) {
    const selectedParam = `selected=${selected}`;
    if (utm) {
      urlParams += `&${selectedParam}`;
    } else {
      urlParams += `?selected=${selected}`;
    }
  }

  const corporation = getUserCorporation(user);
  const business = getUserBusiness(user);
  const isSuperAdmin = role === superAdminRole;
  const isShapeShifting = isShapeShifter && !isSuperAdmin;
  const isConsultant = role === consultantRole;
  const isMultiLocationBusiness = [
    multiLocationBusinessType,
    franchiseBusinessType,
  ].includes(business?.type);

  const onDashboard = pathName === Paths.dashboard;
  const location = getUserLocation(user);
  const agency = getUserAgency(user);
  const agencyId = agency?.id;
  const displayAgency =
    !onDashboard &&
    isSuperAdmin &&
    agency &&
    !inMonitoringMode &&
    !viewingAgency;
  const locationName = location?.name;
  const locationNickname = location?.nickname;
  const locationId = location?.id;

  const parseLocationNameString = () => {
    return onDashboard
      ? `Analytics View`
      : inMonitoringMode
      ? isSuperAdmin
        ? 'Super Admin View'
        : isConsultant
        ? 'Agency View'
        : ''
      : viewingAgency
      ? `Agency View - ${agency.name}`
      : business?.type === singleLocationBusinessType
      ? locationNickname || locationName
      : corporation
      ? `${corporation.name} - ${locationNickname || locationName}`
      : '';
  };

  const baseLocationNameString = parseLocationNameString();

  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);
  const [locationNameString, setLocationNameString] = useState(
    baseLocationNameString,
  );

  useEffect(() => {
    if (!userLoading && user) {
      // Determine the final location name string
      const newLocationNameString = parseLocationNameString();

      // Only update the state if we have new valid data to avoid unnecessary re-renders
      if (newLocationNameString !== locationNameString) {
        setLocationNameString(newLocationNameString);
      }
    }
  }, [userLoading, user, inDemoMode, inMonitoringMode]);

  const onLoginOrSignup = () => {
    const utmSource = searchParams.get('utm');

    if (utmSource) {
      localStorage.setItem('utm', utmSource);
    }
  };

  const onExpandDrawer = () => {
    localStorage.setItem(drawerExpandedKey, 'true');
  };

  const onCollapseDrawer = () => {
    localStorage.setItem(drawerExpandedKey, 'false');
  };

  const navigateToHomepage = () => {
    if (whiteLabelId) {
      window.location.href = homepagePath;
    } else {
      navigate(Paths.homepage);
    }
  };

  return !userLoading ? (
    <>
      <AppBar>
        <Toolbar>
          <ToolbarContainerLeft isMobileScreen={isIpadScreen}>
            {blankMode ? (
              <LogoContainer>
                <Tooltip
                  title={<TooltipTitleText>Back to homepage</TooltipTitleText>}
                >
                  <SmallLogo onClick={() => navigateToHomepage()} />
                </Tooltip>
                <DarkSMText>LiveIQ</DarkSMText>
              </LogoContainer>
            ) : locationNameString || isSuperAdmin ? (
              <>
                <LogoContainer>
                  <Tooltip
                    title={
                      <TooltipTitleText>Back to homepage</TooltipTitleText>
                    }
                  >
                    <SmallLogo onClick={() => navigateToHomepage()} />
                  </Tooltip>
                  <DarkSMText smallLineHeight>
                    {locationNameString || 'None selected'}
                  </DarkSMText>
                  {displayAgency && (
                    <Tooltip
                      title={
                        <TooltipTitleText>
                          This business is part of the {agency?.name} portfolio
                        </TooltipTitleText>
                      }
                    >
                      <AgencyBadge>
                        <TinyText inherit>{agency?.nickname}</TinyText>
                      </AgencyBadge>
                    </Tooltip>
                  )}
                </LogoContainer>
                {isShapeShifting && (
                  <Tooltip
                    title={<TooltipTitleText>ShapeShifting</TooltipTitleText>}
                  >
                    <TinyText>🥷</TinyText>
                  </Tooltip>
                )}
                {multiLocationPrivilegedRoles.includes(role) &&
                (role !== businessOwnerRole || isMultiLocationBusiness) ? (
                  <CenteredDivWithExtraSmallGap>
                    <Tooltip
                      title={
                        <TooltipTitleText>Select location</TooltipTitleText>
                      }
                      placement='bottom'
                    >
                      <SwitchBusinessIcon
                        onClick={() => setSelectLocationModalOpen(true)}
                      />
                    </Tooltip>
                    {isSuperAdmin && (
                      <Tooltip
                        title={
                          <TooltipTitleText>
                            Copy ID{' '}
                            {locationId
                              ? locationId?.slice(0, 4)
                              : agencyId?.slice(0, 4)}
                          </TooltipTitleText>
                        }
                        placement='bottom'
                      >
                        <CopyIdIcon
                          addLeftMargin
                          onClick={() =>
                            copyTextToClipboard(
                              locationId ? locationId : agencyId,
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </CenteredDivWithExtraSmallGap>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <LogoContainer>
                <Tooltip
                  title={<TooltipTitleText>Back to homepage</TooltipTitleText>}
                >
                  <SmallLogo onClick={() => navigate(Paths.homepage)} />
                </Tooltip>
                <DarkSMText>{locationNameString || 'None selected'}</DarkSMText>
              </LogoContainer>
            )}
          </ToolbarContainerLeft>
          {!label && !blankMode && (
            <ToolbarContainerRight>
              {!userLoading ? (
                <>
                  {!user && !isSmallMobileScreen ? (
                    <AuthButtonsContainer>
                      <SignupButton onClick={onLoginOrSignup} />
                      <LoginButton
                        onClick={onLoginOrSignup}
                        variant={'secondary'}
                      />
                    </AuthButtonsContainer>
                  ) : (
                    <ProfileDropdown />
                  )}
                </>
              ) : (
                <ProfileMenuButtonPlaceholder />
              )}
            </ToolbarContainerRight>
          )}
        </Toolbar>
      </AppBar>
      {!blankMode && (
        <Drawer
          open={drawerOpen}
          expanded={drawerExpanded}
          onExpand={onExpandDrawer}
          onCollapse={onCollapseDrawer}
          pathName={pathName}
        />
      )}
      <SelectLocationModal
        isOpen={selectLocationModalOpen}
        onClose={() => setSelectLocationModalOpen(false)}
      />
    </>
  ) : (
    <AppBar>
      <Toolbar>
        <ToolbarContainerLeft isMobileScreen={isIpadScreen}>
          <a href={`${Paths.homepage}${urlParams}`}>
            <Tooltip
              title={<TooltipTitleText>Back to Homepage</TooltipTitleText>}
              arrow
            >
              <SmallLogo
                marginLeft={-3}
                marginRight={2}
              />
            </Tooltip>
          </a>
          <ToolbarDivider />
        </ToolbarContainerLeft>
      </Toolbar>
    </AppBar>
  );
}
