import gql from 'graphql-tag';

export const GET_MY_LOCATIONS = gql`
  query getMyLocations {
    getMyLocations {
      id
      businessId
      name
      nickname
      address
    }
  }
`;

export const GET_MY_LOCATION_OVERVIEW = gql`
  query getMyLocationOverview {
    getMyLocationOverview {
      hours
      links
    }
  }
`;

export const GET_MY_CALENDARS = gql`
  query getMyCalendars {
    getMyCalendars {
      id
      name
    }
  }
`;

export const GET_MY_SESSION_TYPES = gql`
  query getMySessionTypes($onlyBookable: Boolean!) {
    getMySessionTypes(onlyBookable: $onlyBookable) {
      categories
      uncategorized
      packages
      memberships
      calendars
    }
  }
`;

export const GET_MY_PACKAGES = gql`
  query getMyPackages {
    getMyPackages
  }
`;

export const GET_MY_CATEGORIES = gql`
  query getMyCategories {
    getMyCategories
  }
`;

export const GET_MY_BOOKABLE_SESSION_TYPES = gql`
  query getMyBookableSessionTypes {
    getMyBookableSessionTypes
  }
`;

export const GET_MY_STAFF = gql`
  query getMyStaff {
    getMyStaff {
      id
      firstName
      lastName
      gender
      avatar
      bio
      isBookable
      shouldMaskName
      eligibleSessionTypeIds
    }
  }
`;

export const GET_MY_PROMOS = gql`
  query getMyPromos {
    getMyPromos {
      id
      name
      description
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;

export const GET_PROMO_BY_ID = gql`
  query getPromoById($id: String!, $editing: Boolean!) {
    getPromoById(id: $id, editing: $editing) {
      promo {
        id
        name
        description
        discountType
        discountAmount
        startDate
        endDate
        createdAt
        updatedAt
        staff
        sessionTypes
      }
      availableSessionTypes
      availableStaff {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_ALL_ACCESSIBLE_ENTERPRISES = gql`
  query getAllAccessibleEnterprises(
    $agencyId: String
    $excludeSingle: Boolean!
  ) {
    getAllAccessibleEnterprises(
      agencyId: $agencyId
      excludeSingle: $excludeSingle
    )
  }
`;

export const GET_ALL_ACCESSIBLE_CONGLOMERATES = gql`
  query getAllAccessibleConglomerates {
    getAllAccessibleConglomerates
  }
`;

export const GET_ALL_ACCESSIBLE_CORPORATIONS = gql`
  query getAllAccessibleCorporations {
    getAllAccessibleCorporations
  }
`;

export const GET_ALL_ACCESSIBLE_BUSINESSES = gql`
  query getAllAccessibleBusinesses {
    getAllAccessibleBusinesses
  }
`;

export const GET_ALL_ACCESSIBLE_AGENCIES = gql`
  query getAllAccessibleAgencies {
    getAllAccessibleAgencies
  }
`;

export const GET_SERVICE_SETTINGS = gql`
  query getServiceSettings {
    getServiceSettings {
      followUp
      deposit
      bookings
      cancellation
      payment
      lastMinute
    }
  }
`;

export const GET_AVAILABLE_SESSION_TYPES = gql`
  query getAvailableSessionTypes($asCatalog: Boolean!) {
    getAvailableSessionTypes(asCatalog: $asCatalog)
  }
`;

export const GET_CATALOG_DIAGNOSTICS = gql`
  query getCatalogDiagnostics {
    getCatalogDiagnostics
  }
`;

export const GET_CORPORATION_LOCATIONS = gql`
  query getCorporationLocations {
    getCorporationLocations
  }
`;

export const GET_ORGANIZATION_TEMPLATES = gql`
  query getOrganizationTemplates {
    getOrganizationTemplates
  }
`;

export const GET_ORGANIZATION_TEMPLATE_BY_ID = gql`
  query getOrganizationTemplateById($id: String!) {
    getOrganizationTemplateById(id: $id)
  }
`;
