import { gql } from '@apollo/client';

export const GET_MY_ANALYTICS = gql`
  query getMyAnalytics(
    $locationIds: [String]
    $campaignIds: [String]
    $start: String
    $end: String
  ) {
    getMyAnalytics(
      locationIds: $locationIds
      campaignIds: $campaignIds
      start: $start
      end: $end
    )
  }
`;

export const GET_CAMPAIGN_ANALYTICS = gql`
  query getCampaignAnalytics($campaignIds: [String]) {
    getCampaignAnalytics(campaignIds: $campaignIds)
  }
`;

export const GET_CALL_ANALYTICS = gql`
  query getCallAnalytics($start: String, $end: String) {
    getCallAnalytics(start: $start, end: $end)
  }
`;

export const GET_APPOINTMENT_CLIENTS = gql`
  query getAppointmentClients($clientIds: [String]) {
    getAppointmentClients(clientIds: $clientIds)
  }
`;

export const GET_INTERCEPTIONS = gql`
  query getInterceptions($chats: [JSON]) {
    getInterceptions(chats: $chats)
  }
`;
