import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../Paths';
import {
  GET_ALL_USERS,
  GET_MULTI_LOCATION_USERS,
  GET_MY_LOCATION_USERS,
  GET_MY_USER,
} from '../queries/users';

export const useMyUser = ({ email, shouldSkip }) => {
  const navigate = useNavigate();

  const { data, error, loading, refetch } = useQuery(GET_MY_USER, {
    fetchPolicy: 'cache-first',
    skip: !email || shouldSkip,
    variables: { email },
    onError: (err) => {
      console.error('GraphQL Error:', err);
      if (isAuthError(err)) {
        navigate(Paths.logout);
      }
    },
  });

  const isAuthError = (err) => {
    const errorMessage = err?.message || '';
    return errorMessage.includes('401');
  };

  useEffect(() => {
    // Handle manual logout on invalid token.
    if (error) {
      console.warn('Error detected:', error);
      if (isAuthError(error)) {
        navigate(Paths.logout);
      }
    }
  }, [error]);

  return {
    error,
    loading,
    refetch,
    user: data?.getMyUser || null,
  };
};

export const useMyLocationUsers = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_LOCATION_USERS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    users: get(data, 'getMyLocationUsers', null),
  };
};

export const useMultiLocationUsers = ({ locationId, skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_MULTI_LOCATION_USERS, {
    fetchPolicy: 'network-only',
    skip: skipCondition,
    variables: {
      locationId,
    },
  });

  return {
    error,
    loading,
    refetch,
    users: get(data, 'getMultiLocationUsers', null),
  };
};

export const useAllUsers = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_ALL_USERS, {
    fetchPolicy: 'network-only',
    skip: skipCondition,
  });

  return {
    error,
    loading,
    refetch,
    users: get(data, 'getAllUsers', null),
  };
};
