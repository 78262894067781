import React, { useEffect, useState } from 'react';
import {
  MediumPrimaryButton,
  MLText,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  allKey,
  completeStatusKey,
  createdSessionTypeTypesLabelMap,
  dropdownInputType,
  sessionTypeBookableStatusLabelMap,
  sessionTypeBookingSystemBookableStatusLabelMap,
} from '../../../utils/constants';
import { PromptInput } from '../../Training/shared-training-components';

const FilterServicesModal = ({
  isOpen,
  onClose,
  displayDiagnostics,
  filter,
  onSave,
}) => {
  const [stagingFilter, setStagingFilter] = useState(filter);

  useEffect(() => {
    if (!stagingFilter && filter) {
      setStagingFilter(filter);
    }
  }, [filter]);

  const type = stagingFilter?.type || allKey;
  const bookableStatus = stagingFilter?.bookableStatus || allKey;
  const diagnosticStatus = stagingFilter?.diagnosticStatus || allKey;

  const onChange = (key, value) => {
    setStagingFilter({
      ...stagingFilter,
      [key]: value,
    });
  };

  const onClickSave = () => {
    onSave(stagingFilter);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <ModalContainer useSmallGap={true}>
        <MLText>Filter Services</MLText>
        <ModalInputsContainerScrollWrapper
          addTopMargin
          topPadding={20}
          columnFlex
          largeGap
        >
          <PromptInput
            label='Type'
            value={type}
            type={dropdownInputType}
            onChange={(e) => {
              onChange('type', e.target.value);
            }}
            options={[
              { key: allKey, label: 'All types' },
              ...createdSessionTypeTypesLabelMap,
            ]}
          />
          <PromptInput
            label='LiveIQ bookable status'
            value={bookableStatus}
            type={dropdownInputType}
            onChange={(e) => {
              onChange('bookableStatus', e.target.value);
            }}
            options={[
              { key: allKey, label: 'All LiveIQ statuses' },
              { key: completeStatusKey, label: 'All bookable statuses' },
              ...sessionTypeBookableStatusLabelMap,
            ]}
          />
          {displayDiagnostics && (
            <PromptInput
              label='Booking system diagnostic status'
              value={diagnosticStatus}
              type={dropdownInputType}
              useExtraSmallGap
              onChange={(e) => {
                onChange('diagnosticStatus', e.target.value);
              }}
              options={[
                { key: allKey, label: 'All booking system statuses' },
                ...sessionTypeBookingSystemBookableStatusLabelMap,
              ]}
            />
          )}
        </ModalInputsContainerScrollWrapper>
        <ModalActions addTopMargin={true}>
          <MediumPrimaryButton
            onClick={() => onClickSave()}
            disabled={false}
          >
            Apply filter
          </MediumPrimaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default FilterServicesModal;
