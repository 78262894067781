import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_INTERNAL_GHL_CHAT } from '../../../../api/mutations/chat';
import {
  BrightGreenTinyText,
  Checkbox,
  Chip,
  ChipCancelIcon,
  ContainerWithInputWidth,
  Dropdown,
  DropdownLabel,
  EssText,
  ExtraSmallAddIcon,
  ExtraSmallPrimaryAddIcon,
  ExtraSmallPrimaryButton,
  Form,
  FormControlLabel,
  FormGroup,
  InputPhoneNumberContainer,
  LinkCreatedIcon,
  MediumGapColumnCenteredDiv,
  Stack,
  StartAlignedFlexDiv,
  StartAlignedMediumDarkExtraSmallText,
  TinyText,
} from '../../../../styles/shared-styled-components';
import {
  appointmentBookedContactEventKey,
  appointmentCancelledContactEventKey,
  appointmentRescheduledContactEventKey,
  callbackRequestedContactEventKey,
  leadConnectorMessagingPlatformKey,
  phoneInputType,
} from '../../../../utils/constants';
import { BaseContext } from '../../../Auth/AuthRouter/AuthRouter';
import Input from '../../../Form/Input';
import SplitInput from '../../../Form/SplitInput/SplitInput';
import LoadingIndicator from '../../../LoadingIndicator';
import PopperMenu from '../../../PopperMenu';
import {
  PromptContainer,
  PromptInput,
  PromptsSection,
} from '../../shared-training-components';
import { ContactPhoneNumberChatContainer } from './styled';

const contactEventOptions = [
  {
    key: appointmentBookedContactEventKey,
    label: 'Appointment created',
  },
  {
    key: appointmentRescheduledContactEventKey,
    label: 'Appointment rescheduled',
  },
  {
    key: appointmentCancelledContactEventKey,
    label: 'Appointment cancelled',
  },
  {
    key: callbackRequestedContactEventKey,
    label: 'Callback requested',
  },
];
const contactEventOptionsLabelMap = {};
contactEventOptions.map((e) => {
  contactEventOptionsLabelMap[e.key] = e.label;
});

const Contact = ({
  agentName,
  location,
  onChange,
  loading,
  snackbarSetter,
}) => {
  const { refetchUser } = useContext(BaseContext);

  const [createInternalChatMutation, { loading: createInternalChatLoading }] =
    useMutation(CREATE_INTERNAL_GHL_CHAT);

  const businessPhoneNumberForClient =
    location?.businessPhoneNumberForClient || '';
  const contactPhoneNumbers = location?.contactPhoneNumbers || [];
  const numNumbers = contactPhoneNumbers.length;
  const notificationPreference = location?.notificationPreference || {};
  const contactEvents = notificationPreference?.contactEvents || [];
  const usingLeadConnector =
    location?.messagingPlatform === leadConnectorMessagingPlatformKey;

  const [ghlChatMenuAnchorEl, setGhlChatMenuAnchorEl] = useState();
  const [ghlChatToCreate, setGhlChatToCreate] = useState({});

  const ghlChatMenuOpenBoolean = Boolean(ghlChatMenuAnchorEl);

  const onCreateGhlChat = () => {
    setGhlChatMenuAnchorEl();

    createInternalChatMutation({
      variables: {
        phone: ghlChatToCreate.phone,
        firstName: ghlChatToCreate.firstName,
        lastName: ghlChatToCreate.lastName,
      },
      onCompleted: async () => {
        await refetchUser();
        snackbarSetter(`Internal chat created`);
      },
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <PromptsSection>
      <PromptContainer addMediumGap>
        <PromptInput
          value={businessPhoneNumberForClient}
          onChange={(updatedPhone) => {
            onChange({
              ...location,
              businessPhoneNumberForClient: updatedPhone,
            });
          }}
          label='Main business phone number for clients'
          type={phoneInputType}
        />
      </PromptContainer>
      <PromptContainer>
        <Input
          id='contact-events'
          value={contactEvents}
          removeGap
          useSmallWidth
          CustomInputComponent={
            <Form
              fitContentHeight
              useSmallWidth
            >
              <DropdownLabel>When should {agentName} text you?</DropdownLabel>
              <Dropdown
                multiple
                useSmallWidth
                value={contactEvents}
                label={`When should ${agentName} text you?`}
                renderValue={(selected) => {
                  return (
                    <Stack>
                      {Object.values(selected)?.map((selectedEvent) => {
                        return (
                          <Chip
                            key={selectedEvent}
                            label={contactEventOptionsLabelMap[selectedEvent]}
                            onDelete={() => {
                              onChange({
                                ...location,
                                notificationPreference: {
                                  ...notificationPreference,
                                  contactEvents: contactEvents.filter(
                                    (e) => e !== selectedEvent,
                                  ),
                                },
                              });
                            }}
                            deleteIcon={
                              <ChipCancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        );
                      })}
                    </Stack>
                  );
                }}
              >
                {contactEventOptions.map((o) => {
                  const { key, label } = o;
                  const checked = contactEvents.includes(key);
                  return (
                    <>
                      <FormGroup key={key}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onClick={() => {
                                if (!checked) {
                                  onChange({
                                    ...location,
                                    notificationPreference: {
                                      ...notificationPreference,
                                      contactEvents: [...contactEvents, key],
                                    },
                                  });
                                } else {
                                  onChange({
                                    ...location,
                                    notificationPreference: {
                                      ...notificationPreference,
                                      contactEvents: contactEvents.filter(
                                        (e) => e !== key,
                                      ),
                                    },
                                  });
                                }
                              }}
                            />
                          }
                          label={label}
                        />
                      </FormGroup>
                    </>
                  );
                })}
              </Dropdown>
            </Form>
          }
        />
      </PromptContainer>
      <PromptContainer addMediumGap>
        <ContainerWithInputWidth useSmallWidth>
          <StartAlignedMediumDarkExtraSmallText>
            Where should {agentName} text you?
          </StartAlignedMediumDarkExtraSmallText>
          <MediumGapColumnCenteredDiv
            topMargin={25}
            bottomMargin={25}
          >
            {contactPhoneNumbers?.map((contactPhoneNumberObj, idx) => {
              const { value, link, id } = contactPhoneNumberObj;
              const chatCreated = !!link;
              const canCreateChat = usingLeadConnector && !chatCreated;
              return (
                <div>
                  <PromptInput
                    useFullWidth
                    value={value}
                    onChange={(value) => {
                      onChange({
                        ...location,
                        contactPhoneNumbers: contactPhoneNumbers.map((n) => {
                          if (n.id === id) {
                            return {
                              ...n,
                              value,
                            };
                          }
                          return n;
                        }),
                      });
                    }}
                    label={`Phone number${
                      numNumbers === 1 ? '' : ` #${idx + 1}`
                    }`}
                    type={phoneInputType}
                    includeDeleteButton
                    onDelete={() => {
                      onChange({
                        ...location,
                        contactPhoneNumbers: contactPhoneNumbers.filter(
                          (n) => n.id !== id,
                        ),
                      });
                    }}
                  />
                  {canCreateChat ? (
                    <ContactPhoneNumberChatContainer
                      onClick={(e) => {
                        setGhlChatMenuAnchorEl(e.currentTarget);
                        setGhlChatToCreate({
                          firstName: 'Internal',
                          lastName: `Chat ${idx + 1}`,
                          phone: value,
                        });
                      }}
                    >
                      <TinyText
                        centered
                        addGap
                      >
                        Create GHL chat <ExtraSmallPrimaryAddIcon />
                      </TinyText>
                    </ContactPhoneNumberChatContainer>
                  ) : chatCreated ? (
                    <ContactPhoneNumberChatContainer
                      complete
                      onClick={() => {
                        window.location.href = link;
                      }}
                    >
                      <BrightGreenTinyText
                        centered
                        addGap
                      >
                        GHL chat created <LinkCreatedIcon />
                      </BrightGreenTinyText>
                    </ContactPhoneNumberChatContainer>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </MediumGapColumnCenteredDiv>
          <PopperMenu
            open={ghlChatMenuOpenBoolean}
            anchorElement={ghlChatMenuAnchorEl}
            onClose={() => {
              setGhlChatMenuAnchorEl();
              setGhlChatToCreate({});
            }}
            variant='offset'
            modifiers={{
              name: 'offset',
              options: {
                offset: [0, -260],
              },
            }}
          >
            <InputPhoneNumberContainer>
              <EssText>Select the name to use for this chat in GHL </EssText>
              <SplitInput
                id={'name'}
                splitInputs={[
                  {
                    id: 'first',
                    value: ghlChatToCreate.firstName,
                    onChange: (e) =>
                      setGhlChatToCreate({
                        ...ghlChatToCreate,
                        firstName: e.target.value,
                      }),
                    label: 'First name',
                  },
                  {
                    id: 'last',
                    value: ghlChatToCreate.lastName,
                    onChange: (e) =>
                      setGhlChatToCreate({
                        ...ghlChatToCreate,
                        lastName: e.target.value,
                      }),
                    label: 'Last name',
                  },
                ]}
                useExtraSmallGap={true}
              />
              <ExtraSmallPrimaryButton
                largePadding
                topMargin={10}
                disabled={
                  !ghlChatToCreate.firstName || !ghlChatToCreate.lastName
                }
                onClick={() => onCreateGhlChat()}
              >
                Create chat
              </ExtraSmallPrimaryButton>
            </InputPhoneNumberContainer>
          </PopperMenu>
          <StartAlignedFlexDiv
            topMargin={contactPhoneNumbers?.length ? 10 : -30}
          >
            <ExtraSmallPrimaryButton
              disabled={numNumbers === 3}
              onClick={() => {
                onChange({
                  ...location,
                  contactPhoneNumbers: [
                    ...contactPhoneNumbers,
                    { id: uuidv4() },
                  ],
                });
              }}
            >
              Add number <ExtraSmallAddIcon />
            </ExtraSmallPrimaryButton>
          </StartAlignedFlexDiv>
        </ContainerWithInputWidth>
      </PromptContainer>
    </PromptsSection>
  );
};

export default Contact;
