import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  DarkExtraSmallText,
  HorizontalSectionMenuDivider,
  MediumDarkSMMenuItemText,
  MenuItem,
} from '../../../styles/shared-styled-components';
import { handleLogin, handleSignup } from '../../../utils/auth';
import {
  appModeCookieKey,
  consultantRole,
  demoAppModeKey,
  liveAppModeKey,
  superAdminRole,
  supportAgentRole,
} from '../../../utils/constants';
import { getCookieExpiryObject } from '../../../utils/date';
import { getFormattedFirstName } from '../../../utils/name';
import {
  checkIfPathIsUserOnboarding,
  getAgentAppConnectionUrl,
} from '../../../utils/routing';
import { getUserLiveIQAgentId } from '../../../utils/user';
import { screenWidthIsMediumMobileSizeOrSmaller } from '../../../utils/view';
import { getWhiteLabelId } from '../../../utils/white-label';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import DataSyncStatusModal from '../../Modals/DataSyncStatusModal';
import PopperMenu from '../../PopperMenu';
import {
  BillingIconInDropdown,
  ConnectionsIconInDropdown,
  DemoIconInDropdown,
  ProfileIconInDropdown,
  ProfileIconInHeader,
  ProfileLogoutIcon,
  ProfileMenuButton,
  SettingsIconInDropdown,
  ShapeShiftIconInDropdown,
  TermsIconInDropdown,
} from './styled';

const profileReady = false;

const cookieExpiryObject = getCookieExpiryObject();

const whiteLabelId = getWhiteLabelId();
const whiteLabelConfig = whiteLabelId ? Paths.whiteLabel[whiteLabelId] : null;
const whiteLabelTitle = whiteLabelConfig?.title;
const testUserIds = ['ae413c10-938d-4031-baa6-2da0828c1695'];

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loginWithRedirect } = useAuth0();
  const {
    inDemoMode,
    user,
    width,
    setCookie,
    inMonitoringMode,
    viewingAgency,
    isShapeShifter,
  } = useContext(BaseContext);
  const [searchParams] = useSearchParams();

  const agentId = getUserLiveIQAgentId(user);

  const [syncModalIsOpen, setSyncModalIsOpen] = useState(false);

  const role = user?.role;
  const isSuperAdmin = role === superAdminRole;
  const isConsultant = role === consultantRole;
  const isSupportAgent = role === supportAgentRole;
  const isMobileScreen = screenWidthIsMediumMobileSizeOrSmaller(width);
  const formattedIsMobileScreen = isMobileScreen || !user;
  const nameCharThreshold = formattedIsMobileScreen ? 6 : 15;

  const pathIsOnboardingPage = checkIfPathIsUserOnboarding(pathname);

  let formattedFirstName = `${getFormattedFirstName(
    user?.firstName || user?.email,
  )}`;
  formattedFirstName =
    formattedFirstName?.length > nameCharThreshold
      ? `${formattedFirstName.slice(0, nameCharThreshold)}...`
      : formattedFirstName;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickProfile = () => {
    if (onProfilePage) {
      handleClose();
    } else {
      navigate(Paths.profile);
    }
  };

  const onLoginOrSignup = () => {
    const utmSource = searchParams.get('utm');

    if (utmSource) {
      localStorage.setItem('utm', utmSource);
    }
  };

  const onSignup = () => {
    handleSignup({ onClick: onLoginOrSignup, pathname, loginWithRedirect });
  };

  const onLogin = () => {
    handleLogin({ onClick: onLoginOrSignup, pathname, loginWithRedirect });
  };

  const onChangeAppMode = (newAppMode) => {
    setCookie(appModeCookieKey, newAppMode, cookieExpiryObject);
    handleClose();
    window.location.reload();
  };

  const onClickLogout = () => {
    navigate(Paths.logout);
  };

  const onProfilePage = pathname === Paths.profile;

  return (
    <>
      {whiteLabelTitle && (
        <DarkExtraSmallText>{whiteLabelTitle}</DarkExtraSmallText>
      )}
      <ProfileMenuButton
        disableElevation
        // Have to spell it in lowercase so React doesn't confuse it for a built-in prop
        disablepadding
        onClick={handleProfileClick}
        startIcon={
          <ProfileIconInHeader isMobileScreen={formattedIsMobileScreen} />
        }
        isMobileScreen={formattedIsMobileScreen}
      >
        {formattedFirstName}
      </ProfileMenuButton>
      <PopperMenu
        open={open}
        anchorElement={anchorEl}
        onClose={handleClose}
        variant='offset'
        fixedWidth={!isMobileScreen && user && 250}
      >
        {user ? (
          <>
            {!pathIsOnboardingPage && profileReady && (
              <>
                <MenuItem
                  onClick={onClickProfile}
                  disableRipple
                >
                  <ProfileIconInDropdown />
                  <MediumDarkSMMenuItemText>Profile</MediumDarkSMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            {(testUserIds.includes(user?.id) ||
              (agentId &&
                !inMonitoringMode &&
                (isSuperAdmin || isConsultant))) && (
              <>
                <MenuItem
                  onClick={() => navigate(getAgentAppConnectionUrl(agentId))}
                  disableRipple
                >
                  <ConnectionsIconInDropdown />
                  <MediumDarkSMMenuItemText>
                    Connections
                  </MediumDarkSMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            {!inMonitoringMode && !isSupportAgent && (
              <>
                {' '}
                <MenuItem
                  onClick={() => navigate(Paths.billing)}
                  disableRipple
                >
                  <BillingIconInDropdown />
                  <MediumDarkSMMenuItemText>Billing</MediumDarkSMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            <MenuItem
              onClick={() => navigate(Paths.terms)}
              disableRipple
            >
              <TermsIconInDropdown />
              <MediumDarkSMMenuItemText>Terms of Use</MediumDarkSMMenuItemText>
            </MenuItem>
            <HorizontalSectionMenuDivider />
            {isSuperAdmin && (
              <>
                <MenuItem
                  onClick={() =>
                    onChangeAppMode(
                      inDemoMode ? liveAppModeKey : demoAppModeKey,
                    )
                  }
                  disableRipple
                >
                  <DemoIconInDropdown />
                  <MediumDarkSMMenuItemText>
                    {inDemoMode ? `Leave demo mode` : `Enter demo mode`}
                  </MediumDarkSMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            {!inMonitoringMode && !viewingAgency && (
              <>
                <MenuItem
                  onClick={() => navigate(Paths.settings)}
                  disableRipple
                >
                  <SettingsIconInDropdown />
                  <MediumDarkSMMenuItemText>Settings</MediumDarkSMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            {isShapeShifter && (
              <>
                <MenuItem
                  onClick={() => navigate(Paths.shapeShift)}
                  disableRipple
                >
                  <ShapeShiftIconInDropdown />
                  <MediumDarkSMMenuItemText>
                    Shape Shift
                  </MediumDarkSMMenuItemText>
                </MenuItem>
                <HorizontalSectionMenuDivider />
              </>
            )}
            <MenuItem
              onClick={onClickLogout}
              disableRipple
            >
              <ProfileLogoutIcon />
              <MediumDarkSMMenuItemText>Logout</MediumDarkSMMenuItemText>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={onSignup}
              disableRipple
            >
              <MediumDarkSMMenuItemText>Sign up</MediumDarkSMMenuItemText>
            </MenuItem>
            <MenuItem
              onClick={onLogin}
              disableRipple
            >
              <MediumDarkSMMenuItemText>Login</MediumDarkSMMenuItemText>
            </MenuItem>
          </>
        )}
      </PopperMenu>
      <DataSyncStatusModal
        isOpen={syncModalIsOpen}
        onClose={() => setSyncModalIsOpen(false)}
      />
    </>
  );
};

export default ProfileDropdown;
