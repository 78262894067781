import styled, { css } from 'styled-components';
import {
  AutoHorizontalMargins,
  CenterFlexStyle,
  EssText,
  MediumGapStyle,
  SMMIconSize,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';
import { DownArrow, UpArrow } from '../../Micro/ArrowIcon/styled';
import { FilterFieldContainer } from '../FilterClientsModal/styled';

export const FieldsContainer = styled(FilterFieldContainer)`
  ${MediumGapStyle}
  padding-top: 10px;
  ${AutoHorizontalMargins}
`;

export const AdvancedButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  ${TextInputWidthStyle}
`;

export const AdvancedButton = styled(EssText)`
  ${CenterFlexStyle}
  cursor: pointer;
  gap: 2px;
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

export const AdvancedArrowStyle = css`
  ${SMMIconSize}
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

export const AdvancedDownArrow = styled(DownArrow)`
  ${AdvancedArrowStyle}
`;

export const AdvancedUpArrow = styled(UpArrow)`
  ${AdvancedArrowStyle}
`;

export const ToggleAllTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 26px;
`;
