import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import { useAllEnterprises } from '../../../api/hooks/enterprise';
import { CREATE_ENTERPRISE } from '../../../api/mutations/enterprise';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import FormPage from '../../../components/Form/FormPage';
import Input from '../../../components/Form/Input';
import { InputToggleContainer } from '../../../components/Modals/EditServiceModal/styled';
import LocationSearchBox from '../../../components/Search/LocationSearchBox/LocationSearchBox';
import {
  ColumnCenteredDiv,
  EmptyGapColumnCenteredDiv,
  InputContainer,
  InputContainerForMediumGap,
  InputTitleContainer,
  MediumDarkEssText,
  SMCheckbox,
  StartAlignedLightDarkEssText,
  StartAlignedMediumDarkEssText,
  StartAlignedMediumDarkLargeTinyText,
  StartAlignedSmallText,
  TextInputWidthStyle,
} from '../../../styles/shared-styled-components';
import {
  businessTypes,
  dropdownInputType,
  franchiseBusinessType,
  integerInputType,
  multiLocationBusinessType,
  singleLocationBusinessType,
  superAdminRole,
} from '../../../utils/constants';
import { valueIsEmpty } from '../../../utils/data';
import {
  checkIfIsValidUrl,
  getIdOfFirstEmptyField,
} from '../../../utils/input';
import { removeAllLocationSpecificCookies } from '../../../utils/routing';
import { stripAllSpacesFromString } from '../../../utils/string';
import { locationIdSelectedKey } from '../../../utils/user';
import { scrollElementIntoView } from '../../../utils/view';

const websiteId = 'website';
const websiteErrorText = 'Please enter a valid URL';

const locationEnterpriseType = 'location';
const agencyEnterpriseType = 'agency';
const enterpriseTypes = [
  {
    key: locationEnterpriseType,
    label: 'Location',
  },
  {
    key: agencyEnterpriseType,
    label: 'Agency',
  },
];

const CreateBusiness = () => {
  const apolloClient = useApolloClient();
  const { user, userLoading, removeCookie } = useContext(BaseContext);
  const navigate = useNavigate();

  const isSuperAdmin = user?.role === superAdminRole;

  const {
    enterprises,
    loading: enterprisesLoading,
    refetch,
  } = useAllEnterprises({ excludeSingle: true });
  const conglomerateMap = {};
  const corporationMap = {};
  const businessMap = {};
  (enterprises || []).map((e) => {
    const {
      conglomerateId,
      conglomerate,
      corporationId,
      corporation,
      businessId,
      business,
    } = e;
    if (conglomerateId) {
      conglomerateMap[conglomerateId] = {
        key: conglomerateId,
        label: conglomerate,
      };
    }
    if (corporationId) {
      corporationMap[corporationId] = {
        key: corporationId,
        label: corporation,
      };
    }
    if (businessId) {
      businessMap[businessId] = { key: businessId, label: business };
    }
  });

  const [createEnterpriseMutation, { loading: createEnterpriseLoading }] =
    useMutation(CREATE_ENTERPRISE);

  const [enterpriseType, setEnterpriseType] = useState(
    isSuperAdmin ? '' : locationEnterpriseType,
  );
  const [agencyName, setAgencyName] = useState('');
  const [agencyNickname, setAgencyNickname] = useState('');
  const [type, setType] = useState('');
  const [isConglomerateAssociate, setIsConglomerateAssociate] = useState(false);
  const [conglomerateId, setConglomerateId] = useState(null);
  const [conglomerateName, setConglomerateName] = useState('');
  const [corporationId, setCorporationId] = useState(null);
  const [corporationName, setCorporationName] = useState('');
  const [corporationNumLocations, setCorporationNumLocations] = useState('');
  const [businessId, setBusinessId] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [nickname, setNickname] = useState('');
  const [numLocations, setNumLocations] = useState('');
  const [locationName, setLocationName] = useState('');
  const [website, setWebsite] = useState('');
  const [websiteErrorMessage, setWebsiteErrorMessage] = useState();
  const [stagingAddress, setStagingAddress] = useState('');
  const [address, setAddress] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [timezone, setTimezone] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false);
  const [redirectLoading, setRedirectLoading] = useState(false);

  const conglomerateOptions = Object.values(conglomerateMap);
  const corporationOptions = Object.values(corporationMap);
  const businessOptions = Object.values(businessMap);

  const createLocation = () => {
    createEnterpriseMutation({
      variables: {
        data: {
          enterpriseType: locationEnterpriseType,
          type,
          conglomerateId,
          conglomerateName,
          corporationId,
          corporationName,
          numLocations: parseInt(numLocations),
          businessId,
          businessName,
          locationName,
          nickname,
          website,
          address,
          lat: lat?.toString(),
          lng: lng?.toString(),
          timezone,
          postalCode,
        },
      },
      onCompleted: async (data) => {
        setRedirectLoading(true);
        const result = data.createEnterprise;
        const createdEnterprise = result.enterprise;
        const createdLocation = createdEnterprise?.location?.id;

        if (createdLocation) {
          const createdLocationId = createdLocation.id;
          const createdAgentId = createdEnterprise?.location?.agents?.[0]?.id;

          removeAllLocationSpecificCookies(removeCookie);
          localStorage.setItem(locationIdSelectedKey, createdLocationId);
          await apolloClient.clearStore();

          const pathToNavigateTo = createdAgentId
            ? Paths.agentAppConnection.replace(':agentId', createdAgentId)
            : Paths.callback;
          navigate(pathToNavigateTo);
        }
      },
    });
  };

  const createAgency = () => {
    createEnterpriseMutation({
      variables: {
        data: {
          enterpriseType: agencyEnterpriseType,
          businessName: agencyName,
          nickname: agencyNickname,
        },
      },
      onCompleted: async (data) => {
        setRedirectLoading(true);
        const result = data.createEnterprise;
        const createdEnterprise = result.enterprise;
        const createdAgency = createdEnterprise?.agency?.id;
        if (createdAgency) {
          navigate(Paths.dashboard);
        }
      },
    });
  };

  const onClickContinue = () => {
    if (enterpriseType === locationEnterpriseType) {
      const formattedWebsite = stripAllSpacesFromString(website);
      const isValidUrl = checkIfIsValidUrl(formattedWebsite);

      if (!continueEnabled) {
        const idWithError = getIdOfFirstEmptyField(inputs);
        scrollElementIntoView(document, idWithError);
        setSubmittedDataWithErrors(true);

        if (website && !isValidUrl) {
          setWebsiteErrorMessage(websiteErrorText);
        }

        return;
      }

      if (!isValidUrl) {
        scrollElementIntoView(document, websiteId);
        setWebsiteErrorMessage(websiteErrorText);

        return;
      }

      createLocation();
    } else if (enterpriseType === agencyEnterpriseType) {
      if (!continueEnabled) {
        return;
      }

      createAgency();
    }
  };

  const typeInputs = [
    <>
      {isSuperAdmin && (
        <InputContainerForMediumGap removeMargin>
          <InputTitleContainer addTopMargin>
            <StartAlignedSmallText>
              What are you creating?
            </StartAlignedSmallText>
          </InputTitleContainer>
          <ColumnCenteredDiv>
            <Input
              id='type'
              value={enterpriseType}
              onChange={(e) => {
                setEnterpriseType(e.target.value);
              }}
              type={dropdownInputType}
              options={enterpriseTypes}
              isRequired={false}
              label={'Enterprise type'}
              useExtraSmallGap
            />
          </ColumnCenteredDiv>
        </InputContainerForMediumGap>
      )}
      {enterpriseType === locationEnterpriseType ? (
        <InputContainerForMediumGap removeMargin>
          <InputTitleContainer addTopMargin>
            <StartAlignedSmallText>
              What type of business does this location belong to?
            </StartAlignedSmallText>
          </InputTitleContainer>
          <ColumnCenteredDiv>
            <Input
              id='type'
              value={type}
              onChange={(e) => {
                const previousType = type;
                const newType = e.target.value;

                setType(newType);

                if (newType === singleLocationBusinessType) {
                  setNumLocations(1);
                } else if (previousType === singleLocationBusinessType) {
                  setNumLocations();
                }
              }}
              type={dropdownInputType}
              options={businessTypes}
              isRequired={false}
              label={'Type'}
              containsError={valueIsEmpty(type) && submittedDataWithErrors}
              useExtraSmallGap
            />
          </ColumnCenteredDiv>
        </InputContainerForMediumGap>
      ) : enterpriseType === agencyEnterpriseType ? (
        <>
          <Input
            id='agency-name'
            value={agencyName}
            onChange={(e) => {
              setAgencyName(e.target.value);
            }}
            label='Agency name'
            removeGap
          />
          <Input
            id='agency-nickame'
            value={agencyNickname}
            onChange={(e) => {
              setAgencyNickname(e.target.value);
            }}
            label='Agency shorthand code'
            removeGap
          />
        </>
      ) : (
        <></>
      )}
    </>,
  ];
  const inputs = [
    ...typeInputs,
    <>
      {type !== singleLocationBusinessType ? (
        <>
          <InputContainerForMediumGap removeMargin>
            <InputTitleContainer>
              <StartAlignedSmallText>Business info</StartAlignedSmallText>
            </InputTitleContainer>
            <ColumnCenteredDiv>
              <InputToggleContainer>
                <MediumDarkEssText>Create new business</MediumDarkEssText>
                <SMCheckbox
                  checked={corporationId == null}
                  onClick={() => {
                    setCorporationId(corporationId == null ? '' : null);
                  }}
                />
              </InputToggleContainer>
              {corporationId != null && (
                <Input
                  id='corporation'
                  value={corporationId}
                  onChange={(e) => {
                    setCorporationId(e.target.value);
                  }}
                  type={dropdownInputType}
                  options={corporationOptions}
                  isRequired={false}
                  label={'Business to attach to'}
                  removeGap
                />
              )}
            </ColumnCenteredDiv>
            {corporationId == null ? (
              <ColumnCenteredDiv topMargin={6}>
                <InputToggleContainer>
                  <StartAlignedMediumDarkEssText smallLineHeight>
                    This is a subsidiary of a larger corporation
                  </StartAlignedMediumDarkEssText>
                  <SMCheckbox
                    checked={isConglomerateAssociate}
                    onClick={() => {
                      setIsConglomerateAssociate(!isConglomerateAssociate);
                    }}
                  />
                </InputToggleContainer>
                {isConglomerateAssociate ? (
                  <ColumnCenteredDiv
                    topMargin={10}
                    bottomMargin={10}
                  >
                    <InputToggleContainer>
                      <StartAlignedMediumDarkEssText smallLineHeight>
                        Create new corporation
                      </StartAlignedMediumDarkEssText>
                      <SMCheckbox
                        checked={conglomerateId == null}
                        onClick={() => {
                          setConglomerateId(conglomerateId == null ? '' : null);
                        }}
                      />
                    </InputToggleContainer>
                    {conglomerateId == null ? (
                      <Input
                        id='conglomerate-name'
                        value={conglomerateName}
                        onChange={(e) => {
                          setConglomerateName(e.target.value);
                        }}
                        label='Corporation name'
                        containsError={
                          valueIsEmpty(conglomerateName) &&
                          submittedDataWithErrors
                        }
                        removeGap
                      />
                    ) : (
                      <>
                        {conglomerateOptions?.length ? (
                          <Input
                            id='conglomerate'
                            value={conglomerateId}
                            onChange={(e) => {
                              setConglomerateId(e.target.value);
                            }}
                            type={dropdownInputType}
                            options={conglomerateOptions}
                            isRequired={false}
                            label={'Corporation to attach business to'}
                            removeGap
                          />
                        ) : (
                          <InputToggleContainer>
                            <StartAlignedLightDarkEssText>
                              No existing accessible corporations found, must
                              create new
                            </StartAlignedLightDarkEssText>
                          </InputToggleContainer>
                        )}
                      </>
                    )}
                  </ColumnCenteredDiv>
                ) : (
                  <></>
                )}
              </ColumnCenteredDiv>
            ) : (
              <></>
            )}
          </InputContainerForMediumGap>
          {corporationId == null && (
            <>
              <Input
                id='business-name'
                value={corporationName}
                onChange={(e) => {
                  setCorporationName(e.target.value);
                }}
                label='Business name'
                containsError={
                  valueIsEmpty(corporationName) && submittedDataWithErrors
                }
                removeGap
              />
              <Input
                id='num-locations'
                value={numLocations}
                onChange={(e) => {
                  setNumLocations(e.target.value);
                }}
                label='Number of total locations in business'
                renderCondition={
                  type === franchiseBusinessType ||
                  type === multiLocationBusinessType
                }
                type={integerInputType}
                containsError={
                  valueIsEmpty(numLocations) && submittedDataWithErrors
                }
                removeGap
              />
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>,
    <>
      {type !== singleLocationBusinessType ? (
        <>
          <InputContainerForMediumGap
            removeMargin
            topMargin={20}
          >
            <InputTitleContainer>
              <StartAlignedSmallText smallLineHeight>
                Portfolio Info
              </StartAlignedSmallText>
              <StartAlignedMediumDarkLargeTinyText smallLineHeight>
                A portfolio is a set of locations within the business, such as a
                batch of locations owned by a particular franchisee
              </StartAlignedMediumDarkLargeTinyText>
            </InputTitleContainer>
            <ColumnCenteredDiv topMargin={15}>
              <InputToggleContainer>
                <MediumDarkEssText>
                  Create new portfolio within the business
                </MediumDarkEssText>
                <SMCheckbox
                  checked={businessId == null}
                  onClick={() => {
                    setBusinessId(businessId == null ? '' : null);
                  }}
                />
              </InputToggleContainer>
              {businessId != null ? (
                <Input
                  id='business'
                  value={businessId}
                  onChange={(e) => {
                    setBusinessId(e.target.value);
                  }}
                  type={dropdownInputType}
                  options={businessOptions}
                  isRequired={false}
                  label={'Portfolio to attach to'}
                  removeGap
                />
              ) : (
                <Input
                  id='name'
                  value={businessName}
                  onChange={(e) => {
                    setBusinessName(e.target.value);
                  }}
                  label='Portfolio name (leave blank if corporate-owned)'
                  containsError={
                    valueIsEmpty(businessName) && submittedDataWithErrors
                  }
                  removeGap
                />
              )}
            </ColumnCenteredDiv>
          </InputContainerForMediumGap>
        </>
      ) : (
        <></>
      )}
    </>,
    <>
      {type === singleLocationBusinessType && (
        <>
          <Input
            id='location-name'
            value={locationName}
            onChange={(e) => {
              setLocationName(e.target.value);
            }}
            label='Name'
            containsError={
              valueIsEmpty(locationName) && submittedDataWithErrors
            }
            removeGap
          />
          <Input
            id='nickname'
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
            }}
            label='Name abbreviation'
            containsError={valueIsEmpty(nickname) && submittedDataWithErrors}
            removeGap
          />
          <Input
            id='num-locations'
            value={numLocations}
            onChange={(e) => {
              setNumLocations(e.target.value);
            }}
            label='Number of locations'
            renderCondition={
              type === franchiseBusinessType ||
              type === multiLocationBusinessType
            }
            type={integerInputType}
            containsError={
              valueIsEmpty(numLocations) && submittedDataWithErrors
            }
            removeGap
          />
        </>
      )}
    </>,
    <EmptyGapColumnCenteredDiv>
      <InputTitleContainer>
        <StartAlignedSmallText>Location info</StartAlignedSmallText>
      </InputTitleContainer>
      <Input
        id='location-name'
        value={locationName}
        onChange={(e) => {
          setLocationName(e.target.value);
        }}
        label='Location name'
        renderCondition={type !== singleLocationBusinessType}
        containsError={valueIsEmpty(locationName) && submittedDataWithErrors}
        useExtraSmallGap
      />
    </EmptyGapColumnCenteredDiv>,
    <InputContainer useExtraSmallGap={true}>
      <LocationSearchBox
        customLabel={
          type === singleLocationBusinessType ? 'Address' : 'Location address'
        }
        value={stagingAddress}
        onChange={(newStagingAddress) => {
          setStagingAddress(newStagingAddress);

          if (!newStagingAddress) {
            setAddress('');
            setLat('');
            setLng('');
            setTimezone('');
            setPostalCode('');
          }
        }}
        onEnter={(newAddress, newLat, newLng, newTimezone, newPostalCode) => {
          setStagingAddress(newAddress);
          setAddress(newAddress);
          setLat(newLat);
          setLng(newLng);
          setTimezone(newTimezone);
          setPostalCode(newPostalCode);
        }}
        customPopperMenuStyle={TextInputWidthStyle}
      />
    </InputContainer>,
    <Input
      id={websiteId}
      value={website}
      onChange={(e) => {
        setWebsite(e.target.value);
        if (websiteErrorMessage) {
          setWebsiteErrorMessage();
        }
      }}
      label={
        type === singleLocationBusinessType ? 'Website' : 'Location website'
      }
      containsError={valueIsEmpty(website) && submittedDataWithErrors}
      removeGap
      errorMessage={websiteErrorMessage}
    />,
  ];

  const continueEnabled =
    enterpriseType === locationEnterpriseType
      ? !valueIsEmpty(locationName) &&
        (corporationId == null || !!corporationId) &&
        !valueIsEmpty(type) &&
        (type === singleLocationBusinessType ||
          !!corporationId ||
          (type !== singleLocationBusinessType &&
            !valueIsEmpty(numLocations) &&
            !valueIsEmpty(locationName))) &&
        !valueIsEmpty(website) &&
        !valueIsEmpty(address) &&
        !valueIsEmpty(timezone)
      : enterpriseType === agencyEnterpriseType
      ? !valueIsEmpty(agencyName) && !valueIsEmpty(agencyNickname)
      : false;

  const loading = userLoading || createEnterpriseLoading || redirectLoading;

  return (
    user && (
      <FormPage
        title={
          !enterpriseType
            ? `New Enterprise`
            : enterpriseType === agencyEnterpriseType
            ? `New Agency`
            : `New Location`
        }
        isLoading={loading}
        inputs={type ? inputs : typeInputs}
        continueEnabled={continueEnabled}
        onContinue={onClickContinue}
        backEnabled={false}
        continueButtonText={'Create'}
      />
    )
  );
};

export default CreateBusiness;
