import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useOrganizationTemplateById } from '../../../api/hooks/enterprise';
import { SAVE_ORGANIZATION_TEMPLATE } from '../../../api/mutations/enterprise';
import {
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  MediumGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  dropdownInputType,
  organizationTemplateTypeLabelMap,
  superAdminRole,
} from '../../../utils/constants';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import Input from '../../Form/Input';
import LoadingIndicator from '../../LoadingIndicator';

const emptyTemplateObject = {
  type: '',
  locationId: '',
};

const templateOptions = Object.values(organizationTemplateTypeLabelMap);

const OrganizationTemplateModal = ({
  id,
  locationOptions,
  isOpen,
  onClose,
  onSave,
}) => {
  const { inAgencyMode, user } = useContext(BaseContext);
  const isSuperAdmin = user?.role === superAdminRole;
  const {
    template,
    loading: templateLoading,
    refetch,
  } = useOrganizationTemplateById({
    id,
    skipCondition: !id || !isOpen,
  });
  const [saveOrganizationTemplateMutation, { loading: saveLoading }] =
    useMutation(SAVE_ORGANIZATION_TEMPLATE);

  const [content, setContent] = useState(template || emptyTemplateObject);

  useEffect(() => {
    if (template) {
      setContent(template);
    }
  }, [template, isOpen]);

  const handleSave = () => {
    saveOrganizationTemplateMutation({
      variables: {
        content,
      },
      onCompleted: async () => {
        handleClose();
        await refetch();
        await onSave();
      },
    });
  };

  const handleClose = () => {
    onClose();
  };

  if (templateLoading && isOpen) {
    return <LoadingIndicator />;
  }

  const type = content?.type;
  const locationId = content?.locationId;
  const saveEnabled = !!type && (isSuperAdmin || inAgencyMode || !!locationId);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <ModalContainer>
        <DarkMLText>
          {id ? 'Edit' : 'Create'}{' '}
          {isSuperAdmin ? 'LiveIQ' : inAgencyMode ? 'Agency' : 'Corporation'}{' '}
          Template
        </DarkMLText>
        <ModalInputsContainer>
          <ModalInputsContainerScrollWrapper columnFlex>
            <MediumGapColumnCenteredDiv>
              <EmptyGapColumnCenteredDiv>
                {!isSuperAdmin && !inAgencyMode && (
                  <Input
                    id={'location'}
                    label='Location'
                    value={content?.locationId}
                    onChange={(e) => {
                      setContent({
                        ...content,
                        locationId: e.target.value,
                      });
                    }}
                    type={dropdownInputType}
                    options={locationOptions}
                  />
                )}
                <Input
                  id={'type'}
                  label='Type'
                  value={content?.type}
                  onChange={(e) => {
                    setContent({
                      ...content,
                      type: e.target.value,
                    });
                  }}
                  type={dropdownInputType}
                  options={templateOptions}
                />
              </EmptyGapColumnCenteredDiv>
            </MediumGapColumnCenteredDiv>
          </ModalInputsContainerScrollWrapper>
        </ModalInputsContainer>
        <ModalActions>
          <MediumPrimaryButton
            onClick={() => {
              if (saveEnabled) {
                handleSave();
              }
            }}
            disabled={!saveEnabled}
          >
            Save
          </MediumPrimaryButton>
          <MediumSecondaryButton onClick={handleClose}>
            Close
          </MediumSecondaryButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

export default OrganizationTemplateModal;
