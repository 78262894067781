import styled from 'styled-components';
import {
  AutoHorizontalMargins,
  CenterFlexStyle,
  ColumnFlexStyle,
  ExtraSmallGapStyle,
  SmallGapStyle,
  VeryLightExtraSmallText,
} from '../../styles/shared-styled-components';

export const FooterContainer = styled.div`
  background-color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.charcoal
      : ({ theme }) => theme.color.lightGray};
  padding: 0px;
  min-height: ${({ theme }) => theme.spacing.footerHeight}px;
  height: ${({ theme }) => theme.spacing.footerHeight}px;
  ${CenterFlexStyle}
  padding-top:${(props) =>
    !props.topPaddingType
      ? 50
      : props.topPaddingType === 'large'
      ? 90
      : props.topPaddingType === 'small'
      ? 30
      : 0}px;
`;

export const FooterContentContainer = styled.div`
  width: calc(100% - 70px);
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing.footerHeight}px;
  align-items: flex-start;
  ${AutoHorizontalMargins}
  ${SmallGapStyle}
  margin-bottom: 60px;
  padding-top: 30px;
`;

export const LeftFooterTextContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  ${ExtraSmallGapStyle};
`;

export const RightFooterTextContainer = styled(LeftFooterTextContainer)`
  ${ColumnFlexStyle}
  justify-content: flex-start;
  align-items: flex-end;
`;

export const FooterText = styled(VeryLightExtraSmallText)`
  font-size: 10px;
  text-align: ${(props) => (props.position === 'end' ? 'end' : 'start')};
  line-height: 1.1;

  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
`;

export const FooterLinkText = styled(VeryLightExtraSmallText)`
  font-size: 14px;

  color: ${(props) =>
    props.dark
      ? ({ theme }) => theme.color.defaultGray
      : ({ theme }) => theme.color.charcoal};
`;
