import styled from 'styled-components';
import {
  ColumnDiv,
  MediumDarkEssText,
} from '../../../styles/shared-styled-components';

export const UserTextContainer = styled(ColumnDiv)`
  align-items: flex-end;
`;

export const UserNameText = styled(MediumDarkEssText)`
  text-align: end;
`;
