import FontFaceObserver from 'fontfaceobserver';
import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import WebsiteHeader from '../../components/NavBar/WebsiteHeader/WebsiteHeader';
import MetaSetter from '../../components/Utils/MetaSetter';
import { getWhiteLabelId, whiteLabelConfigMap } from '../../utils/white-label';
import CampaignTypes from './components/CampaignTypes';
import CompetitorComparison from './components/CompetitorComparison';
import FAQ from './components/FAQ';
import FiveMinuteSetup from './components/FiveMinuteSetup';
import Hero from './components/Hero';
import Partners from './components/Partners';
import Pricing from './components/Pricing';
import Testimonials from './components/Testimonials';
import {
  BackgroundContainer,
  DotAnimation,
  HomepagePageContainer,
} from './styles/style-Homepage';

const darkMode = false;
const whiteLabelId = getWhiteLabelId();
const whiteLabelConfig = whiteLabelId ? whiteLabelConfigMap[whiteLabelId] : '';

const Homepage = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const font = new FontFaceObserver('LogoFont');

    font.load().then(() => {
      setFontLoaded(true);
    });
  }, []);

  return (
    fontLoaded && (
      <>
        <MetaSetter
          title={`${whiteLabelConfig?.name || 'LiveIQ'}`}
          description={'Grow your business with AI'}
        />
        <WebsiteHeader darkMode={darkMode} />
        <BackgroundContainer ref={scrollContainerRef}>
          <DotAnimation />
          <HomepagePageContainer dark={darkMode}>
            <Hero
              darkMode={darkMode}
              tagline={whiteLabelConfig?.tagline}
              agentAvatar={whiteLabelConfig?.agentAvatar}
              subtitle={whiteLabelConfig?.subtitle}
              logo={whiteLabelConfig?.logo}
              url={whiteLabelConfig?.url}
            />
            {!whiteLabelConfig && (
              <>
                <Partners darkMode={darkMode} />
                <Testimonials darkMode={darkMode} />
                <CompetitorComparison darkMode={darkMode} />
                <FiveMinuteSetup darkMode={darkMode} />
                {/* <Features1 darkMode={darkMode} /> */}
                <CampaignTypes darkMode={darkMode} />
                <FAQ darkMode={darkMode} />
                <Pricing darkMode={darkMode} />
              </>
            )}
          </HomepagePageContainer>
          <Footer />
        </BackgroundContainer>
      </>
    )
  );
};

export default Homepage;
