import { useApolloClient } from '@apollo/client';
import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMetaAuthStatus } from '../../../api/hooks/app-connections';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import {
  ContentContainer,
  DarkMediumText,
  PageContainer,
} from '../../../styles/shared-styled-components';
import { updateMyUserAppConnectionsInCache } from '../../../utils/cache';
import {
  connectedStatusKey,
  incompleteStatusKey,
  metaKey,
} from '../../../utils/constants';

const MetaAuthStatus = () => {
  const { cache } = useApolloClient();

  const { user, refetchUser, drawerOpen, drawerExpanded } =
    useContext(BaseContext);
  const [searchParams] = useSearchParams();
  const tokenId = searchParams?.get('tid');

  const onCompleted = async (data) => {
    if (data) {
      const status = data.getMetaAuthStatus;
      if (status === connectedStatusKey) {
        refetchUser();
        await updateMyUserAppConnectionsInCache(
          metaKey,
          true,
          user?.email,
          cache,
        );
      }
    }
  };

  const { data: authStatus, loading: authStatusLoading } = useMetaAuthStatus(
    tokenId,
    onCompleted,
  );

  if (authStatusLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    authStatus && (
      <>
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
          >
            <DarkMediumText>
              {!user
                ? 'Must be logged in to verify Meta auth status'
                : authStatus === incompleteStatusKey
                ? `Meta failed to authenticate`
                : `Meta authenticated successfully!`}
            </DarkMediumText>
          </ContentContainer>
        </PageContainer>
      </>
    )
  );
};

export default MetaAuthStatus;
