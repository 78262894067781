import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  MenuItem,
  SmallLogoTransparent,
} from '../../../styles/shared-styled-components';
import { handleLogin, returnToUrlLocalStorageKey } from '../../../utils/auth';
import { checkIfPathIsAppPage } from '../../../utils/routing';
import { getWhiteLabelId } from '../../../utils/white-label';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import PopperMenu from '../../PopperMenu';
import {
  AppBar,
  HamburgerMenu,
  LogoContainer,
  LogoText,
  MobileMenuItem,
  NavbarDemoButton,
  NavbarGoToAppButton,
  ToolBarLeftContainer,
  ToolBarLeftSection,
  ToolBarMiddleSection,
  ToolBarRightContainer,
  ToolBarRightSection,
} from './styled';

const whiteLabelId = getWhiteLabelId();
const whiteLabelConfig = whiteLabelId ? Paths.whiteLabel[whiteLabelId] : null;
const homepagePath = whiteLabelConfig
  ? whiteLabelConfig['url']
  : Paths.homepage;

const WebsiteHeader = ({ darkMode, onNavigate = () => {} }) => {
  const { pathname, search } = useLocation();
  const { width } = useContext(BaseContext);
  const { user: auth0User, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  let pathToReturnTo = `${pathname}${search}`;
  const isAppPage = checkIfPathIsAppPage(pathname);
  if (!isAppPage) {
    pathToReturnTo = Paths.callback;
  }
  const existingReturnToUrl = localStorage.getItem(returnToUrlLocalStorageKey);

  const onClick = () => {};

  const onLogin = () => {
    if (!existingReturnToUrl) {
      localStorage.setItem(returnToUrlLocalStorageKey, pathToReturnTo);
    }
    handleLogin({
      onClick,
      pathname: Paths.callback,
      pathToReturnTo,
      loginWithRedirect,
    });
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const menuOpen = Boolean(menuAnchorEl);

  const navigateToPath = (path) => {
    onNavigate();
    navigate(path);
  };

  const navigateToHomepage = () => {
    if (whiteLabelId) {
      window.location.href = homepagePath;
    } else {
      if (!onHomeScreen) {
        navigate(Paths.homepage);
      }
    }
  };

  const collapseTitle = width < 1200;
  const collapseLeftDrawer = width < 800;
  const collapseRightDrawer = width < 730;
  const smallScreen = width < 650;
  const onHomeScreen = pathname === Paths.homepage && !whiteLabelId;

  const title = !whiteLabelId
    ? 'LiveIQ'
    : collapseTitle
    ? whiteLabelConfig?.name
    : whiteLabelConfig?.title;

  return (
    <>
      <AppBar>
        <ToolBarLeftContainer dark={darkMode}>
          <ToolBarLeftSection>
            <LogoContainer onClick={() => navigateToHomepage()}>
              <SmallLogoTransparent small={collapseLeftDrawer} />
              <LogoText
                dark={darkMode}
                collapsed={collapseLeftDrawer}
              >
                {title}
              </LogoText>
            </LogoContainer>
          </ToolBarLeftSection>
          {!whiteLabelId && (
            <ToolBarMiddleSection dark={darkMode}>
              <li>
                <a href='#five-minute-setup'>Setup</a>
              </li>
              <li>
                <a href='#testimonials'>Testimonials</a>
              </li>
              <li>
                <a href='#faq'>FAQ</a>
              </li>
              <li>
                <a href='#pricing'>Pricing</a>
              </li>
            </ToolBarMiddleSection>
          )}
        </ToolBarLeftContainer>
        <ToolBarRightContainer
          dark={darkMode}
          collapsed={false}
        >
          {smallScreen ? (
            <>
              <HamburgerMenu
                dark={darkMode}
                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
              >
                &#9776; {/* Hamburger Icon */}
              </HamburgerMenu>
            </>
          ) : (
            <ToolBarRightSection>
              <NavbarDemoButton
                href={Paths.calendlyLink}
                large={!collapseRightDrawer}
              >
                {collapseRightDrawer ? `Demo` : `Book a demo`}
              </NavbarDemoButton>
              <NavbarGoToAppButton
                onClick={() => onLogin()}
                dark={darkMode}
                large={!collapseRightDrawer}
              >
                Go to app
              </NavbarGoToAppButton>
            </ToolBarRightSection>
          )}
        </ToolBarRightContainer>
        {collapseLeftDrawer && menuOpen && (
          <PopperMenu
            dark={darkMode}
            open={menuOpen}
            anchorElement={menuAnchorEl}
            onClose={() => setMenuAnchorEl()}
            variant='offset'
            disablebottompadding
          >
            {!whiteLabelId ? (
              <>
                <MenuItem
                  dark={darkMode}
                  href='#five-minute-setup'
                >
                  Setup
                </MenuItem>
                <MenuItem
                  dark={darkMode}
                  href='#features'
                >
                  Features
                </MenuItem>
                <MenuItem
                  dark={darkMode}
                  href='#testimonials'
                >
                  Testimonials
                </MenuItem>
                <MenuItem
                  dark={darkMode}
                  href='#faq'
                >
                  FAQ
                </MenuItem>
                <MenuItem
                  dark={darkMode}
                  href='#pricing'
                >
                  Pricing
                </MenuItem>
              </>
            ) : (
              <MenuItem
                dark={darkMode}
                onClick={() => navigateToHomepage()}
              >
                Company Info
              </MenuItem>
            )}
            <MobileMenuItem
              dark={darkMode}
              href='#pricing'
              onClick={() => onLogin()}
            >
              {!auth0User ? 'Get started' : 'Go to app'}
            </MobileMenuItem>
          </PopperMenu>
        )}
      </AppBar>
    </>
  );
};

export default WebsiteHeader;
