import { sortBy } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import { useMyLocationUsers } from '../../../api/hooks/users';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import EmptyCard from '../../../components/Micro/EmptyCard';
import CreateUserModal from '../../../components/Modals/CreateUserModal';
import Header from '../../../components/NavBar/Header';
import Snackbar from '../../../components/Snackbar';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  BaseCard,
  CardHeaderContainer,
  CardPageSection,
  CardPageSectionActionsContainer,
  ColumnCenteredDiv,
  ContentContainer,
  DarkSmallText,
  ExtraSmallSecondaryButton,
  MediumGapColumnCenteredDiv,
  PageContainer,
  PageTitleText,
  SmallAddIcon,
  SmallLightDarkTextSpan,
  StartAlignedLightDarkEssText,
} from '../../../styles/shared-styled-components';
import {
  conglomerateRole,
  consultantRole,
  corporateRole,
  textSeparatorChar,
  userRoleLabelMap,
} from '../../../utils/constants';
import { getFormattedFullNameFromUser } from '../../../utils/name';
import { getUserAgency } from '../../../utils/user';

const Users = () => {
  const {
    drawerOpen,
    drawerExpanded,
    viewingAgency,
    inMonitoringMode,
    onAgency,
    user,
  } = useContext(BaseContext);

  const displayConsultants = onAgency;

  const agency = getUserAgency(user);

  const {
    users,
    refetch: refetchUsers,
    loading: usersLoading,
  } = useMyLocationUsers({});
  let locationUsers = [];
  let displayAgency = !viewingAgency && !inMonitoringMode;
  if (!viewingAgency && !inMonitoringMode && agency) {
    locationUsers.push({
      type: 'agency',
      name: `All members of the '${agency.name}' agency have access to this location`,
    });
  }
  sortBy(users, (u) => u.firstName)?.map((u) => {
    const included =
      u?.enterprise?.business?.id != null ||
      [corporateRole, conglomerateRole].includes(u?.role) ||
      (displayConsultants && u?.role === consultantRole);
    if (included) {
      locationUsers.push(u);
    }
  });

  const contentContainerRef = useRef();

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (usersLoading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    locationUsers && (
      <>
        <MetaSetter
          title={`Users`}
          description={`Users`}
        />
        <Header />
        <PageContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <ContentContainer
            drawerOpen={drawerOpen}
            drawerExpanded={drawerExpanded}
            ref={contentContainerRef}
          >
            <ColumnCenteredDiv>
              <PageTitleText>{onAgency ? `Agency ` : ''}Users</PageTitleText>
            </ColumnCenteredDiv>
            <CardPageSection>
              <CardPageSectionActionsContainer>
                <ExtraSmallSecondaryButton
                  onClick={() => setCreateModalOpen(true)}
                  largePadding
                  centered
                >
                  <SmallAddIcon /> Add user
                </ExtraSmallSecondaryButton>
              </CardPageSectionActionsContainer>
              {locationUsers?.length === 0 ? (
                <EmptyCard text='No users added' />
              ) : (
                <MediumGapColumnCenteredDiv>
                  {locationUsers?.map((user) => {
                    return (
                      <BaseCard>
                        {user.type === 'agency' ? (
                          <CardHeaderContainer>
                            <DarkSmallText>{user.name}</DarkSmallText>
                          </CardHeaderContainer>
                        ) : (
                          <>
                            <CardHeaderContainer>
                              <DarkSmallText>
                                {getFormattedFullNameFromUser(user)}{' '}
                                {textSeparatorChar}{' '}
                                <SmallLightDarkTextSpan>
                                  {userRoleLabelMap[user.role]}
                                  {displayAgency && user.agency
                                    ? ` (${user.agency})`
                                    : ''}
                                </SmallLightDarkTextSpan>
                              </DarkSmallText>
                            </CardHeaderContainer>
                            <div>
                              <StartAlignedLightDarkEssText>
                                {user.email}
                              </StartAlignedLightDarkEssText>
                            </div>
                          </>
                        )}
                      </BaseCard>
                    );
                  })}
                </MediumGapColumnCenteredDiv>
              )}
            </CardPageSection>
          </ContentContainer>
        </PageContainer>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
        <CreateUserModal
          isOpen={createModalOpen}
          onClose={() => {
            setCreateModalOpen(false);
          }}
          onSave={() => {
            refetchUsers();
            setSnackbarMessage('User created');
          }}
        />
      </>
    )
  );
};

export default Users;
