import { useApolloClient, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paths from '../../../Paths';
import {
  useAllAgencies,
  useAllEnterprises,
} from '../../../api/hooks/enterprise';
import {
  EDIT_BUSINESS_USER_LOCATION_VIEW,
  MODIFY_LOCATION_VALIDITY,
} from '../../../api/mutations/users';
import {
  MLText,
  MediumPrimaryButton,
  MenuItem,
  Modal,
  ModalContainer,
  ModalInputsContainerScrollWrapper,
  MoreMenuIcon,
  SMText,
  SmallMenuItemText,
  SyncIcon,
} from '../../../styles/shared-styled-components';
import {
  chatsSearchParamsCookieKey,
  consultantRole,
  greaterThanKey,
  monitoringAllActionKey,
  numUserMessagesField,
  replaceActionKey,
  superAdminRole,
} from '../../../utils/constants';
import { getCookieExpiryObject } from '../../../utils/date';
import { baseChatSearchParams } from '../../../utils/filters';
import { removeAllLocationSpecificCookies } from '../../../utils/routing';
import { getUserLocationId, locationIdSelectedKey } from '../../../utils/user';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import { DownArrow, UpArrow } from '../../Micro/ArrowIcon/styled';
import PopperMenu from '../../PopperMenu';
import Snackbar from '../../Snackbar';
import {
  BusinessNameText,
  CreateButtonContainer,
  EnterpriseContainer,
  EnterprisesContainer,
} from './styled';

const cookieExpiryObject = getCookieExpiryObject();

const locationViewKey = 'location';
const corporationViewKey = 'corporation';

const SelectLocationModal = ({ isOpen, onClose }) => {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const { user, refetchUser, setCookie, removeCookie } =
    useContext(BaseContext);

  const role = user?.role;
  const isSuperAdmin = role === superAdminRole;
  const isConsultant = role === consultantRole;
  const locationId = getUserLocationId(user);

  const {
    enterprises,
    loading: enterprisesLoading,
    refetch,
  } = useAllEnterprises({
    skipCondition: !isOpen,
  });
  const {
    agencies,
    loading: agenciesLoading,
    refetch: refetchAgencies,
  } = useAllAgencies({
    skipCondition: !isOpen || !isSuperAdmin,
  });

  const [editBusinessUserLocationViewMutation] = useMutation(
    EDIT_BUSINESS_USER_LOCATION_VIEW,
  );
  const [modifyLocationValidityMutation] = useMutation(
    MODIFY_LOCATION_VALIDITY,
  );

  const [view, setView] = useState(locationViewKey);
  const [locationsExpanded, setLocationsExpanded] = useState(!isSuperAdmin);
  const [agenciesExpanded, setAgenciesExpanded] = useState(false);
  const [locationIdToEdit, setLocationIdToEdit] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const menuOpenBoolean = Boolean(menuAnchorEl);

  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const onSelectLocation = (locationId, agencyId, isMonitoring = false) => {
    removeAllLocationSpecificCookies(removeCookie);
    handleClose();
    console.log('agencyId', agencyId);

    editBusinessUserLocationViewMutation({
      variables: {
        locationId,
        agencyId,
        action: isMonitoring ? monitoringAllActionKey : replaceActionKey,
      },
      onCompleted: async (data) => {
        const success = data.editBusinessUserLocationView;

        if (success === true) {
          if (!isMonitoring) {
            setSnackbarMessage(`Location updated`);
          }

          await refetchUser();
          isMonitoring
            ? localStorage.setItem(
                locationIdSelectedKey,
                monitoringAllActionKey,
              )
            : agencyId
            ? localStorage.setItem(locationIdSelectedKey, `agency_${agencyId}`)
            : localStorage.setItem(locationIdSelectedKey, locationId);

          await apolloClient.clearStore();

          if (isMonitoring) {
            let params = { ...baseChatSearchParams };
            params[numUserMessagesField] = [
              {
                key: numUserMessagesField,
                operator: greaterThanKey,
                value: 0,
              },
            ];
            setCookie(chatsSearchParamsCookieKey, params, cookieExpiryObject);
            navigate(Paths.chats);
          } else {
            navigate(Paths.callback);
          }
        } else {
          setSnackbarMessage('Error modifying location, refresh and try again');
        }
      },
    });
  };

  const onModifyValidity = (locationId, isValid) => {
    modifyLocationValidityMutation({
      variables: {
        locationId,
        isValid,
      },
      onCompleted: async (data) => {
        const success = data.modifyLocationValidity?.success;

        if (success === true) {
          await refetch();
          setSnackbarMessage(`Location updated`);
        } else {
          setSnackbarMessage('Error modifying location, refresh and try again');
        }
        setLocationIdToEdit();
        setMenuAnchorEl();
      },
    });
  };

  const isLoading = enterprisesLoading;

  return (
    isOpen &&
    !isLoading && (
      <>
        <Modal
          open={isOpen}
          onClose={handleClose}
          maxWidth={830}
        >
          <ModalContainer>
            <MLText>
              Select {view === locationViewKey ? 'Location' : 'Corporation'}{' '}
              {view === 'fake' && (
                <SyncIcon
                  onClick={() => {
                    setView(
                      view === locationViewKey
                        ? corporationViewKey
                        : locationViewKey,
                    );
                  }}
                />
              )}
            </MLText>
            <ModalInputsContainerScrollWrapper>
              <EnterprisesContainer>
                {isSuperAdmin && (
                  <EnterpriseContainer>
                    <BusinessNameText
                      special
                      clickable
                      onClick={() => onSelectLocation('', null, true)}
                    >
                      Super Admin View
                    </BusinessNameText>
                  </EnterpriseContainer>
                )}
                {isConsultant && (
                  <EnterpriseContainer>
                    <BusinessNameText
                      special
                      clickable
                      onClick={() => onSelectLocation('', null, true)}
                    >
                      Agency View
                    </BusinessNameText>
                  </EnterpriseContainer>
                )}
                {isSuperAdmin && (
                  <>
                    <SMText
                      addGap
                      centered
                      clickable
                      onClick={() => setLocationsExpanded(!locationsExpanded)}
                    >
                      Locations
                      {locationsExpanded ? <DownArrow /> : <UpArrow />}
                    </SMText>
                  </>
                )}
                {locationsExpanded && (
                  <>
                    {enterprises?.map((e) => {
                      const {
                        id,
                        location,
                        business,
                        corporation,
                        isSingleLocation,
                      } = e;
                      let formattedName;
                      if (isSingleLocation) {
                        formattedName = location;
                      } else {
                        formattedName = `${corporation} - ${location}${
                          business ? ` [${business}]` : ''
                        }`;
                      }
                      const isCurrent = id === locationId;
                      return (
                        <EnterpriseContainer>
                          {isCurrent ? (
                            <BusinessNameText disabled>
                              {formattedName} (Current)
                            </BusinessNameText>
                          ) : (
                            <BusinessNameText
                              clickable
                              onClick={() => onSelectLocation(id)}
                            >
                              {formattedName}
                            </BusinessNameText>
                          )}
                          {isSuperAdmin && (
                            <MoreMenuIcon
                              onClick={(e) => {
                                setLocationIdToEdit(id);
                                setMenuAnchorEl(e.currentTarget);
                              }}
                            />
                          )}
                        </EnterpriseContainer>
                      );
                    })}
                  </>
                )}
                {isSuperAdmin && (
                  <>
                    <SMText
                      addGap
                      centered
                      clickable
                      onClick={() => setAgenciesExpanded(!agenciesExpanded)}
                    >
                      Agencies
                      {agenciesExpanded ? <DownArrow /> : <UpArrow />}
                    </SMText>
                    {agenciesExpanded && (
                      <>
                        {agencies?.map((a) => {
                          const { id, name } = a;
                          const isCurrent = id === locationId;
                          return (
                            <EnterpriseContainer>
                              {isCurrent ? (
                                <BusinessNameText disabled>
                                  {name} (Current)
                                </BusinessNameText>
                              ) : (
                                <BusinessNameText
                                  clickable
                                  onClick={() => onSelectLocation(null, id)}
                                >
                                  {name}
                                </BusinessNameText>
                              )}
                            </EnterpriseContainer>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </EnterprisesContainer>
            </ModalInputsContainerScrollWrapper>
            {(isSuperAdmin || isConsultant) && (
              <CreateButtonContainer>
                <MediumPrimaryButton
                  onClick={() => {
                    navigate(Paths.createBusiness);
                  }}
                >
                  Create
                </MediumPrimaryButton>
              </CreateButtonContainer>
            )}
          </ModalContainer>
        </Modal>
        <PopperMenu
          open={menuOpenBoolean}
          anchorElement={menuAnchorEl}
          onClose={() => {
            setMenuAnchorEl();
            setLocationIdToEdit();
          }}
          variant='offset'
        >
          <MenuItem
            disableRipple
            onClick={() => {
              onModifyValidity(locationIdToEdit, false);
            }}
          >
            <SmallMenuItemText>Mark as invalid</SmallMenuItemText>
          </MenuItem>
        </PopperMenu>
        <Snackbar
          isOpen={!!snackbarMessage}
          onClose={() => setSnackbarMessage('')}
          message={snackbarMessage}
          quick
        />
      </>
    )
  );
};

export default SelectLocationModal;
