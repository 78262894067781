import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { CREATE_CONTACT } from '../../../api/mutations/client';
import {
  DarkMLText,
  EmptyGapColumnCenteredDiv,
  MediumPrimaryButton,
  MediumSecondaryButton,
  Modal,
  ModalActions,
  ModalContainer,
  ModalInputsContainer,
  ModalInputsContainerScrollWrapper,
} from '../../../styles/shared-styled-components';
import {
  contactTypeOptions,
  dropdownInputType,
  phoneInputType,
} from '../../../utils/constants';
import Input from '../../Form/Input';
import SplitInput from '../../Form/SplitInput/SplitInput';

const CreateContactModal = ({ isOpen, onClose, onCreate }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [type, setType] = useState();
  const [phone, setPhone] = useState();

  const [createContactMutation, { loading }] = useMutation(CREATE_CONTACT);

  const handleClose = () => {
    // setFirstName();
    // setLastName();
    // setType();
    // setPhone();
    onClose();
  };

  const handleCreate = () => {
    createContactMutation({
      variables: {
        contact: {
          firstName,
          lastName,
          type,
          phone,
        },
      },
      onCompleted: async (data) => {
        const success = data.createContact?.success;
        onCreate(success);
      },
    });
    handleClose();
  };

  const createEnabled = !!firstName && !!lastName && !!type && !!phone;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <ModalContainer>
          <DarkMLText>Create Contact</DarkMLText>
          <ModalInputsContainer>
            <ModalInputsContainerScrollWrapper>
              <EmptyGapColumnCenteredDiv>
                <SplitInput
                  id={'name'}
                  splitInputs={[
                    {
                      id: 'first',
                      value: firstName,
                      onChange: (e) => setFirstName(e.target.value),
                      label: 'First name',
                    },
                    {
                      id: 'last',
                      value: lastName,
                      onChange: (e) => setLastName(e.target.value),
                      label: 'Last name',
                    },
                  ]}
                  useExtraSmallGap={true}
                />
              </EmptyGapColumnCenteredDiv>
              <Input
                id='type'
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                label={'Contact type'}
                isRequired={false}
                useSmallGap
                type={dropdownInputType}
                options={contactTypeOptions}
              />
              <Input
                id='phone'
                value={phone}
                onChange={(updatedValue) => {
                  setPhone(updatedValue);
                }}
                label={'Phone'}
                isRequired={false}
                useSmallGap
                type={phoneInputType}
              />
            </ModalInputsContainerScrollWrapper>
          </ModalInputsContainer>
          <ModalActions>
            <MediumPrimaryButton
              disabled={!createEnabled}
              onClick={handleCreate}
            >
              Create
            </MediumPrimaryButton>
            <MediumSecondaryButton onClick={handleClose}>
              Close
            </MediumSecondaryButton>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CreateContactModal;
