import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import {
  GET_CAMPAIGN_LANDING_PAGE,
  GET_CAMPAIGN_TEMPLATES,
  GET_CONTACT_TAGS,
  GET_MY_CAMPAIGNS,
} from '../queries/campaigns';

export const useMyCampaigns = ({
  agencyId,
  viewingAgency,
  locationIds,
  shallow = false,
  skipCondition,
}) => {
  const { data, error, loading, refetch } = useQuery(GET_MY_CAMPAIGNS, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
    variables: {
      agencyId,
      viewingAgency,
      locationIds,
      shallow,
    },
  });

  const campaigns = get(data, 'getMyCampaigns', null);

  return {
    error,
    loading,
    refetch,
    campaigns,
  };
};

export const useContactTags = () => {
  const { data, error, loading, refetch } = useQuery(GET_CONTACT_TAGS, {
    fetchPolicy: 'cache-first',
  });

  const tags = get(data, 'getContactTags', []);

  return {
    error,
    loading,
    refetch,
    tags,
  };
};

export const useCampaignTemplates = ({ skipCondition }) => {
  const { data, error, loading, refetch } = useQuery(GET_CAMPAIGN_TEMPLATES, {
    fetchPolicy: 'cache-first',
    skip: skipCondition,
  });

  const templates = sortBy(get(data, 'getCampaignTemplates', []), (c) => [
    c.locationName,
    c.name,
  ]);

  return {
    error,
    loading,
    refetch,
    templates,
  };
};

export const useCampaignLandingPage = ({ skipCondition, id }) => {
  const { data, error, loading, refetch } = useQuery(
    GET_CAMPAIGN_LANDING_PAGE,
    {
      fetchPolicy: 'cache-first',
      skip: skipCondition || !id,
      variables: {
        id,
      },
    },
  );

  return {
    error,
    loading,
    refetch,
    data: get(data, 'getCampaignLandingPage', null),
  };
};
