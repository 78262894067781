import React, { useContext, useEffect, useState } from 'react';
import {
  AutoScrollWrapper,
  FetchMoreContainer,
  FetchMoreText,
  LoadingMoreContainer,
  MenuItem,
  MenuItemCheckmark,
  ModuleDownArrow,
  ModuleUpArrow,
  SmallMenuItemText,
  SmallMenuItemTitleContainer,
  SmallMenuItemTitleText,
  SuperTinyText,
  TinyText,
} from '../../../styles/shared-styled-components';
import {
  allKey,
  ascendingKey,
  descendingKey,
  lastUserMessageSentAtField,
  numHumanAgentMessagesField,
  numTotalMessagesField,
  numUserMessagesField,
} from '../../../utils/constants';
import {
  bookedFilterKey,
  customFilterKey,
  getSortParam,
  needsAttentionKey,
  recentActiveKey,
  starredKey,
} from '../../../utils/filters';
import { formatNumber } from '../../../utils/numbers';
import { BaseContext } from '../../Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../LoadingIndicator';
import PopperMenu from '../../PopperMenu';
import ClientSearchBox from '../../Search/ClientSearchBox/ClientSearchBox';
import ChatCard from '../ChatCard/ChatCard';
import {
  ChatDrawer,
  ChatDrawerHeaderContainer,
  ChatFilterIcon,
  ChatFilterIconContainer,
  ChatQueryContainer,
  ChatResultsTextContainer,
  ChatSortIcon,
  ChatSortIconContainer,
  ChatTypeCard,
  ChatTypeMenuContainer,
  NumChatFiltersAppliedIconContainer,
  QuerySubContainer,
  ResetFilterContainer,
  ResetFilterText,
} from './styled';

const chatFilterTypes = {
  [allKey]: { label: 'All chats shown', sublabel: 'All' },
  [recentActiveKey]: {
    label: 'Recently active chats',
    sublabel: 'Recently active',
  },
  [needsAttentionKey]: {
    label: 'Needs human attention',
    sublabel: 'Needs attention',
  },
  [bookedFilterKey]: {
    label: 'Resulted in booking',
    sublabel: 'Booked',
  },
  [starredKey]: {
    label: 'Starred',
    sublabel: 'Starred',
  },
  [customFilterKey]: {
    label: 'Custom filter applied',
    sublabel: 'Custom filter',
  },
};
const chatFilterTypesArr = Object.keys(chatFilterTypes);

const ChatRenderer = ({
  chats,
  preset,
  onApplyPreset,
  onModification,
  totalNumQueriedChats,
  chatsLoading,
  lastUpdatedAt,
  subscribeToChatUpdates,
  selectedChatId,
  onSelectChat,
  onChangeSearchFilter,
  onResetSearchFilter,
  searchFilterValue,
  sortParam,
  onChangeSortParam,
  onOpenFiltersModal,
  numFiltersApplied,
  onResetFilters,
  onScrollToBottom,
  onManuallyFetchMoreChats,
  numDisplayed,
  totalNum,
  pageNumber,
  totalNumPages,
}) => {
  const { drawerOpen, drawerExpanded } = useContext(BaseContext);

  const [sortParamMenuAnchorEl, setSortParamMenuAnchorEl] = useState(false);
  const [chatTypesMenuOpen, setChatTypesMenuOpen] = useState(true);

  const sortParamMenuOpenBoolean = Boolean(sortParamMenuAnchorEl);

  useEffect(() => {
    subscribeToChatUpdates();
  }, [lastUpdatedAt]);

  const onUpdateSortParam = (newSortParam) => {
    onChangeSortParam(newSortParam);
    setSortParamMenuAnchorEl();
  };

  const onUpdateChatFilterType = (type) => {
    if (type === customFilterKey) {
      onOpenFiltersModal();
    } else {
      const selected = preset === type;
      if (!selected) {
        onApplyPreset(type);
      }
    }
  };

  const filtersApplied = preset !== recentActiveKey || searchFilterValue;
  // !(!numFiltersApplied && !searchFilterValue);
  const moreChatsToFetch =
    pageNumber < totalNumPages || (!chatsLoading && numDisplayed < totalNum);

  return (
    <>
      <ChatDrawer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ChatDrawerHeaderContainer>
          <ChatQueryContainer>
            <ClientSearchBox
              onChange={(e) => onChangeSearchFilter(e.target.value)}
              value={searchFilterValue}
              onReset={onResetSearchFilter}
            />
            <ChatFilterIconContainer onClick={onOpenFiltersModal}>
              <ChatFilterIcon />
              {numFiltersApplied && preset === customFilterKey ? (
                <NumChatFiltersAppliedIconContainer>
                  {numFiltersApplied}
                </NumChatFiltersAppliedIconContainer>
              ) : (
                <></>
              )}
            </ChatFilterIconContainer>
          </ChatQueryContainer>
          <ResetFilterContainer>
            <ResetFilterText
              disabled={!filtersApplied}
              onClick={() => {
                if (filtersApplied) {
                  onResetFilters();
                }
              }}
            >
              Reset filters
            </ResetFilterText>
          </ResetFilterContainer>
          <QuerySubContainer>
            <ChatResultsTextContainer>
              <TinyText centered>
                {formatNumber(totalNumQueriedChats)}{' '}
                {!filtersApplied ? 'chat' : 'result'}
                {formatNumber(totalNumQueriedChats) !== 1 && 's'}{' '}
                <div
                  onClick={() => setChatTypesMenuOpen(!chatTypesMenuOpen)}
                  style={{ marginLeft: '5px' }}
                >
                  {chatTypesMenuOpen ? (
                    <ModuleUpArrow
                      small
                      primary
                      topMargin={6}
                    />
                  ) : (
                    <>
                      <ModuleDownArrow
                        small
                        primary
                        topMargin={6}
                      />
                    </>
                  )}
                </div>
              </TinyText>
            </ChatResultsTextContainer>
            <ChatSortIconContainer>
              <ChatSortIcon
                onClick={(e) => {
                  setSortParamMenuAnchorEl(e.currentTarget);
                }}
              />
            </ChatSortIconContainer>
          </QuerySubContainer>
          {chatTypesMenuOpen && (
            <ChatTypeMenuContainer>
              <ChatTypeCard selected>
                <SuperTinyText inherit>
                  {chatFilterTypes[preset]?.sublabel}
                </SuperTinyText>
              </ChatTypeCard>
              {chatFilterTypesArr.map((type) => {
                const label = chatFilterTypes[type]?.sublabel;
                const selected = preset === type;
                return (
                  !selected && (
                    <ChatTypeCard
                      selected={selected}
                      onClick={() => {
                        onUpdateChatFilterType(type);
                      }}
                    >
                      <SuperTinyText inherit>{label}</SuperTinyText>
                    </ChatTypeCard>
                  )
                );
              })}
            </ChatTypeMenuContainer>
          )}
          <PopperMenu
            open={sortParamMenuOpenBoolean}
            anchorElement={sortParamMenuAnchorEl}
            onClose={() => setSortParamMenuAnchorEl()}
            variant='offset'
          >
            <SmallMenuItemTitleContainer disableRipple>
              <SmallMenuItemTitleText>Select sort field</SmallMenuItemTitleText>
            </SmallMenuItemTitleContainer>
            <MenuItem
              disableRipple
              onClick={() => {
                onUpdateSortParam(
                  getSortParam(lastUserMessageSentAtField, descendingKey),
                );
              }}
            >
              <SmallMenuItemText>Last user message (newest)</SmallMenuItemText>
              {sortParam ===
                getSortParam(lastUserMessageSentAtField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(lastUserMessageSentAtField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>Last user message (oldest)</SmallMenuItemText>
              {sortParam ===
                getSortParam(lastUserMessageSentAtField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numTotalMessagesField, descendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Total number of messages (most)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numTotalMessagesField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numTotalMessagesField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Total number of messages (least)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numTotalMessagesField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numUserMessagesField, descendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of user messages (most)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numUserMessagesField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numUserMessagesField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of user messages (least)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numUserMessagesField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numHumanAgentMessagesField, descendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of human support agent messages (most)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numHumanAgentMessagesField, descendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
            <MenuItem
              disableRipple
              onClick={() =>
                onUpdateSortParam(
                  getSortParam(numHumanAgentMessagesField, ascendingKey),
                )
              }
            >
              <SmallMenuItemText>
                Number of human support agent messages (least)
              </SmallMenuItemText>
              {sortParam ===
                getSortParam(numHumanAgentMessagesField, ascendingKey) && (
                <MenuItemCheckmark />
              )}
            </MenuItem>
          </PopperMenu>
        </ChatDrawerHeaderContainer>
        <AutoScrollWrapper onScroll={onScrollToBottom}>
          {chats.map((c, idx) => {
            const id = c.id;
            const position =
              idx === 0 ? 'start' : idx === chats.length - 1 ? 'end' : 'middle';
            const selected = selectedChatId === id;
            return (
              <ChatCard
                chat={c}
                idx={idx}
                position={position}
                onClick={() => {
                  onSelectChat(id);
                }}
                selected={selected}
                onModifyStar={() => {
                  onModification();
                }}
              />
            );
          })}
          {chatsLoading && !numFiltersApplied ? (
            <LoadingMoreContainer>
              <LoadingIndicator size={30} />
            </LoadingMoreContainer>
          ) : moreChatsToFetch ? (
            <FetchMoreContainer>
              <FetchMoreText onClick={() => onManuallyFetchMoreChats()}>
                Fetch more
              </FetchMoreText>
            </FetchMoreContainer>
          ) : (
            <></>
          )}
        </AutoScrollWrapper>
      </ChatDrawer>
    </>
  );
};

export default ChatRenderer;
