import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useAllUsers } from '../../../api/hooks/users';
import { SHAPE_SHIFT } from '../../../api/mutations/users';
import { BaseContext } from '../../../components/Auth/AuthRouter/AuthRouter';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Header from '../../../components/NavBar/Header';
import { OptionItemContainer } from '../../../components/Training/shared-training-components';
import MetaSetter from '../../../components/Utils/MetaSetter';
import {
  CenteredDivWithGap,
  ColumnCenteredDiv,
  ContentContainer,
  ExtraSmallPrimaryButton,
  ExtraSmallSecondaryButton,
  ExtraSmallTextInput,
  FullSizeColumnCenteredDivWithGap,
  PageContainer,
  PageTitleText,
  SmallCheckbox,
  TinyText,
} from '../../../styles/shared-styled-components';
import { emptyStringValue } from '../../../utils/constants';
import { formatKeyToLabel } from '../../../utils/string';
import { UserNameText, UserTextContainer } from './styled';

const ShapeShift = () => {
  const { drawerOpen, drawerExpanded, isShapeShifter } =
    useContext(BaseContext);

  const { users, loading } = useAllUsers({ skipCondition: !isShapeShifter });
  const [shapeShiftMutation, { loading: shapeShifting }] =
    useMutation(SHAPE_SHIFT);

  const [searchFilter, setSearchFilter] = useState('');
  const [shiftUserId, setShiftUserId] = useState();

  const lowercaseSearchTerm = searchFilter?.toLowerCase();
  const matchingUsers = !searchFilter
    ? []
    : users.filter((u) => {
        const name = `${u.firstName?.toLowerCase()} ${u.lastName?.toLowerCase()}`;
        return name.includes(lowercaseSearchTerm);
      });

  const onShapeShift = (revert) => {
    shapeShiftMutation({
      variables: {
        shiftUserId: revert ? emptyStringValue : shiftUserId,
      },
      onCompleted: async (data) => {
        const success = data.shapeShift;

        if (success) {
          window.location.reload();
        }
      },
    });
  };

  if (loading) {
    return <LoadingIndicator fullScreen />;
  }

  return (
    <>
      <MetaSetter
        title={`Shape Shift`}
        description={`Shape Shift`}
      />
      <Header />
      <PageContainer
        drawerOpen={drawerOpen}
        drawerExpanded={drawerExpanded}
      >
        <ContentContainer
          drawerOpen={drawerOpen}
          drawerExpanded={drawerExpanded}
        >
          <PageTitleText>Shape Shift 🥷</PageTitleText>
          <FullSizeColumnCenteredDivWithGap>
            <CenteredDivWithGap>
              <ExtraSmallTextInput
                label='Search by name'
                value={searchFilter}
                onChange={(e) => {
                  setSearchFilter(e.target.value);
                }}
              />
              <ExtraSmallPrimaryButton
                disabled={!shiftUserId}
                onClick={() => {
                  onShapeShift();
                }}
              >
                Shape shift
              </ExtraSmallPrimaryButton>
              <ExtraSmallSecondaryButton
                onClick={() => {
                  onShapeShift(true);
                }}
              >
                Revert
              </ExtraSmallSecondaryButton>
            </CenteredDivWithGap>
            <ColumnCenteredDiv>
              {matchingUsers.map((u) => {
                console.log('u', u);
                const enterpriseTextElements = [];
                if (u.location) {
                  enterpriseTextElements.push(`Location: ${u.location}`);
                }
                if (u.business) {
                  enterpriseTextElements.push(`Biz: ${u.business}`);
                }
                if (u.corporation) {
                  enterpriseTextElements.push(`Corp: ${u.corporation}`);
                }
                if (u.conglomerate) {
                  enterpriseTextElements.push(
                    `Conglomerate: ${u.conglomerate}`,
                  );
                }
                if (u.agency) {
                  enterpriseTextElements.push(`Agency: ${u.agency}`);
                }
                const assigned = u.id === shiftUserId;
                return (
                  <OptionItemContainer>
                    <SmallCheckbox
                      checked={assigned}
                      onClick={() => {
                        setShiftUserId(assigned ? '' : u.id);
                      }}
                    />
                    <UserTextContainer>
                      <UserNameText smallLineHeight>
                        {u.firstName} {u.lastName}
                        <br></br>
                        {formatKeyToLabel(u.role)}
                      </UserNameText>
                      <TinyText>{enterpriseTextElements.join(', ')}</TinyText>
                    </UserTextContainer>
                  </OptionItemContainer>
                );
              })}
            </ColumnCenteredDiv>
          </FullSizeColumnCenteredDivWithGap>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default ShapeShift;
