import styled from 'styled-components';
import {
  ReceptionistAvatar as BaseAgentAvatar,
  CenteredDiv,
  SmallGapStyle,
} from '../../../../styles/shared-styled-components';

export const AgentAvatar = styled(BaseAgentAvatar)`
  overflow: auto;
  height: 111%;
  width: 110%;
`;

export const AgentNameContainer = styled(CenteredDiv)`
  margin-top: 10px;
  ${SmallGapStyle}
  height: 80px;
  margin-bottom: -15px;
`;
